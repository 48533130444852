import { getUrlDynamic, getUrlByContentType } from 'constants/app/AppUrls';
import { CONTENT_TYPE_SOCIAL } from 'constants/app/AppConstants';

// Parse Social page content
// Page blocks
const parseBlocksData = (block) => {
  const { data, id } = block;

  const { type = '', content = {} } = data;

  if (type == 'case_study') {
    return {
      type,
      id,
      details: {
        id: data.id,
        override_title: data.override_title,
      },
    };
  }

  return {
    id,
    type,
    friendly_url: getUrlByContentType(CONTENT_TYPE_SOCIAL, content?.friendly_url?.trim() || ''),
    details: {
      name: content?.name?.trim() || '',
      types: content?.types || [],
    },
    content: {
      cta_image: getUrlDynamic(content?.cta_image || ''),
      description: content?.description || '',
    },
  };
};

export const parseBlocks = (data) => {
  const allIDs = [];
  const byID = {};
  const pages = {
    allIDs: [],
    byID: {},
  };

  for (const item of data) {
    const { id } = item;
    const parsed = parseBlocksData(item);

    const { type } = parsed;

    if (type == 'social_page') {
      pages.allIDs.push(id);
      pages.byID[id] = { ...parsed };
    }

    allIDs.push(id);
    byID[id] = { ...parsed };
  }
  return { allIDs, byID, pages };
};
// END OF

// Parse Social Content Data
const parseContentData = (content) => {
  const { data = {}, id, name } = content;
  const trimmedName = name.trim();
  const { description = '', slides = [], types = [], cta_image = '', cta_type = '', slideshow_active = false, placeholder_image = '', background_image = '' } = data;

  const activeSlideshow = slideshow_active && slideshow_active == '1';

  const parsedContent = {
    id,
    details: {
      name: trimmedName,
      types,
      description,
    },
    content: {
      cta_type,
      cta_image: getUrlDynamic(cta_image),
      cta_video: getUrlDynamic(background_image),
      cta_video_placeholder: getUrlDynamic(placeholder_image),
      activeSlideshow,
      slides: slides.map((slide) => {
        const { media_type = '', media_video = '', media_video_thumbnail = '', media_image = '', media_gif = '' } = slide;

        if (media_type == 'video') {
          return {
            ...slide,
            media_video: getUrlDynamic(media_video),
            media_video_thumbnail: getUrlDynamic(media_video_thumbnail),
          };
        }

        if (media_type == 'gif') {
          return {
            ...slide,
            media_gif: getUrlDynamic(media_gif),
          };
        }

        return {
          ...slide,
          media_image: getUrlDynamic(media_image),
        };
      }),
    },
  };

  return parsedContent;
};

export const parseSocialContentData = (data) => {
  const allIDs = [];
  const byID = {};
  const contentByTypeId = {};

  for (const item of data) {
    const { id, name } = item;
    const parsed = parseContentData(item);

    parsed.details.types.map((id) => {
      if (!contentByTypeId[id]) {
        contentByTypeId[id] = [];
      }

      contentByTypeId[id].push(parsed.id);
    });

    allIDs.push(id);
    byID[id] = { ...parsed };
  }

  return { allIDs, byID, contentByTypeId };
};
// END OF

// Parse Social Category tags
export const parseTags = (data) => {
  const allIDs = [];
  const byID = {};

  for (const item of data) {
    const { id, name } = item;
    const trimmedName = name.trim();
    allIDs.push(id);
    byID[id] = { name: trimmedName };
  }
  return { allIDs, byID };
};
// END OF
