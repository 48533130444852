import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const random = Math.random() * (1 - 0) + 0;
    const delay = 0.5 + random * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.1 },
      },
    };
  },
};

const SvgBlueLinesTriangle = () => {
  const controls = useAnimation();

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="343.608"
      height="334.728"
      viewBox="0 0 343.608 334.728"
      className="svg-icon--blue-lines-triangle"
      ref={ref}
      variants={draw}
      initial="hidden"
      animate={controls}
    >
      <g data-name="Group 846" fill="rgba(71,167,206,0)" stroke="rgba(71,167,206,0.4)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="10">
        <motion.path variants={draw} data-name="Path 1766" d="M94.8605 171.9531a93.8505 93.8505 0 0 0 21.8151-38.3407" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1767" d="m85.6918 141.175-24.9199-16.5755" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1768" d="M97.6372 124.124a101.355 101.355 0 0 1 4.1698-23.9713" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1769" d="M39.05 128.842a101.294 101.294 0 0 1 4.1698-23.9713" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1770" d="M116.9667 114.1814a40.8636 40.8636 0 0 0 28.5362-4.168" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1771" d="M6.7887 92.306a40.8586 40.8586 0 0 0 28.5343-4.1687" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1772" d="M135.5016 131.1383a166.9012 166.9012 0 0 1 24.0642 20.215" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1773" d="M113.3475 206.3173a166.9761 166.9761 0 0 1 31.3944-1.4226" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1774" d="m130.5499 183.8395 3.468-29.3122" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1775" d="M153.925 177.7096a75.1736 75.1736 0 0 0 29.3796-3.2011" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1776" d="M191.2755 231.2582a75.1596 75.1596 0 0 0 29.3793-3.2002" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1777" d="M179.0854 142.2758a149.7912 149.7912 0 0 0-.9547-36.9536" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1778" d="M162.7556 102.033a159.7256 159.7256 0 0 1-.2591-38.1365" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1779" d="M122.4236 87.9967a144.7695 144.7695 0 0 0-29.4239-14.6703" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1780" d="M74.8591 109.137a138.0459 138.0459 0 0 1-6.3657-30.2406" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1781" d="M129.6151 65.0188a52.383 52.383 0 0 0 19.4542-6.8604" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1782" d="m302.735 105.7378 11.5943-27.5067" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1783" d="M172.0076 43.7593A75.1646 75.1646 0 0 1 197.504 58.641" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1784" d="M276.8288 28.6292a75.1736 75.1736 0 0 1 26.2992-13.4144" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1785" d="M264.5859 113.7563a75.1676 75.1676 0 0 1 25.4974 14.8822" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1786" d="m180.7248 85.8836 31.9633 9.4918" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1787" d="M194.5631 129.0441a66.774 66.774 0 0 0 34.261-25.24" stroke-width="9.999400000000001" />
        <motion.path
          variants={draw}
          data-name="Path 1788"
          d="M226.9945 160.921a71.8388 71.8388 0 0 0-8.2658-21.6425c-.7978-1.3726-3.2792-2.4596-3.4594-.8822"
          stroke-linejoin="round"
          stroke-width="9.999400000000001"
        />
        <motion.path
          variants={draw}
          data-name="Path 1789"
          d="M250.8244 244.3258a71.8088 71.8088 0 0 0-8.2636-21.6428c-.7993-1.371-3.2795-2.4587-3.4598-.8813"
          stroke-linejoin="round"
          stroke-width="9.999400000000001"
        />
        <motion.path variants={draw} data-name="Path 1790" d="M196.4314 163.2087a140.0997 140.0997 0 0 0 15.1072 38.6082" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1791" d="M188.0996 277.4394q17.017 2.9606 34.1805 4.9427" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1792" d="m164.9614 232.2959 8.3647-37.4383" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1793" d="M90.7164 196.0263a227.5026 227.5026 0 0 1-15.4196-36.2651" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1794" d="m243.3406 313.2916 3.07-30.2228" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1795" d="M260.8828 266.0881a241.7277 241.7277 0 0 0-43.447-10.8017" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1796" d="M158.0464 249.255a209.9876 209.9876 0 0 0-24.2302-20.29" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1797" d="M263.677 178.815c-2.4761 12.7007-.0758 26.8545 6.6698 39.31" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1798" d="M209.3 297.2527a54.3718 54.3718 0 0 0 21.2457 29.6502" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1799" d="M255.6068 27.0128a70.4568 70.4568 0 0 0-32.6572 5.2315" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1800" d="m228.4822 205.6925 13.7306-34.8158" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1801" d="m259.5265 151.0174-12.2985-26.436" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1802" d="M276.4826 88.9985a107.3187 107.3187 0 0 1-41.4363-13.0356" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1803" d="m217.5842 79.2292-.734-31.6239" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1804" d="m286.6992 48.7922 17.5897-2.7821" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1805" d="M329.9293 39.6584a80.5462 80.5462 0 0 0 .3875-20.4816" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1806" d="M274.8666 67.6546a108.0476 108.0476 0 0 0-14.3608-18.3417" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1807" d="M282.0589 172.174a107.9786 107.9786 0 0 0 2.3443-23.1776" stroke-width="9.999400000000001" />
        <motion.path variants={draw} data-name="Path 1808" d="m178.0642 257.4961 21.0578-1.0623" stroke-width="9.999400000000001" />
      </g>
    </motion.svg>
  );
};

export default SvgBlueLinesTriangle;
