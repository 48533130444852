import * as DeviceDetection from 'utils/deviceDetection';
import { msieversion, canUseWebP } from 'utils/general';

export const APP_CONFIG = window.siteData;
if (!APP_CONFIG) {
  console.error('missing site data');
} else {
  __WATCH__ && console.info('APP_CONFIG', APP_CONFIG);
}

export const TRACKING_ACTIVE = APP_CONFIG.tracking;
export const LANGUAGE_CODE = APP_CONFIG.language_code || 'en_GB';
export const CACHEBUST = APP_CONFIG.cachebust;
export const LOCAL_STORAGE_OPTIONS = { states: [], namespace: 'jollywisesite' };
export const BASE_URL = APP_CONFIG.base_url.replace(/\/+$/, '');

export const USE_WEBP = canUseWebP();

// Device Detection
export const IS_DESKTOP = DeviceDetection.getDevice() === 'desktop';
export const IS_IOS = DeviceDetection.isiOS();
export const DEVICE_TYPE = DeviceDetection.getDevice();

// If you need IE detection
// Adds class to html tag for targeted styling
// if (msieversion()) {
//   document.getElementsByTagName('html')[0].classList.add('is-ie');
// }

// Nav
export const NAV_TYPE = {
  EXTERNAL: 'external_url',
  INTERNAL: 'internal_page',
};

// Content Types
export const CONTENT_TYPE_PROJECT = 'project';
export const CONTENT_TYPE_CLIENT = 'client';
export const CONTENT_TYPE_BLOG = 'blog';
export const CONTENT_TYPE_EMPLOYEE = 'employee';
export const CONTENT_TYPE_CATEGORY = 'category';
export const CONTENT_TYPE_SOCIAL = 'social';
export const CONTENT_TYPE_ILLUSTRATION = 'illustration';

// Modal Overlay Types
export const MODAL_OVERLAY_CLOSE = 'close';
export const MODAL_OVERLAY_TYPE_CLIENT = CONTENT_TYPE_CLIENT;
export const MODAL_OVERLAY_TYPE_EMPLOYEE = CONTENT_TYPE_EMPLOYEE;

// Placeholder Experts in data
export const EXPERTS_PLACEHOLDER = {
  title: "We're experts in...",
  description:
    "Jollywise aren't your typical digital agency. We're the magic-makers behind captivating apps, animated stories, and educational content. Our mission? To create smiles, spark curiosity, and make learning an adventure. Since 2006, we've been trusted by global brands, and passion for fun has only grown stronger.",
  content: [
    {
      title: 'Kids',
      description: 'From Gen A to Gen Z, our specialists know how to connect with young hearts and minds. From games to campaigns, we create magic that leaves a lasting impression.',
    },
    {
      title: 'Family',
      description: 'We have families, we design for families, we ARE a family. From pre-school to old school, we understand what excellent family content should look like - so we make it!',
    },
    {
      title: 'Digital',
      description: 'Creativity is our superpower. Games, apps, websites, campaigns, animations, video - our digital creatives infuse every project with innovation and flair.',
    },
    { title: 'Learning', description: 'As creators, we believe in the power of learning through play. Our content not only entertains but also educates, overseen by a team of experts.' },
    {
      title: 'Brands',
      description: 'No two brands are alike, and neither are our solutions. We customise our services to meet any specific goal and audience, via compelling content that delights.',
    },
    {
      title: 'Entertainment',
      description: "Entertainment comes alive through compelling content, across all digital media, from AR filters to digital toolkits. For engaging, sharable fun, we've got this.",
    },
  ],
};
