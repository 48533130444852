import React from 'react';

const SvgArrowLeft = () => {
  return (
    <svg className="svg-icon svg-icon--arrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
      <path d="M467.3 210H156.1L277 94.2c17.5-18.4 17.5-47.3 0-65.8-17.5-18.4-45.6-18.4-63.1 0L13.2 224C4.4 232.8 0 244.2 0 256.4c0 12.3 4.4 24.5 13.2 32.4L214 483.4c17.5 18.4 45.6 18.4 63.1 0s17.5-47.3 0-65.8l-121-114.8h311.2c24.5 0 44.7-21 44.7-46.5 0-26.2-20.2-46.3-44.7-46.3z" />
    </svg>
  );
};

export default SvgArrowLeft;
