//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import consts
import { PAGES } from 'store/pages/Pages.reducer';
import { CONTENT_TYPE_BLOG } from 'constants/app/AppConstants';

// import selectors
import { getPageDataByName } from 'store/pages/Pages.selectors';
import { getProjectDataByID } from 'store/projects/Projects.selectors';
import { getBlogById } from 'store/blogs/Blogs.selectors';
import { getSiteMetaData } from 'store/app/App.selectors';

// import components
import SiteMetaData from 'components/generic/SiteMetaData';
import ClientsGrid from 'components/clients/Clients.grid';
import HomeHeader from 'components/pages/home/Home.header';
import HomeSubHeader from 'components/pages/home/Home.subheader';
import HomeShowcaseContent from 'components/pages/home/Home.showcase_content';
import { windowScrollTo } from 'utils/general';
import BlogsGrid from 'components/blogs/Blogs.grid';
import CategoriesGrid from 'components/categories/Categories.grid';
import SvgYellowLineLines from 'components/svg/patterns/Svg.yellow_line_lines';
import SvgBlueDots from 'components/svg/patterns/Svg.blue_dots.jsx';
import HomeExperts from 'components/pages/home/Home.experts';

import { trackPageview } from 'utils/tracking';

class PageHome extends Component {
  constructor(props) {
    super(props);

    this.scrollTo = React.createRef();
    this.scrollToCallBack = this.scrollToCallBack.bind(this);
  }

  componentDidMount() {
    trackPageview({ title: 'Homepage', path: this.props.location.pathname });
  }

  scrollToCallBack() {
    windowScrollTo(window.pageYOffset + this.scrollTo.current.getBoundingClientRect().top - 70);
  }

  render() {
    const { header, showcase, expert_block } = this.props;
    const { ids = [] } = showcase;
    return (
      <div className="home">
        <SiteMetaData {...this.props.metadata} />
        <HomeHeader data={header} scrollToCallBack={this.scrollToCallBack} />
        <HomeExperts data={expert_block} />
        <HomeSubHeader data={header} scrollTo={this.scrollTo} />
        <CategoriesGrid />
        <div className="home__blog-showcase">
          <SvgYellowLineLines />
          <SvgBlueDots />
          <div className="container">
            <h4 className="home__blog-showcase__title">Read about how we work</h4>
            <BlogsGrid limit={3} ids={ids} />
          </div>
        </div>
        <ClientsGrid />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const data = getPageDataByName(state, PAGES.HOME);

  const { header = {}, showcase, expert_block = {} } = data;

  return {
    metadata: getSiteMetaData(state, 'home'),
    header,
    showcase,
    expert_block,
  };
};

export default connect(mapStateToProps, null)(PageHome);
