import React from 'react';

function SvgSlideshowArrowRight() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" xmlSpace="preserve">
      <g data-name="Group 1289">
        <g fill="#fff" stroke="#707070" data-name="Ellipse 87" opacity="0.003">
          <circle cx="35" cy="35" r="35" stroke="none"></circle>
          <circle cx="35" cy="35" r="34.5" fill="none"></circle>
        </g>
        <g data-name="Group 1192">
          <g data-name="Group 837">
            <g fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="6" data-name="Group 836">
              <g data-name="Group 831">
                <path d="M41.242 34.5L30.758 44.984" data-name="Line 54"></path>
                <path d="M41.242 34.5L30.758 44.984" data-name="Line 55"></path>
              </g>
              <g data-name="Group 835">
                <path d="M41.243 34.5L30.759 24.016" data-name="Line 54"></path>
                <path d="M41.243 34.5L30.759 24.016" data-name="Line 55"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgSlideshowArrowRight;
