import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { LazyImage } from 'react-lazy-images';

// Framer motion variables
const project_item = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1 },
};

const EmployeesItems = (props) => {
  const { data, modalCallback } = props;
  const [hasLoaded, updateHasLoaded] = useState(false);

  return data.map((employee, index) => {
    const { id, cta_image, name } = employee;
    if (cta_image !== '') {
      return (
        <motion.li key={index} className={'employees__item' + (!hasLoaded ? ' employees__item--loading' : '')} variants={project_item}>
          <figure
            className="employees__item__image"
            onClick={() => {
              modalCallback(id);
            }}
          >
            <LazyImage
              src={cta_image}
              alt={name}
              placeholder={({ imageProps, ref }) => <div className="lazy-load__placeholder" ref={ref} />}
              actual={({ imageProps }) => (
                <img
                  {...imageProps}
                  onLoad={() => {
                    updateHasLoaded(true);
                  }}
                />
              )}
            />

            <figcaption>{name}</figcaption>
          </figure>
        </motion.li>
      );
    }
  });
};

export default EmployeesItems;
