import { getUrlDynamic, getUrlByContentType } from 'constants/app/AppUrls';
import { CONTENT_TYPE_BLOG } from 'constants/app/AppConstants';

const parseDate = (timestamp) => {
  const parseDay = (day) => {
    switch (day) {
      case 1:
        return `${day}st`;

      case 2:
        return `${day}nd`;

      case 3:
        return `${day}rd`;

      default:
        return `${day}th`;
    }
  };
  const MONTHS = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  };

  const date = new Date(timestamp * 1000);
  return `${MONTHS[date.getMonth()]} ${parseDay(date.getDate())} ${date.getFullYear()}`;
};

const parseData = ({ blog, previousBlogUrl, nextBlogUrl }) => {
  const {
    active = false,
    id = '',
    author = '',
    friendly_url = '',
    intro = '',
    main_image = '',
    meta_description = '',
    meta_keywords = '',
    meta_title = '',
    og_description = '',
    og_title = '',
    posted = '',
    slider_image = '',
    subheading = '',
    text = '',
    title = '',
    show_blogpage = false,
    show_contactpage = false,
    show_homepage = false,
    related_blogs = [],
    text_html = '',
  } = blog;

  const related_blog_ids = [];

  if (!Array.isArray(related_blogs)) {
    for (blog in related_blogs) {
      if (Object.prototype.hasOwnProperty.call(related_blogs, blog) && blog) {
        related_blog_ids.push(blog);
      }
    }
  }

  if (author !== '' && author !== null) {
    const { image = '' } = author;

    if (image !== '' && image !== null) {
      author.image = getUrlDynamic(image);
    }
  }

  const parsedBlog = {
    active,
    id,
    title,
    friendly_url: getUrlByContentType(CONTENT_TYPE_BLOG, friendly_url.trim()),
    details: {
      intro,
      title,
      subheading,
      posted: parseDate(posted),
      previous_blog_url: previousBlogUrl ? getUrlByContentType(CONTENT_TYPE_BLOG, previousBlogUrl) : previousBlogUrl,
      next_blog_url: nextBlogUrl ? getUrlByContentType(CONTENT_TYPE_BLOG, nextBlogUrl) : nextBlogUrl,
      show_blogpage,
      show_contactpage,
      show_homepage,
      related_blog_ids,
    },
    content: {
      main_image: '',
      slider_image: '',
      text: text_html,
      author: author !== null ? author : false,
    },
    meta: {
      meta_description,
      meta_keywords,
      meta_title,
      og_description,
      og_title,
    },
  };

  if (slider_image !== '') {
    parsedBlog.content.slider_image = getUrlDynamic(slider_image);
  }

  if (main_image !== '') {
    parsedBlog.content.main_image = getUrlDynamic(main_image);
  }

  return parsedBlog;
};

export const parseBlogs = (data) => {
  const contactPageData = [];
  const blogPageData = [];
  data.map((blog, index) => {
    const { show_blogpage, show_contactpage } = blog;

    if (show_blogpage) {
      blogPageData.push(blog);
    }

    if (show_contactpage) {
      contactPageData.push(blog);
    }
  });

  const allBlogPageIDs = [];
  const byBlogPageID = {};

  const allContactPageIDs = [];
  const byContactPageID = {};

  contactPageData.map((blog, index) => {
    const previousBlogUrl = contactPageData[index - 1] ? contactPageData[index - 1].friendly_url : false;
    const nextBlogUrl = contactPageData[index + 1] ? contactPageData[index + 1].friendly_url : false;

    const { id, name } = blog;
    const parsed = parseData({ blog, previousBlogUrl, nextBlogUrl });
    if (parsed.active) {
      allContactPageIDs.push(id);
      byContactPageID[id] = { ...parsed };
    }
  });

  blogPageData.map((blog, index) => {
    const previousBlogUrl = blogPageData[index - 1] ? blogPageData[index - 1].friendly_url : false;
    const nextBlogUrl = blogPageData[index + 1] ? blogPageData[index + 1].friendly_url : false;

    const { id, name } = blog;
    const parsed = parseData({ blog, previousBlogUrl, nextBlogUrl });
    if (parsed.active) {
      allBlogPageIDs.push(id);
      byBlogPageID[id] = { ...parsed };
    }
  });

  // JS2021-67 :: remove inactive blogs from blog related_blog_ids
  // in order to stop related_blog_container rendering
  for (const id of allBlogPageIDs) {
    const blog = byBlogPageID[id];
    if (blog) {
      const active_ids = [];
      for (const bid of blog?.details?.related_blog_ids) {
        if (allBlogPageIDs.includes(bid)) {
          active_ids.push(bid);
        }
      }
      blog.details.related_blog_ids = active_ids;
    }
  }

  return { allBlogPageIDs, byBlogPageID, allContactPageIDs, byContactPageID };
};
