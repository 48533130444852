import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';

// import containers
import Footer from 'containers/sections/Sections.footer';
import ModalOverlay from 'containers/modal/Modal.overlay';

// import selectors
import { isActiveOverlayModal } from 'store/app/App.selectors';

// import actions
import { activeOverlayModal } from 'store/app/App.actions';

// Page transition consts
const transition = { duration: 0.7, ease: 'easeInOut' };
const variants = {
  initial: { opacity: 0, y: 100 },
  enter: { opacity: 1, y: 0, transition },
  exit: { opacity: 0, y: 100, transition: { duration: 0.5, ease: 'easeInOut' } },
};
const onAnimationComplete = (e) => {
  if (e === 'exit') {
    window.scrollTo(0, 0);
  }
};

const Main = (props) => {
  const { children, isHome = false, activeModal } = props;

  useEffect(() => {
    if (activeModal) {
      props.closeModal({ active: false });
    }
  }, [children]);

  return (
    <motion.div className="page-transition" initial="initial" animate="enter" exit="exit" variants={variants} onAnimationComplete={onAnimationComplete}>
      <ModalOverlay />
      <main role="main" className={'main' + (isHome ? ' main--is-home' : '')}>
        {children}
      </main>
      <Footer />
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    activeModal: isActiveOverlayModal(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: (opts) => {
      dispatch(activeOverlayModal(opts));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
