import React from 'react';
import { connect } from 'react-redux';

// import selectors
import { getRelatedLimitedProjectsByCategoryID, getRelatedLimitedProjectsByClientID } from 'store/projects/Projects.selectors';
import { getHomepageShowcaseContent } from 'store/pages/Pages.selectors';

// import components
import ProjectHeader from 'components/project/Project.header';
import ProjectBody from 'components/project/Project.body';
import BlogRelatedGrid from 'components/blog/Blog.related_grid';

const ProjectBase = ({ data, client, related_data }) => {
  return (
    <>
      <article className="project__base">
        <ProjectHeader data={data} />
        <ProjectBody data={data} client={client} />
      </article>
      <div className="related_blog_container container">
        <BlogRelatedGrid related_data={related_data} />
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const searchParam = getParameterByName('category', false);

  const relatedData = searchParam
    ? getRelatedLimitedProjectsByCategoryID(state, searchParam, 2, ownProps.data.id)
    : getRelatedLimitedProjectsByClientID(state, ownProps.client.id, 2, ownProps.data.id);

  const homeRelated = getHomepageShowcaseContent(state);

  return {
    related_data: relatedData.length > 0 ? relatedData : homeRelated,
  };
};

export default connect(mapStateToProps, null)(ProjectBase);

// helper functions
// ****
const getParameterByName = (name, defaultValue, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results || !results[2]) return defaultValue;

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
