import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';
import { BASE_URL } from 'constants/app/AppConstants';

const SiteMetaData = (props) => {
  const { title, description, keywords, location, og_image, og_description, og_title } = props;
  const { pathname = '/' } = location;

  return (
    <Helmet defer={false}>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <link rel="canonical" href={`${BASE_URL}${pathname}`} />
      <meta property="og:image:url" content={og_image} />
      <meta property="og:image" content={og_image} />
      <meta property="twitter:image" content={og_image} />
      <meta property="og:title" content={og_title} />
      <meta property="og:description" content={og_description} />
      <meta property="og:url" content={`${BASE_URL}${pathname}`} />
    </Helmet>
  );
};

export default withRouter(SiteMetaData);
