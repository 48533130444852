import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';

// import selectors
import { getSocialContentByTypeIDs } from 'store/social/Social.selectors';

// import components
import SocialContentBlock from 'components/social-content/SocialContent.block';
import SocialContentOverlay from 'components/social-content/SocialContent.overlay';

const SocialContentGrid = (props) => {
  const [activeBlock, updateActiveBlock] = useState(false);
  const [displaySlideshow, setDisplaySlideshow] = useState(false);
  const [blockSpan, updateBlockSpan] = useState(5);

  const slideshowRef = useRef(null);

  const checkWidth = () => {
    const currentBlockSpan = window.innerWidth < 576 ? 1 : window.innerWidth < 768 ? 2 : 5;
    blockSpan !== currentBlockSpan && updateBlockSpan(currentBlockSpan);
  };

  useEffect(() => {
    checkWidth();
    window.addEventListener('resize', checkWidth);
    return () => {
      updateActiveBlock(false);
      window.removeEventListener('resize', checkWidth);
    };
  }, []);

  const toggleOverlay = (block = false) => {
    if (!activeBlock && block.content.activeSlideshow) {
      if (slideshowRef.current.getBoundingClientRect()?.height < 600) {
        slideshowRef.current.style.minHeight = window.innerWidth < 768 ? '780px' : '600px';
      }
    } else {
      slideshowRef.current.style.minHeight = '200px';
    }

    updateActiveBlock(activeBlock.id == block.id ? false : block);
    setDisplaySlideshow(activeBlock ? false : block.content.activeSlideshow);
  };

  const renderBlocks = () => {
    const numberOfRows = props.content.length / blockSpan;
    const lastRowThreshold = (numberOfRows % 1 === 0 ? numberOfRows - 1 : Math.floor(numberOfRows)) * blockSpan;

    return props.content.map((block, index) => {
      const { id } = block;
      return <SocialContentBlock block={block} key={index} isActive={activeBlock.id === id} updateActiveBlock={toggleOverlay} isOnLastRow={index + 1 > lastRowThreshold} />;
    });
  };

  const gridLayout = props.content && props.content.length % 4 === 1 ? 3 : 4;

  return (
    <AnimatePresence>
      <div ref={slideshowRef} style={{ position: 'relative', transition: '0.3s all ease-in-out', minHeight: '200px' }}>
        {displaySlideshow && (
          <motion.div key="social-overlay" initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <SocialContentOverlay data={activeBlock} toggleOverlay={toggleOverlay} />
          </motion.div>
        )}
        <ul className={`category__grid category__grid--${gridLayout} social-content__grid` + (activeBlock ? ' active' : '')}>{renderBlocks()}</ul>
      </div>
    </AnimatePresence>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    content: getSocialContentByTypeIDs(state, ownProps.types),
  };
};

export default connect(mapStateToProps, null)(SocialContentGrid);
