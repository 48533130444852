import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Swiper from 'react-id-swiper';
import { windowScrollTo } from 'utils/general';

import SvgCross from 'components/svg/generic/Svg.cross';
import SvgSlideshowArrowLeft from 'components/svg/generic/Svg.slideshow_arrow_left';
import SvgSlideshowArrowRight from 'components/svg/generic/Svg.slideshow_arrow_right';

let eventsAttached = false;

const SocialContentOverlay = ({ data, toggleOverlay }) => {
  const { id, content, details } = data;
  const [swiper, setSwiper] = useState(null);

  const nextArrow = useRef();
  const prevArrow = useRef();

  const { name = '', description = '' } = details;
  const { slides = [] } = content;

  const prevScrollPos = useRef();

  // Swiper variables
  const params = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    freeMode: true,
    pagination: false,
  };

  const toEdgeCheck = () => {
    nextArrow.current.disabled = swiper.isEnd;
    prevArrow.current.disabled = swiper.isBeginning;
  };

  const toggleArrowsOn = () => {
    nextArrow.current.disabled = true;
    prevArrow.current.disabled = true;
  };

  const toggleArrowsOff = () => {
    if (!swiper.isEnd && !swiper.isBeginning) {
      nextArrow.current.disabled = false;
      prevArrow.current.disabled = false;
    }
  };

  useEffect(() => {
    if (swiper && !eventsAttached) {
      eventsAttached = true;

      if (slides && slides.length > 2) {
        prevArrow.current.disabled = true;
        swiper.on('toEdge', toEdgeCheck);
        swiper.on('slideChange', toggleArrowsOff);
      } else {
        toggleArrowsOn();
      }
    }

    return () => {
      if (swiper && !eventsAttached) {
        swiper.off('toEdge', toEdgeCheck);
        swiper.off('slideChange', toggleArrowsOff);
      }

      eventsAttached = false;
    };
  }, [swiper]);

  useEffect(() => {
    prevScrollPos.current = window.scrollY;
    windowScrollTo(0);

    return () => {
      windowScrollTo(prevScrollPos.current);
    };
  }, [data.id]);

  const renderSlide = () => {
    return slides.map((slide, index) => {
      const { media_type, media_video = '', media_video_thumbnail = '', name = '', media_image = '', media_gif = '' } = slide;

      const media_src = media_type == 'video' ? media_video : media_type == 'gif' ? media_gif : media_image;

      if (media_src !== '') {
        if (media_type == 'video') {
          return (
            <div className="social-content__slideshow__video-slide" key={index}>
              <div style={{ lineHeight: 1 }}>
                <video autoPlay playsInline loop muted poster={media_video_thumbnail} title={name} style={{ width: '100%' }}>
                  <source src={media_src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="social-content__slideshow__name">{name}</div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="social-content__slideshow__video-other" key={index}>
              <div style={{ lineHeight: 1 }}>
                <img src={media_src} title={name} alt={name} />
                <div className="social-content__slideshow__name">{name}</div>
              </div>
            </div>
          );
        }
      }
    });
  };

  return (
    <motion.section className="social-content__overlay">
      <div className="container">
        <div className="social-content__overlay__desc-box">
          <button
            className="social-content__overlay__close"
            aria-label="close overlay"
            onClick={() => {
              toggleOverlay(data);
            }}
          >
            <SvgCross />
          </button>
          <div className="social-content__overlay__title">{name}</div>
          <div className="social-content__overlay__desc">{description}</div>

          <section className="social-content__overlay__swiper-controls">
            <button
              className="btn"
              onClick={() => {
                swiper && swiper.slidePrev();
              }}
              ref={prevArrow}
            >
              <SvgSlideshowArrowLeft />
            </button>
            <button
              className="btn"
              onClick={() => {
                swiper && swiper.slideNext();
              }}
              ref={nextArrow}
            >
              <SvgSlideshowArrowRight />
            </button>
          </section>
        </div>
        <div className="social-content__overlay__slides" style={{ display: 'block', flexGrow: 2 }}>
          <Swiper getSwiper={setSwiper} {...params}>
            {renderSlide()}
          </Swiper>
        </div>
      </div>
    </motion.section>
  );
};

export default SocialContentOverlay;
