export const getIllustrations = (state) => {
  const allIds = state.illustrations.allIDs;

  return allIds.map((id, index) => {
    const illustration = { ...state.illustrations.byID[id] };
    return illustration;
  });
};

export const getIllustrationsByID = (state, id) => {
  if (state.illustrations.byID) {
    return state.illustrations.byID[id] || {};
  }
  return {};
};

export const getIllustrationsAllIDs = (state) => {
  return state.illustrations.allIDs;
};

export const getIllustrationsByUrl = (state, url) => {
  const ids = getIllustrationsAllIDs(state);
  let returnableData = [];

  if (ids.length > 0) {
    let i = 0;
    for (i; i < ids.length; i++) {
      const illustration = state.illustrations.byID[ids[i]];

      if (illustration.friendly_url === url) {
        return illustration;
      }
    }
  }

  return false;
};
