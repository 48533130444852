import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { Link } from 'react-router-dom';

const CategoryBlock = ({ block, isExternal, searchParam = '' }) => {
  const controls = useAnimation();

  const { id, content, details, friendly_url } = block;
  const { cta_image } = content;
  const { description = '', external_url = '', name = '' } = details;
  const key = isExternal ? `${name}__external` : name;

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  // rise in
  const motion_styles = {
    hidden: { y: 10 },
    visible: {
      y: 0,
    },
  };

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <motion.li key={key} className="category__block motion_styles" variants={motion_styles} initial="hidden" animate={controls} ref={ref}>
      <div className="category__block__background-image" style={{ backgroundImage: `url(${cta_image})` }} />
      <Link to={searchParam !== '' ? `${friendly_url}?category=${searchParam}` : `${friendly_url}`} className="category__block__inner">
        <div className="category__block__title">{name}</div>
        <div className="category__block__cta">{'Find out more >'}</div>
      </Link>
    </motion.li>
  );
};

export default CategoryBlock;
