import React from 'react';
import { Link } from 'react-router-dom';

// import components
import SvgArrowLeft from 'components/svg/generic/Svg.arrow_left';

const CategoryHeader = ({ data, back_link }) => {
  const { details = {}, content = {} } = data;
  const { name = '' } = details;
  const { description = '' } = content;

  return (
    <div className="category__header">
      <div className="category__header__container">
        <div className="back-button">
          <Link className="go-back" to={back_link} aria-label="go back to previous page">
            <SvgArrowLeft /> Back
          </Link>
        </div>
        <h1 className="title">{name}</h1>
        {description && <h2 className="description wysiwyg" dangerouslySetInnerHTML={{ __html: description }} />}
      </div>
    </div>
  );
};

export default CategoryHeader;
