import request from 'superagent/lib/client';
import { getUrlSiteData, getUrlSendEmail } from 'constants/app/AppUrls';

export const getSiteData = () => {
  // const url = __WATCH__ ? 'https://www.jollywise.co.uk/api/get_site_data/en_GB' : getUrlSiteData();
  const url = getUrlSiteData();
  return request
    .get(url)
    .type('form')
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const postSendEmail = ({ firstname, lastname, email, message }) => {
  const url = getUrlSendEmail();
  return request
    .post(url)
    .type('form')
    .send({ firstname, lastname, email, message })
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};
