import { isEmptyObj } from 'utils/general';

// APP Actions
import { BOOTSTRAP_INIT, completeBootstrap } from 'store/app/App.actions';
// Setup import items
// Mainly App based imports
import * as AppServices from 'constants/app/AppServices';
// Import Dispatches
import { dispatchConfigData, dispatchContentData, dispatchPageData } from 'store/bootstrap/Bootstrap.actions';

// Bootstrap stuff
const BootstrapFlow =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case BOOTSTRAP_INIT:
        {
          const data = action.payload;
          doBootstrap({ dispatch, getState, data });
        }
        break;
    }
  };

const doBootstrap = ({ dispatch, getState, data }) => {
  const { config, content, pages } = parseSiteData(data);

  dispatchConfigData(dispatch, config);

  dispatchContentData(dispatch, content);

  dispatchPageData(dispatch, pages);

  dispatch(completeBootstrap());
};

export default [BootstrapFlow];

const parseSiteData = (data) => {
  const {
    cms_footer = {},
    cms_meta = {},
    cms_navigation = {},
    cms_social_media = {},
    config_medium = {},
    config_meta = {},
    categories = {},
    clients = {},
    employees = {},
    projects = {},
    cms_contact = {},
    cms_home = {},
    cms_how_we_play = {},
    cms_what_we_do = {},
    cms_who_we_are = {},
    cms_who_we_work_with = {},
    cms_privacy_policy = {},
    cms_creative_services = {},
    blogs = [],
    illustrations = {},
    social_section = {},
  } = data;

  return {
    config: { cms_footer, cms_meta, cms_navigation, cms_social_media, config_medium, config_meta },
    content: { categories, clients, employees, projects, blogs, illustrations, social_section },
    pages: {
      cms_contact,
      cms_home,
      cms_how_we_play,
      cms_what_we_do,
      cms_who_we_are,
      cms_who_we_work_with,
      cms_privacy_policy,
      cms_creative_services,
    },
  };
};
