import { getUrlDynamic } from 'constants/app/AppUrls';

const parseData = (employee) => {
  const { data, id, name } = employee;
  const { job_title = '', content = {} } = data;
  const { description = '', image = '', cta_image = '' } = content;

  const parsedEmployee = {
    id,
    details: {
      name,
      job_title,
    },
    content: {
      image: image !== '' ? getUrlDynamic(image) : '',
      description,
      cta_image: cta_image !== '' ? getUrlDynamic(cta_image) : '',
    },
  };

  return parsedEmployee;
};

export const parseEmployees = (data) => {
  const allIDs = [];
  const byID = {};

  for (const employee of data) {
    const { id, name } = employee;
    const parsed = parseData(employee);
    allIDs.push(id);
    byID[id] = { ...parsed };
  }

  return { allIDs, byID };
};
