// import consts
import { IMAGE_COLLAGE_DISPLAY_MORE, IMAGE_COLLAGE_DISPLAY_RESET } from 'store/pages/Pages.actions';
import { BOOTSTRAP_PAGES_DATA } from 'store/bootstrap/Bootstrap.actions';

// import parsers
import { parseHome } from 'store/pages/parsers/Home.parser';
import { parseAbout } from 'store/pages/parsers/About.parser';
import { parseContact } from 'store/pages/parsers/Contact.parser';
import { parseWhatWeDo } from 'store/pages/parsers/Shared.parser';

export const PAGES = {
  WHO_WE_ARE: 'who_we_are',
  CONTACT: 'contact',
  HOME: 'home',
  HOW_WE_PLAY: 'how_we_play',
  WHAT_WE_DO: 'what_we_do',
  WHO_WE_WORK_WITH: 'who_we_work_with',
  ILLUSTRATIONS: 'illustrations',
  PRIVACY_POLICY: 'privacy_policy',
  CREATIVE_SERVICES: 'creative_services',
};

const IMAGE_COLLAGE_DEFAULT_DISPLAY_LIMIT = 2;
const IMAGE_COLLAGE_ADDITIONIAL_DISPLAY_LIMIT = 1;

const initState = {
  [PAGES.CONTACT]: {},
  [PAGES.HOME]: {},
  [PAGES.HOW_WE_PLAY]: {},
  [PAGES.WHAT_WE_DO]: {},
  [PAGES.WHO_WE_ARE]: {},
  [PAGES.WHO_WE_WORK_WITH]: {},
  [PAGES.PRIVACY_POLICY]: {},
  [PAGES.ILLUSTRATIONS]: {},
  numberToDisplay: IMAGE_COLLAGE_DEFAULT_DISPLAY_LIMIT,
};

const Pages = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case BOOTSTRAP_PAGES_DATA: {
      const { contact, home, how_we_play, what_we_do, who_we_are, who_we_work_with, privacy_policy, illustrations = {}, creative_services } = action.payload;

      nextState[PAGES.CONTACT] = parseContact(contact);
      nextState[PAGES.HOME] = parseHome(home);
      nextState[PAGES.HOW_WE_PLAY] = how_we_play;
      nextState[PAGES.WHAT_WE_DO] = parseWhatWeDo(what_we_do);
      nextState[PAGES.WHO_WE_ARE] = parseAbout(who_we_are);
      nextState[PAGES.WHO_WE_WORK_WITH] = who_we_work_with;
      nextState[PAGES.PRIVACY_POLICY] = privacy_policy;
      nextState[PAGES.ILLUSTRATIONS] = illustrations;
      nextState[PAGES.CREATIVE_SERVICES] = creative_services;

      return { ...state, ...nextState };
    }
    case IMAGE_COLLAGE_DISPLAY_MORE: {
      const nextCount = state.numberToDisplay + IMAGE_COLLAGE_ADDITIONIAL_DISPLAY_LIMIT;
      nextState.numberToDisplay = Math.min(state[PAGES.WHO_WE_ARE].image_collage.length, nextCount);

      return { ...state, ...nextState };
    }
    case IMAGE_COLLAGE_DISPLAY_RESET: {
      nextState.numberToDisplay = IMAGE_COLLAGE_DEFAULT_DISPLAY_LIMIT;

      return { ...state, ...nextState };
    }
  }
  return state;
};

export default Pages;
