import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
// import SVG
import { SvgChevronDown } from 'components/svg/generic/Svg.chevron_down';

const IllustrationsBlock = ({ block, isActive, updateActiveBlock, isOnLastRow }) => {
  // state
  const [hasBeenSeen, updateHasBeenSeen] = useState(false);
  const [hasLoaded, updateHasLoaded] = useState(false);

  const videoRef = useRef();
  const controls = useAnimation();

  const { id, content, details } = block;
  const { background, logo, poster, description } = content;
  const { name } = details;

  const { ref, inView, entry } = useInView({
    threshold: window.innerWidth > 768 ? 0.1 : 0.4,
    triggerOnce: true,
  });

  // rise in
  const motion_styles = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const playVideo = () => {
    hasLoaded && videoRef.current && videoRef.current.play();
  };

  const pauseVideo = () => {
    setTimeout(() => {
      if (hasLoaded && videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }, 100);
  };

  useEffect(() => {
    isActive ? playVideo() : pauseVideo();
    inView ? controls.start('visible') : controls.start('hidden');
  }, [isActive, inView, isOnLastRow]);

  const renderBlockContent = () => {
    return (
      <div className={'illustrations__block__container' + (isActive ? ' illustrations__block__container--active' : '') + (isOnLastRow ? ' illustrations__block__container--last-row' : '')}>
        <div
          className={'illustrations__block__inner' + (!hasLoaded ? ' illustrations__block__inner--loading' : '')}
          onClick={() => {
            updateActiveBlock(id);
          }}
        >
          <div className="illustrations__block__background-image" style={{ backgroundImage: `url(${poster})` }} />
          <div className="illustrations__block__arrow-icon">
            <button className="btn btn--flip-out" type="button">
              <SvgChevronDown clazz={'btn__icon btn__icon--bottom'} />
              <SvgChevronDown clazz={'btn__icon btn__icon--top'} />
            </button>
          </div>
          <video
            className="illustrations__block__video"
            poster={poster}
            ref={videoRef}
            muted
            loop
            playsInline
            preload="auto"
            onLoadedData={(res) => {
              updateHasLoaded(true);
            }}
            onError={(err) => {
              console.log('error', err);
            }}
          >
            <source src={`${background}#t=0.1`} type="video/mp4" />
          </video>
        </div>
        <section className="illustrations__block__desc">{description}</section>
      </div>
    );
  };

  return (
    <motion.li key={name} className="illustrations__block motion_styles" variants={motion_styles} initial="hidden" animate={controls} ref={ref}>
      {renderBlockContent()}
    </motion.li>
  );
};

export default IllustrationsBlock;
