import React from 'react';

// import scss
import './Clients.testimonials.scss';
import getTestimonialBox from 'components/svg/generic/Svg.testimonialBoxes';

// import components

const ClientsTestimonialsGridBlock = ({ block, index }) => {
  const { name, jobtitle = '', client_name, description, size } = block;
  const column = index % 2 === 0 ? 'left' : 'right';

  const full_title = jobtitle !== '' ? `${name}, ${jobtitle}` : name;
  return (
    <li className={`clients__testimonials_grid-masonry-brick`}>
      <div className={`masonry-brick masonry-brick-${size} masonry-brick-${column}`}>
        <div className={`clients__testimonial_block__details details-${size}`}>
          <p className="clients__testimonial_block_quote">{description}</p>

          <div className="clients__testimonial_block_info">
            <span>{full_title}</span>
          </div>
          <p className="clients__testimonial_block_company">{client_name}</p>
        </div>
      </div>
    </li>
  );
};

const ClientsTestimonialsGrid = ({ content }) => {
  const leftCol = [];
  const rightCol = [];
  content.map((block, index) => {
    if (index % 2 === 0) {
      leftCol.push(<ClientsTestimonialsGridBlock block={block} index={index} key={index} />);
    } else {
      rightCol.push(<ClientsTestimonialsGridBlock block={block} index={index} key={index} />);
    }
  });
  return (
    <div className="clients__testimonials_grid-masonry--container">
      <ul className="clients__testimonials_grid-masonry clients__testimonials_grid-masonry--left">{leftCol}</ul>
      <ul className="clients__testimonials_grid-masonry clients__testimonials_grid-masonry--right">{rightCol}</ul>
    </div>
  );
};

const ClientsTestimonials = ({ data }) => {
  const { title = '', content = [] } = data;
  return (
    <section className="clients__testimonials">
      <div className="container">
        <h1 className="clients__testimonials_title wysiwyg" dangerouslySetInnerHTML={{ __html: title }} />

        <div className="container clients__testimonials__container">{content.length > 0 && <ClientsTestimonialsGrid content={content} />}</div>
      </div>
    </section>
  );
};

/**
 * Masonry
 * https://fjolt.com/article/css-grid-masonry
 * https://codepen.io/smpnjn/pen/qBRNJQY
 *
 * https://w3bits.com/flexbox-masonry/
 */
export default ClientsTestimonials;
