//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import selectors
import { getSiteMetaData } from 'store/app/App.selectors';

// import components
import SiteMetaData from 'components/generic/SiteMetaData';
import PageNotFoundBase from 'components/page-not-found/PageNotFound.base';

import { trackPageview } from 'utils/tracking';

class PageNotFound extends Component {
  componentDidMount() {
    trackPageview({ title: '404 Page Not Found', path: this.props.location.pathname });
  }

  render() {
    return (
      <div className="not-found">
        <SiteMetaData {...this.props.metadata} />
        <PageNotFoundBase />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    metadata: getSiteMetaData(state, 'page-not-found'),
  };
};

export default connect(mapStateToProps, null)(PageNotFound);
