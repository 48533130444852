import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const random = Math.random() * (1 - 0) + 0;
    const delay = 0.5 + random * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.1 },
      },
    };
  },
};

const SvgRedLines = () => {
  const controls = useAnimation();

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <motion.svg
      className="svg-icon--red-lines"
      xmlns="http://www.w3.org/2000/svg"
      width="419.443"
      height="419.214"
      viewBox="0 0 419.443 419.214"
      ref={ref}
      variants={draw}
      initial="hidden"
      animate={controls}
    >
      <g data-name="Group 900">
        <g data-name="Group 899" fill="none" stroke="#ec685b" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="10">
          <motion.path variants={draw} data-name="Path 1858" d="M334.5084 302.2063a322.5172 322.5172 0 0 1-16.2006 60.451" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1859" d="m309.8148 263.283-18.2635 53.4383" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1860" d="m282.8065 238.1622-33.0568 67.5761" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1861" d="M243.2546 178.5463 223.719 217.707" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1862" d="m210.624 135.4-9.4128 39.6976" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1863" d="m153.5388 124.6574-5.766 32.1777" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1864" d="m90.0056 90.023-7.3228 30.8756" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1865" d="m52.8002 45.7822-5.5933 19.1672" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1866" d="m15.6415 14.6173-6.53 23.6098" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1867" d="m311.783 191.0337-7.0417 17.8956" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1868" d="m282.0363 147.6444-28.1604 71.591" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1869" d="m245.4296 122.222-4.1825 17.64" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1870" d="M193.192 99.587 167.8348 202.591" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1871" d="M121.1633 84.366a691.3507 691.3507 0 0 1-15.1808 71.876" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1872" d="m128.0073 177.3046-13.8552 54.49" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1873" d="m114.8 12.9751-7.6047 43.8534" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1874" d="M82.4642 36.0509 69.3617 99.1006" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1875" d="M36.5978 8.414Q24.9715 86.0475 5.8475 162.3125" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1876" d="m69.9592 123.4696-15.6628 50.3303" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1877" d="m47.6792 99.553-7.0677 33.7306" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1878" d="m146.6905 31.7204-4.7198 27.7657" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1879" d="m200.6185 216.886-8.089 22.3093" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1880" d="M229.654 235.8863 209.5834 285.17" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1881" d="M379.9613 242.6469q-21.0587 85.036-48.3634 168.3587" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1882" d="M393.5659 314.824a389.5854 389.5854 0 0 1-15.6626 50.3284" strokeWidth="10.0052" />
          <motion.path variants={draw} data-name="Path 1883" d="m412.4538 349.1195-4.693 11.9329" strokeWidth="10.0052" />
        </g>
      </g>
    </motion.svg>
  );
};

export default SvgRedLines;
