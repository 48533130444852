//magic things
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// import selectors
import { getViewSocialContent } from 'store/app/App.selectors';

// import actions
import { activateSocialContent } from 'store/app/App.actions';

// import pages
import PageCreativeServices from 'containers/sub-pages/Pages.creative-services';
import PageCategory from 'containers/sub-pages/Pages.category';
import PageCatgories from 'containers/pages/Pages.categories';
import PageSocial from 'containers/pages/Pages.social';
import PageNotFound from 'containers/pages/Pages.not_found';
import PagesSocialContent from 'containers/sub-pages/Pages.social-content';

// import containers
import Main from 'containers/sections/Sections.main';

class RouterCategories extends Component {
  constructor(props) {
    super(props);
    this.checkPath = this.checkPath.bind(this);
  }
  componentDidMount() {
    if (!this.props.viewSocialContent) {
      const debug = new URLSearchParams(location.search)?.get('viewSocialContent') || false;

      debug && this.props.setActivateSocialContent();
    }
  }

  checkPath() {
    const { location, viewSocialContent } = this.props;
    return location.pathname.includes('social') && viewSocialContent;
  }

  render() {
    const { match } = this.props;
    console.log('RouterCategories', this.props);
    return (
      <React.Fragment>
        <Switch location={this.props.location}>
          <Route
            path={`${match.path}/creative-services`}
            render={(props) => (
              <Main>
                <PageCreativeServices {...props} />
              </Main>
            )}
          />
          {this.props.viewSocialContent && (
            <Route
              path={`${match.path}/social/:urlFragment`}
              render={(props) => (
                <Main>
                  <PagesSocialContent {...props} />
                </Main>
              )}
            />
          )}
          <Route path={`${match.path}/:urlFragment`} render={(props) => <Main>{this.checkPath() ? <PageSocial {...props} /> : <PageCategory {...props} />}</Main>} />

          <Route
            exact
            path={`${match.path}`}
            render={(props) => (
              <Main>
                <PageCatgories {...props} />
              </Main>
            )}
          />
          <Route
            render={(props) => (
              <Main>
                <PageNotFound {...props} />
              </Main>
            )}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    viewSocialContent: getViewSocialContent(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setActivateSocialContent: () => {
      dispatch(activateSocialContent());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouterCategories);
