import { combineReducers } from 'redux';

import app from 'store/app/App.reducer';
import pages from 'store/pages/Pages.reducer';
import categories from 'store/categories/Categories.reducer';
import clients from 'store/clients/Clients.reducer';
import employees from 'store/employees/Employees.reducer';
import projects from 'store/projects/Projects.reducer';
import blogs from 'store/blogs/Blogs.reducer';
import social from 'store/social/Social.reducer';
import illustrations from 'store/illustrations/Illustrations.reducer';

const rootReducer = combineReducers({
  app,
  pages,
  categories,
  clients,
  employees,
  projects,
  blogs,
  illustrations,
  social,
});

export default rootReducer;
