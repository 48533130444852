// import consts
import { ILLUSTRATIONS_DISPATCH_DATA } from 'store/illustrations/Illustrations.actions';

// import parsers
import { parseIllustrations } from 'store/illustrations/Illustrations.parsers';

const initState = {
  allIDs: [],
  byID: {},
  categoryIdByType: {},
};

const Illustration = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case ILLUSTRATIONS_DISPATCH_DATA: {
      const { allIDs, byID, illustrationIdByType } = parseIllustrations(action.payload);
      nextState.allIDs = allIDs;
      nextState.byID = byID;
      nextState.categoryIdByType = illustrationIdByType;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default Illustration;
