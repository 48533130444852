import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const random = Math.random() * (1 - 0) + 0;
    const delay = 0.5 + random * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.1 },
      },
    };
  },
};

const SvgBlueSwirl = () => {
  const controls = useAnimation();

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="590.777"
      height="316.323"
      viewBox="0 0 590.777 316.323"
      className="svg-icon--blue-swirl"
      ref={ref}
      variants={draw}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={draw}
        data-name="Path 1857"
        d="M22.021 138.015a260.225 260.225 0 0 0 16.425 65.062A484.63 484.63 0 0 0 6.222 76.311a256.742 256.742 0 0 0 28.171 143.9C44.54 159.011 25.75 97.119 7.179 37.929a437.2 437.2 0 0 0 41.044 185.876A662.866 662.866 0 0 0 16.262 27.152a646.877 646.877 0 0 0 50.06 205.647 465.412 465.412 0 0 0-34.36-217.655c10.042 71.081 20.33 143.066 48 209.3 8.027 3.286 13.1-8.791 13.273-17.466A782.364 782.364 0 0 0 70.879 4.996a1090.185 1090.185 0 0 0 27.9 183.681c3.067 12.76 7.975 27.313 20.335 31.725a613.871 613.871 0 0 0-3.149-182.719 537.533 537.533 0 0 0-1.516 174.257 731.049 731.049 0 0 0 9.871-172.535l-4 151.965c23.555-53.04 31.3-111.516 38.8-169.064q-8.4 79.958-8.937 160.482a616.357 616.357 0 0 0 69.941-158.864 750.2 750.2 0 0 0-40.7 145.089l71.238-110.672a433.557 433.557 0 0 1-27.2 122.2c32.078-45.188 65.278-91.472 111.731-121.692l-63.431 95.562a289.25 289.25 0 0 1 106.731-53.682 240.978 240.978 0 0 1-63.47 85.725 290.259 290.259 0 0 1 97.805-56.705 204.2 204.2 0 0 1-54.4 71.623 174.228 174.228 0 0 1 97.391-44.756 305.97 305.97 0 0 1-65.616 67.389 186.234 186.234 0 0 1 64.45-21.522 206.938 206.938 0 0 1-42.867 51.075l76.563-21.575a386.246 386.246 0 0 0-59.12 48.83l80.433-13.208a109.243 109.243 0 0 0-45.984 37.8 384.708 384.708 0 0 0 78.773-22.336l-34.55 21.744a199.488 199.488 0 0 1 76.741 5.377"
        fill="none"
        stroke="#47a7ce"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
      />
    </motion.svg>
  );
};

export default SvgBlueSwirl;
