import { NAV_TYPE } from 'constants/app/AppConstants';

const parse = (data) => {
  const { id, name = '', nav_item_type: type = '' } = data;

  const classFriendlyName = name !== '' ? name.replace(/[\s-]+/g, '-').toLowerCase() : '';

  const parsed = {
    id,
    type,
    name,
    classFriendlyName,
  };
  if (type === NAV_TYPE.INTERNAL) {
    const { page } = data;
    parsed.url = page;
  }
  if (type === NAV_TYPE.EXTERNAL) {
    const { external_url = '', external_url_target = '_blank' } = data;
    parsed.url = external_url;
    parsed.target = external_url_target;
  }
  return parsed;
};

export const parseNavigation = (data = []) => {
  const nav = {};

  const { content = [] } = data;

  // parse CMS links
  if (content.length > 0) {
    for (const item of content) {
      const { id } = item;

      const parsed = parse(item);
      if (parsed) {
        nav[id] = { ...parsed };
      }
    }
  }
  return nav;
};
