const gtagAvaiable = () => {
  return window?.gtag || false;
};

export const trackPageview = ({ title, path }) => {
  if (!gtagAvaiable()) {
    console.log('gtag not avaiable');
    return;
  }

  gtag('event', 'page_view', {
    page_title: title,
    page_location: path,
  });
};
