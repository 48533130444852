import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

const CategoriesBlock = ({ block }) => {
  // state
  const [hasBeenSeen, updateHasBeenSeen] = useState(false);
  const [hasLoaded, updateHasLoaded] = useState(false);

  const videoRef = useRef();
  const controls = useAnimation();

  const { id, content, details, friendly_url, categoryProjects, categoryProject_count } = block;
  const { background, logo, poster } = content;
  const { name } = details;
  const nameCheck = name.trim().toLowerCase();

  const { ref, inView, entry } = useInView({
    threshold: window.innerWidth < 768 ? 0.1 : 0.4,
    triggerOnce: window.innerWidth > 768,
  });

  // rise in
  const motion_styles = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const playVideo = () => {
    videoRef.current && videoRef.current.play();
  };

  const pauseVideo = () => {
    setTimeout(() => {
      if (hasLoaded && videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }, 500);
  };

  useEffect(() => {
    !inView && !hasBeenSeen && controls.start('hidden');
    if (inView) {
      if (!hasBeenSeen) {
        controls.start('visible');
        updateHasBeenSeen(!hasBeenSeen);
      }
      window.innerWidth < 768 && playVideo();
    } else {
      hasBeenSeen && window.innerWidth < 768 && pauseVideo();
    }
  }, [controls, inView]);

  const renderBlockContent = () => {
    return (
      <div className={'categories__block__inner' + (!hasLoaded ? ' categories__block__inner--loading' : '')} onMouseEnter={playVideo} onMouseLeave={pauseVideo}>
        <div className="categories__block__background-image" style={{ backgroundImage: `url(${poster})` }} />
        <video
          className="categories__block__video"
          poster={poster}
          ref={videoRef}
          muted
          loop
          playsInline
          preload="auto"
          onLoadedData={(res) => {
            updateHasLoaded(true);
          }}
          onError={(err) => {
            console.log('error', err);
          }}
        >
          <source src={`${background}#t=0.1`} type="video/mp4" />
        </video>
      </div>
    );
  };
  return (
    <motion.li key={name} className="categories__block motion_styles" variants={motion_styles} initial="hidden" animate={controls} ref={ref}>
      {/* JS2021-67 : Temp disable click*/}
      {/*<Link to={friendly_url} className={'categories__block__inner' + (!hasLoaded ? ' categories__block__inner--loading' : '')} onMouseEnter={playVideo} onMouseLeave={pauseVideo}>*/}
      {categoryProject_count === 1 && nameCheck !== 'creative services' ? (
        <Link to={categoryProjects[0].friendly_url}>{renderBlockContent()}</Link>
      ) : (
        <Link to={friendly_url}>{renderBlockContent()}</Link>
      )}
    </motion.li>
  );
};

export default CategoriesBlock;
