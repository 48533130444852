import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// import actions
import { displayMoreCollageImages, displayResetCollageImages } from 'store/pages/Pages.actions';

// import selectors
import { getCollageImages, shouldDisplayMoreButton } from 'store/pages/Pages.selectors';

// import components
import ImageCollageGridOne from 'components/image-collage/ImageCollage.grid_one';
import ImageCollageGridTwo from 'components/image-collage/ImageCollage.grid_two';
import SvgArrowDown from 'components/svg/generic/Svg.arrow_down';

const ImageCollageBase = (props) => {
  const { image_collage, showMore } = props;

  useEffect(() => {
    return () => {
      props.displayResetCollageImages();
    };
  }, []);

  return (
    <div className="image-collages">
      {image_collage.map((images, index) => {
        return index % 2 == 0 ? <ImageCollageGridOne data={images} key={index} /> : <ImageCollageGridTwo data={images} key={index} />;
      })}
      {showMore && (
        <button
          aria-label="Show more image collages"
          className="image-collages__show-more"
          onClick={() => {
            props.displayMoreCollageImages();
          }}
        >
          Load More <SvgArrowDown />
        </button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    image_collage: getCollageImages(state),
    showMore: shouldDisplayMoreButton(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    displayMoreCollageImages: () => {
      dispatch(displayMoreCollageImages());
    },
    displayResetCollageImages: () => {
      dispatch(displayResetCollageImages());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCollageBase);
