import React from 'react';
import { connect } from 'react-redux';

// import selectors
import { getSocialBlocks } from 'store/social/Social.selectors';

// import components
import SocialBlock from 'components/social/Social.block';

const SocialGrid = ({ blocks }) => {
  const renderBlocks = () => {
    return blocks.map((block, index) => {
      return <SocialBlock block={block} key={index} />;
    });
  };
  // const gridLayout = blocks && blocks.length % 4 === 1 ? 3 : 4;
  // return <ul className={`social__grid category__grid category__grid--${gridLayout}`}>{renderBlocks()}</ul>;

  return <ul className="social__grid categories__grid">{renderBlocks()}</ul>;
};

const mapStateToProps = (state) => {
  return {
    blocks: getSocialBlocks(state),
  };
};

export default connect(mapStateToProps, null)(SocialGrid);
