import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

// import components
import getPostit from 'components/svg/testimonials/Svg.postit';

const EmployeeTestimonialsBlock = ({ block }) => {
  const controls = useAnimation();
  const { type, title = '', description = '', name, role, color } = block;

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  // rise in
  const motion_styles = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  const details_className = `employee-testimonial__block__details ${color}`;
  const description_className = title ? 'employee-testimonial__block__description center' : 'employee-testimonial__block__description';
  const nameOut = role ? `${name}, ${role}` : `${name}`;

  return (
    <motion.li key={name} className="employee-testimonial__block motion_styles" variants={motion_styles} initial="hidden" animate={controls} ref={ref}>
      {getPostit(color)}
      <div className={details_className}>
        {type === 'message' && <div className="employee-testimonial__block__title">{title}</div>}
        <div className={description_className}>{description}</div>
        {type === 'testimonial' && <div className="employee-testimonial__block__name">{nameOut}</div>}
      </div>
    </motion.li>
  );
};

export default EmployeeTestimonialsBlock;
