// import consts
import { CATEGORIES_DISPATCH_DATA } from 'store/categories/Categories.actions';

// import parsers
import { parseCategories } from 'store/categories/Categories.parsers';

const initState = {
  allIDs: [],
  byID: {},
  categoryIdByType: {},
};

const Categories = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case CATEGORIES_DISPATCH_DATA: {
      const { allIDs, byID, categoryIdByType } = parseCategories(action.payload);
      nextState.allIDs = allIDs;
      nextState.byID = byID;
      nextState.categoryIdByType = categoryIdByType;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default Categories;
