export const BOOTSTRAP_INIT = '[APP] BOOTSTRAP_INIT';
export const BOOTSTRAP_COMPLETE = '[APP] BOOTSTRAP_COMPLETE';
export const ACTIVE_MOBILE_NAV = '[APP] ACTIVE_MOBILE_NAV';
export const ACTIVE_OVERLAY_MODAL = '[APP] ACTIVE_OVERLAY_MODAL';
export const VIEW_SOCIAL_CONTENT = '[APP] VIEW_SOCIAL_CONTENT';
export const startBootstrap = (data) => ({
  type: BOOTSTRAP_INIT,
  payload: data,
});

export const completeBootstrap = () => ({
  type: BOOTSTRAP_COMPLETE,
});

export const activeMobileNav = () => ({
  type: ACTIVE_MOBILE_NAV,
});

export const activateSocialContent = () => ({
  type: VIEW_SOCIAL_CONTENT,
});

export const activeOverlayModal = ({ active, type, id }) => ({
  type: ACTIVE_OVERLAY_MODAL,
  payload: { active, type, id },
});
