import React from 'react';

// import const
import { getUrlFrontend } from 'constants/app/AppUrls';

// import components
import SocialIconList from 'components/social-icons/SocialIcons.list';
import ContactForm from 'components/contact-form/ContactForm';

const ContactAddress = ({ data }) => {
  const { address_details = '', email = '', google_map_link = '', tel = '', title, description } = data;

  return (
    <section className="contact-address">
      <div className="bg-container">
        <div className="container container-title">
          <h1 className="contact-address__title">{title}</h1>
        </div>

        <div className="container container-rotate">
          <div className="container-unrotate">
            <div className="container-address">
              <div className="contact-address__left">
                <div className="contact-address__address">
                  <h2>Address:</h2>
                  <div className="address wysiwyg" dangerouslySetInnerHTML={{ __html: address_details }} />
                </div>
                <div className="contact-address__google-map">
                  <a href={google_map_link} target="_blank" rel="noreferrer">
                    Google Maps
                  </a>
                </div>
              </div>
              <div className="contact-address__center">
                <img src={getUrlFrontend('images/base/contact-page-map.png')}></img>
              </div>
              <div className="contact-address__right">
                <div className="contact-address__tel">
                  <h2>Telephone:</h2>
                  <a href={`tel:${tel}`}>{tel}</a>
                </div>
                <div className="contact-address__email">
                  <h2>Email:</h2>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
                <div className="contact-address__social">
                  <SocialIconList viewType="column" />
                </div>
              </div>
            </div>
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactAddress;
