import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// import consts
import { CONTENT_TYPE_CLIENT, CONTENT_TYPE_CATEGORY } from 'constants/app/AppConstants';

// import components
import CategoryBlock from 'components/category/Category.block';
import SvgArrowDown from 'components/svg/generic/Svg.arrow_down';

// import actions
import { displayMoreProjects, displayResetProjects } from 'store/projects/Projects.actions';

// import selectors
import { getProjectsByCategoryID, shouldDisplayMoreButton, getLimitedProjectsByClientID } from 'store/projects/Projects.selectors';

const CategoryGrid = (props) => {
  const { searchParam = '' } = props;
  useEffect(() => {
    return () => {
      props.displayResetProjects();
    };
  }, []);

  const renderBlocks = () => {
    const { data } = props;
    if (data.length > 0) {
      return data.map((block, index) => {
        const { details, isExternal } = block;
        const { external_url = '' } = details;

        return <CategoryBlock block={block} key={`${index}_external`} index={`${index}_external`} isExternal={isExternal} searchParam={searchParam} />;
      });
    }

    return null;
  };

  const gridLayout = props.data && props.data.length % 4 === 1 ? 3 : 4;

  return (
    <>
      <ul className={`category__grid category__grid--${gridLayout}`}>{renderBlocks()}</ul>
      {props.showMore && (
        <button
          aria-label="Show more projects"
          className="category__grid__show-more"
          onClick={() => {
            props.displayMoreProjects();
          }}
        >
          Load More <SvgArrowDown />
        </button>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { id = false, type = CONTENT_TYPE_CATEGORY } = ownProps;

  if (!id) {
    return {
      data: [],
      showMore: false,
    };
  }

  return {
    data: type === CONTENT_TYPE_CLIENT ? getLimitedProjectsByClientID(state, id) : getProjectsByCategoryID(state, ownProps.id),
    showMore: id ? shouldDisplayMoreButton(state, id, type) : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    displayMoreProjects: () => {
      dispatch(displayMoreProjects());
    },
    displayResetProjects: () => {
      dispatch(displayResetProjects());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryGrid);
