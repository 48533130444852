import React from 'react';

// import scss
import './Home.experts.scss';

// import svgs
import SvgLineyLines from 'components/svg/patterns/Svg.liney_lines';
import SvgSwirls from 'components/svg/patterns/Svg.swirls';

const HomeExperts = ({ data }) => {
  const { title, description, content } = data;

  const renderExpertBlocks = () => {
    return content.map((block, index) => {
      return (
        <li key={index} className="experts__block">
          <div className="experts__block__container">
            <h1 className="experts__block__title">{block.title}</h1>
            <h2 className="experts__block__description">{block.description}</h2>
          </div>
        </li>
      );
    });
  };

  const opts = { clazz: 'svg-icon--swirls', clr: '#ffd208' };

  return (
    <div className="experts">
      <SvgLineyLines />
      <SvgSwirls {...opts} />
      <div className="container">
        <div className="experts__title">{title}</div>
        <div className="experts__description">{description}</div>
        <ul className="experts__grid">{renderExpertBlocks()}</ul>
      </div>
    </div>
  );
};

export default HomeExperts;
