import { getUrlDynamic } from 'constants/app/AppUrls';

export const parseWhatWeDo = (data = {}) => {
  const { header = {}, headline = {} } = data;
  const { background_image: header_image = '' } = header;
  const { image: headline_image = '' } = headline;

  if (header_image !== '') {
    header.background_image = getUrlDynamic(header_image);
  }
  if (headline_image !== '') {
    headline.image = getUrlDynamic(headline_image);
  }

  return data;
};
