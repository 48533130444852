//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import consts
import { URL_PAGE_WHAT_WE_DO } from 'constants/app/AppUrls';

// import selectors
import { getCategoryByUrl } from 'store/categories/Categories.selectors';
import { getProjectsByCategoryID } from 'store/projects/Projects.selectors';
import { getSiteMetaData } from 'store/app/App.selectors';

// import components
import CategoryBase from 'components/pages/category/Category.base';
import SvgBlueDots from 'components/svg/patterns/Svg.blue_dots';
import SvgOrangeSquares from 'components/svg/patterns/Svg.orange_squares';
import SiteMetaData from 'components/generic/SiteMetaData';
import PageNotFoundBase from 'components/page-not-found/PageNotFound.base';

import SocialGrid from 'components/social/Social.grid';

import { trackPageview } from 'utils/tracking';

class PageSocial extends Component {
  componentDidMount() {
    trackPageview({ title: 'Social', path: this.props.location.pathname });
  }
  render() {
    const { category = false, metadata, isExact } = this.props;
    if (!category || !isExact) {
      return <PageNotFoundBase />;
    }
    const { type = '', content: { poster = '' } = {}, id } = category;

    const { title, description, og_title, og_description } = metadata;

    const base_meta_title = title.replace('{title}', type);
    const base_meta_description = description.replace('{title}', type);
    const base_og_title = og_title.replace('{title}', type);
    const base_og_description = og_description.replace('{title}', type);

    if (category) {
      return (
        <div className={'category category--social'}>
          <SiteMetaData {...this.props.metadata} title={base_meta_title} description={base_meta_description} og_description={base_og_description} og_title={base_og_title} og_image={poster} />
          <div className="container">
            <SvgBlueDots />
            <SvgOrangeSquares />
            <CategoryBase data={category} back_link={URL_PAGE_WHAT_WE_DO} />
          </div>
          <SocialGrid />
        </div>
      );
    }
    return (
      <div className="category">
        <SiteMetaData {...this.props.metadata} />
        <div className="container">No Data</div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const urlFragment = ownProps.match.url || '';
  const category = getCategoryByUrl(state, urlFragment);

  const { id = false, type } = category;

  return {
    category,
    categoryID: id,
    metadata: getSiteMetaData(state, 'individual_category'),
    isIllustrationCategoryType: type?.toLowerCase()?.includes('illustration') || false,
    isSocialCategoryType: type?.toLowerCase()?.includes('social') || false,
    isExact: ownProps.match.isExact,
  };
};

export default withRouter(connect(mapStateToProps, null)(PageSocial));
