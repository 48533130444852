import React from 'react';

// import scss
import './About.header.scss';

// import components
import SvgArrowDown from 'components/svg/generic/Svg.arrow_down';
import SvgLineyLines from 'components/svg/patterns/Svg.liney_lines';
import SvgYellowLineLines from 'components/svg/patterns/Svg.yellow_line_lines';

const AboutHeader = ({ data, scrollToCallBack }) => {
  const { background_image = '', description = '', title = '' } = data;

  const inlineStyle = background_image !== '' ? { backgroundImage: `url(${background_image})` } : {};

  return (
    <section className="about-header" style={inlineStyle}>
      <SvgLineyLines />
      <SvgYellowLineLines />
      <div className="container about-header__container">
        <h1 className="about-header__title">{title}</h1>
        <h2 className="about-header__description wysiwyg" dangerouslySetInnerHTML={{ __html: description }} />
        <div className="about-header__arrow">
          <button onClick={scrollToCallBack} aria-label="scroll to next page section">
            <SvgArrowDown />
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutHeader;
