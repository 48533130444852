// import consts
import { PROJECTS_DISPATCH_DATA, PROJECTS_DISPLAY_MORE, PROJECTS_DISPLAY_RESET } from 'store/projects/Projects.actions';

// import parsers
import { parseProjects } from 'store/projects/Projects.parsers';

const PROJECTS_DEFAULT_DISPLAY_LIMIT = 8;
const PROJECTS_ADDITIONIAL_DISPLAY_LIMIT = 4;

const initState = {
  allIDs: [],
  byID: {},
  byClientID: {},
  byCategoryID: {},
  latestProjectByID: [],
  numberToDisplay: PROJECTS_DEFAULT_DISPLAY_LIMIT,
};

const Projects = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case PROJECTS_DISPATCH_DATA: {
      const { allIDs, byID, byClientID, byCategoryID, latestProjectByID } = parseProjects(action.payload);
      nextState.allIDs = allIDs;
      nextState.byID = byID;
      nextState.byClientID = byClientID;
      nextState.byCategoryID = byCategoryID;
      nextState.latestProjectByID = latestProjectByID;
      return { ...state, ...nextState };
    }
    case PROJECTS_DISPLAY_MORE: {
      const nextCount = state.numberToDisplay + PROJECTS_ADDITIONIAL_DISPLAY_LIMIT;
      nextState.numberToDisplay = Math.min(state.allIDs.length, nextCount);

      return { ...state, ...nextState };
    }
    case PROJECTS_DISPLAY_RESET: {
      nextState.numberToDisplay = PROJECTS_DEFAULT_DISPLAY_LIMIT;

      return { ...state, ...nextState };
    }
  }
  return state;
};

export default Projects;
