import React from 'react';

// import scss
import './Categories.process.scss';

// import components
import { SvgHand } from 'components/svg/generic/Svg.inline_text';
import SvgSwirls from 'components/svg/patterns/Svg.swirls';
import SvgBlueDots from 'components/svg/patterns/Svg.blue_dots';
import getProcessIcon from 'components/svg/generic/Svg.processIcons';

const CategoriesProcessBlock = ({ block, index }) => {
  const { title = '', process01, process02, process03, process04 } = block;
  return (
    <li className={'categories__process_block'}>
      <div className="categories__process_block__details">
        {getProcessIcon(index)}
        <h2 className="categories__process_block_title">{title}</h2>
        <ul className="categories__process_block_list">
          <li>{process01}</li>
          <li>{process02}</li>
          <li>{process03}</li>
          <li>{process04}</li>
        </ul>
      </div>
    </li>
  );
};

const CategoriesProcessGrid = ({ processes }) => {
  const renderBlocks = () => {
    return processes.map((block, index) => {
      return <CategoriesProcessBlock block={block} key={index} index={index} />;
    });
  };
  return processes.length > 0 ? (
    <div className="container">
      <ul className="categories__process_grid">{renderBlocks()}</ul>
    </div>
  ) : null;
};

const CategoriesProcess = ({ data }) => {
  const { title = '', description = '', processes = [] } = data;
  const renderTitle = title.replace('{hand}', SvgHand);
  const dots = { clazz: 'svg-icon--dots', clr: '#47cdc7', opacity: '.8' };
  const swirls = { clazz: 'svg-icon--swirls', clr: '#ffd208' };
  return (
    <section className="categories__process">
      <div className="container">
        <SvgBlueDots {...dots} />
        <SvgSwirls {...swirls} />
        <h1 className="categories__title wysiwyg" dangerouslySetInnerHTML={{ __html: renderTitle }} />
        <h2 className="categories__description wysiwyg" dangerouslySetInnerHTML={{ __html: description }} />

        <div className="container categories__process__container">
          <CategoriesProcessGrid processes={processes} />
        </div>
      </div>
    </section>
  );
};

export default CategoriesProcess;
