import { getProjectsByClientID } from 'store/projects/Projects.selectors';

export const getClients = (state) => {
  const allIds = state.clients.allIDs;

  return allIds.map((id, index) => {
    const client = { ...state.clients.byID[id] };
    const projects = getProjectsByClientID(state, id);

    client.projects = projects;
    client.project_count = projects.length;

    return client;
  });
};

export const getClientByID = (state, id) => {
  if (state.clients.byID) {
    return state.clients.byID[id] || {};
  }
  return {};
};

export const getClientDataByUrl = (state, url) => {
  const clients = getClients(state);
  let id = '';
  clients.find((client) => {
    const {
      details: { friendly_url },
    } = client;
    if (friendly_url === url) {
      id = client.id;
    }
  });

  return {
    id,
    client: getClientByID(state, id),
    projects: getProjectsByClientID(state, id),
  };
};
