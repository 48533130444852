import React from 'react';

// import components
import EmployeeTestimonialsGrid from 'components/employee_testimonials/EmployeeTestimonials.grid';
import CurrentVacanciesGrid from 'components/current-vacancies/CurrentVacancies.grid';

const ContactBecomingJollywise = ({ data }) => {
  const { description = '', title = '', testimonials = [] } = data;

  return (
    <section className="contact-becoming-jollywise">
      <div className="container contact-becoming-jollywise__container">
        <h1 className="contact-becoming-jollywise__title">{title}</h1>
        <div className="contact-becoming-jollywise__description">{description}</div>

        <EmployeeTestimonialsGrid testimonials={testimonials} />
        <CurrentVacanciesGrid />
      </div>
    </section>
  );
};

export default ContactBecomingJollywise;
