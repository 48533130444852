// blogs type 'Current Vacancies' to display on contact page
export const getCurrentVacancies = (state) => {
  const ids = state.blogs.allContactPageIDs;

  return ids.map((id) => {
    return state.blogs.byContactPageID[id];
  });
};

export const getCurrentVacancyByUrl = (state, url) => {
  const ids = state.blogs.allContactPageIDs;

  if (ids.length > 0) {
    let i = 0;
    for (i; i < ids.length; i++) {
      const vacancy = state.blogs.byContactPageID[ids[i]];

      if (vacancy.friendly_url === url) {
        return vacancy;
      }
    }
  }

  return false;
};

export const getLatestBlogIds = (state, count = 2) => {
  return state.blogs.allBlogPageIDs.slice(0, count);
};

// Standard blog type selectors
export const getIdsHasActiveBlogId = (state, ids) => {
  // JS2021-67 :: leaving this extra check in for now - but inactive blog ids
  // are now removed in Blogs.parsers : parseBlogs
  return ids.reduce((acc, id) => {
    acc = state.blogs.byBlogPageID[id] ? true : false;
    return acc;
  }, false);
};

export const getBlogById = (state, id, specificVariable = false) => {
  const blog = state.blogs.byBlogPageID[id] || false;

  if (specificVariable && blog) {
    return blog[specificVariable];
  }

  return blog;
};

export const getBlogsByCount = (state, count) => {
  const ids = state.blogs.allBlogPageIDs.slice(0, count);
  return ids.map((id) => {
    return state.blogs.byBlogPageID[id];
  });
};

export const getBlogs = (state) => {
  const currentDisplayCount = state.blogs.numberToDisplay;

  const ids = state.blogs.allBlogPageIDs.slice(0, currentDisplayCount);
  return ids.map((id) => {
    return state.blogs.byBlogPageID[id];
  });
};

export const shouldDisplayMoreButton = (state) => {
  const currentDisplayCount = state.blogs.numberToDisplay;
  const totalCount = state.blogs.allBlogPageIDs.length;

  return currentDisplayCount < totalCount;
};

export const getBlogByUrl = (state, url) => {
  const ids = state.blogs.allBlogPageIDs;

  if (ids.length > 0) {
    let i = 0;
    for (i; i < ids.length; i++) {
      const blog = state.blogs.byBlogPageID[ids[i]];

      if (blog.friendly_url === url) {
        return blog;
      }
    }
  }

  return getCurrentVacancyByUrl(state, url);
};
