import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { Link } from 'react-router-dom';
import { LazyImage } from 'react-lazy-images';

import PictureMappings from 'components/picture-mappings/PictureMappings';

const CurrentVacanciesBlock = ({ block, index }) => {
  const { content, details, title, friendly_url } = block;
  const { main_image, slider_image } = content;
  const { posted, intro } = details;

  const controls = useAnimation();

  const blog_motion = {
    hidden: { y: 60, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ease: 'easeInOut',
        duration: 1.2,
        delay: index % 2 === 0 ? 0.5 : 0.3,
      },
    },
  };

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [inView]);

  return (
    <motion.li key={title} variants={blog_motion} initial="hidden" animate={controls} ref={ref} className={'current-vacancies__block' + (index === 0 ? ' ' : '')}>
      <LazyImage
        src={slider_image}
        alt={title}
        placeholder={({ imageProps, ref }) => <div className="lazy-load__placeholder" ref={ref} />}
        actual={({ imageProps }) => <PictureMappings {...imageProps} caption={title} class_name="current-vacancies__block__image" />}
      />
      <div className="current-vacancies__block__body">
        <div className="current-vacancies__block__left-section">
          {/* <div className="current-vacancies__block__date">{posted}</div> */}
          <div className="current-vacancies__block__intro">{intro}</div>
        </div>
        <div className="current-vacancies__block__right-section">
          <Link to={friendly_url} className="current-vacancies__block__link">
            Find Out More
          </Link>
        </div>
      </div>
    </motion.li>
  );
};

export default CurrentVacanciesBlock;
