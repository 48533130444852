import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { Link } from 'react-router-dom';
import { LazyImage } from 'react-lazy-images';

const BlogsBlock = ({ block, index }) => {
  const { content, details, title, friendly_url } = block || {};
  const { slider_image = '', header_image = '' } = content;
  const { posted, intro = 'Read more about this case study', name = title } = details;

  const controls = useAnimation();

  const blog_motion = {
    hidden: { y: 60, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ease: 'easeInOut',
        duration: 1.2,
        delay: index % 2 === 0 ? 0.5 : 0.3,
      },
    },
  };

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [inView]);

  return (
    <motion.li key={name} variants={blog_motion} initial="hidden" animate={controls} ref={ref} className={'blogs__block' + (index === 0 ? ' blogs__block--wide' : '')}>
      <figure className="blogs__block__image">
        <LazyImage
          src={header_image !== '' ? header_image : slider_image}
          alt={name}
          placeholder={({ imageProps, ref }) => <div className="lazy-load__placeholder" ref={ref} />}
          actual={({ imageProps }) => <img {...imageProps} />}
        />
        <figcaption>{name}</figcaption>
      </figure>
      <div className="blogs__block__body">
        <div className="blogs__block__left-section">
          {/*JS2021-67 : tempoarary disable blog date*/}
          {/*<div className="blogs__block__date">{posted}</div>*/}
          <h2 className="blogs__block__intro">{intro}</h2>
        </div>
        <div className="blogs__block__right-section">
          <Link to={friendly_url} className="blogs__block__link">
            Read it
          </Link>
        </div>
      </div>
    </motion.li>
  );
};

export default BlogsBlock;
