import { getUrlDynamic } from 'constants/app/AppUrls';
import { CONTENT_TYPE_PROJECT, CONTENT_TYPE_BLOG, EXPERTS_PLACEHOLDER } from 'constants/app/AppConstants';

export const parseHome = (data = []) => {
  const { header = {}, showcase_block_1, showcase_block_2, showcase_block_3, showcase_content = '', expert_block = {} } = data;

  data.showcase = {};
  data.showcase.ids = showcase_content !== '' ? [showcase_content] : [showcase_block_1, showcase_block_2, showcase_block_3];

  // parse award images
  const { awards = [], background_image: header_image = '' } = header;

  if (awards.length > 0) {
    awards.map((award) => {
      award.image = getUrlDynamic(award.image);
    });
  }

  if (header_image !== '') {
    header.background_image = getUrlDynamic(header_image);
  }

  // Experts in block
  let expert_data = { ...EXPERTS_PLACEHOLDER };

  expert_data.title = (expert_block?.title || '') !== '' ? expert_block.title : expert_data.title;
  expert_data.description = (expert_block?.description || '') !== '' ? expert_block.description : expert_data.description;
  expert_data.content = (expert_block?.content || []).length > 0 ? expert_block.content : expert_data.content;

  data.expert_block = expert_data;

  return data;
};
