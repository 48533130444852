import React, { useEffect, useRef } from 'react';

// import scss
import './Home.subheader.scss';

// import utils
import { convertFileExtensionToWebp } from 'utils/general';

const HomeSubHeader = ({ data, scrollTo }) => {
  const textOptions = ['children and families', 'brands', 'fans'];
  const { background_image = '' } = data;

  const inlineStyle = background_image !== '' ? { backgroundImage: `url(${convertFileExtensionToWebp(background_image)})` } : {};

  const spanRef = useRef();
  let timeout = null;
  let textTypeOptions = {
    loopNum: 0,
    period: 2000,
    txt: '',
    isDeleting: false,
  };

  const triggerTextType = () => {
    textType();
  };

  const textType = () => {
    let i = textTypeOptions.loopNum % textOptions.length;
    let fullTxt = textOptions[i];

    textTypeOptions.txt = textTypeOptions.isDeleting ? fullTxt.substring(0, textTypeOptions.txt.length - 1) : fullTxt.substring(0, textTypeOptions.txt.length + 1);

    spanRef.current.innerHTML = textTypeOptions.txt;

    const speed = fullTxt.length > 10 && !textTypeOptions.isDeleting ? 125 : 200;
    let delta = speed - Math.random() * 100;

    if (textTypeOptions.isDeleting) {
      delta /= 2;
    }

    if (!textTypeOptions.isDeleting && textTypeOptions.txt === fullTxt) {
      delta = textTypeOptions.period;
      textTypeOptions.isDeleting = true;
    } else if (textTypeOptions.isDeleting && textTypeOptions.txt === '') {
      textTypeOptions.isDeleting = false;
      textTypeOptions.loopNum++;
      delta = 500;
    }

    timeout = setTimeout(function () {
      triggerTextType();
    }, delta);
  };

  useEffect(() => {
    textType();

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <section ref={scrollTo} className="home-subheader" style={inlineStyle}>
      <div className="home-subheader__middle-region">
        <div className="container">
          <div className="title">
            <h3>
              We create content <span ref={spanRef}>{textOptions[0]}</span> love
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSubHeader;
