import React from 'react';
import { connect } from 'react-redux';

// import selectors
import { getBlogById, getIdsHasActiveBlogId, getLatestBlogIds } from 'store/blogs/Blogs.selectors';
import { getProjectDataByID } from 'store/projects/Projects.selectors';

// import components
import BlogRelatedBlock from 'components/blog/Blog.related_block';

const BlogRelatedGrid = (props) => {
  const renderBlocks = () => {
    return props.data.map((block, index) => {
      return block ? <BlogRelatedBlock block={block} key={index} index={index} /> : null;
    });
  };
  return props.hasActiveRelatedBlogs ? (
    <div>
      <h3 className="blog__related_grid__title">You may also like...</h3>
      <ul className="blog__related_grid">{renderBlocks()}</ul>
    </div>
  ) : null;
};

const mapStateToProps = (state, ownProps) => {
  const { ids = 'latest', related_data = [] } = ownProps;

  if (related_data.length > 0) {
    return {
      data: related_data,
      hasActiveRelatedBlogs: true,
    };
  }

  const blogIDs = ids === 'latest' ? getLatestBlogIds(state) : ids;
  const hasActiveRelatedBlogs = getIdsHasActiveBlogId(state, blogIDs);
  const data = blogIDs.map((id) => {
    return getBlogById(state, id);
  });

  return {
    data,
    hasActiveRelatedBlogs,
  };
};

export default connect(mapStateToProps, null)(BlogRelatedGrid);
