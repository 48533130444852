//magic things
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

// import pages
import PageProject from 'containers/sub-pages/Pages.project';
import PageNotFound from 'containers/pages/Pages.not_found';

// import containers
import Main from 'containers/sections/Sections.main';

class RouterProjects extends Component {
  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <Switch location={this.props.location}>
          <Route
            path={`${match.path}/:urlFragment`}
            render={(props) => (
              <Main>
                <PageProject {...props} />
              </Main>
            )}
          />
          <Route
            render={(props) => (
              <Main>
                <PageNotFound {...props} />
              </Main>
            )}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
export default withRouter(RouterProjects);
