import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const random = Math.random() * (1 - 0) + 0;
    const delay = 0.5 + random * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.1 },
      },
    };
  },
};

const SvgLinesCircle = ({ clazz = 'svg-icon--lines-circle', clr = 'rgba(255,210,8,0.4)' }) => {
  const controls = useAnimation();

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <motion.svg ref={ref} variants={draw} initial="hidden" animate={controls} xmlns="http://www.w3.org/2000/svg" width="303.92" height="294.602" viewBox="0 0 303.92 294.602" className={clazz}>
      <g data-name="Group 847" fill="none" stroke={clr} strokeLinecap="round" strokeMiterlimit="10" strokeWidth="10">
        <motion.path variants={draw} data-name="Path 1622" d="M183.264 66.226a105.489 105.489 0 0 0 11.868 48.126" />
        <motion.path variants={draw} data-name="Path 1623" d="m214.545 84.226 33.134-5.762" />
        <motion.path variants={draw} data-name="Path 1624" d="M217.993 107.365a113.843 113.843 0 0 1 15.141 22.767" />
        <motion.path variants={draw} data-name="Path 1625" d="M262.102 58.204a113.892 113.892 0 0 1 15.141 22.768" />
        <motion.path variants={draw} data-name="Path 1626" d="M209.924 130.424a45.919 45.919 0 0 0-20.038 25.469" />
        <motion.path variants={draw} data-name="Path 1627" d="M181.7 130.933a187.625 187.625 0 0 1-35.253 2.131" />
        <motion.path variants={draw} data-name="Path 1628" d="M297.612 130.472a187.544 187.544 0 0 1 .839 35.306" />
        <motion.path variants={draw} data-name="Path 1629" d="m144.975 84.138 19.844 26.58" />
        <motion.path variants={draw} data-name="Path 1630" d="M130.659 107.214a84.48 84.48 0 0 0-21.475 25.331" />
        <motion.path variants={draw} data-name="Path 1631" d="M137.553 155.561a168.339 168.339 0 0 0 29.359 29.389" />
        <motion.path variants={draw} data-name="Path 1632" d="M181.992 175.739A179.526 179.526 0 0 1 211.7 206.63" />
        <motion.path variants={draw} data-name="Path 1633" d="M225.728 155.987a162.628 162.628 0 0 0 35.336-10.8" />
        <motion.path variants={draw} data-name="Path 1634" d="M254.337 103.519a155.03 155.03 0 0 1 28.578 19.729" />
        <motion.path variants={draw} data-name="Path 1635" d="M271.407 163.575a58.868 58.868 0 0 0-10.554 20.639" />
        <motion.path variants={draw} data-name="Path 1636" d="m232.78 185.015 11.824 31.392" />
        <motion.path variants={draw} data-name="Path 1637" d="M219.521 230.404a84.441 84.441 0 0 1-32.3 7.589" />
        <motion.path variants={draw} data-name="Path 1638" d="M263.818 245.916a84.478 84.478 0 0 1-32.3 7.588" />
        <motion.path variants={draw} data-name="Path 1639" d="M89.907 244.94a84.5 84.5 0 0 1-32.3 7.589" />
        <motion.path variants={draw} data-name="Path 1640" d="m179.833 202.803-33.4 16.983" />
        <motion.path variants={draw} data-name="Path 1641" d="M135.168 178.32a75.036 75.036 0 0 0-8.41 47.074" />
        <motion.path variants={draw} data-name="Path 1642" d="M84.074 177.414a80.783 80.783 0 0 0 23.476 11.252c1.712.5 4.576-.533 3.5-1.957" stroke-linejoin="round" />
        <motion.path variants={draw} data-name="Path 1643" d="M107.22 151.912a157.414 157.414 0 0 0-42.176-19.793" />
        <motion.path variants={draw} data-name="Path 1644" d="M144.954 52.734q-16.16 10.748-31.689 22.407" />
        <motion.path variants={draw} data-name="Path 1645" d="m74.54 77.685 22.137 36.99" />
        <motion.path variants={draw} data-name="Path 1646" d="M168.024 43.393a255.774 255.774 0 0 1 40.618 17.64" />
        <motion.path variants={draw} data-name="Path 1647" d="m111.007 35.38-19.59 27.956" />
        <motion.path variants={draw} data-name="Path 1648" d="M133.658 31.756a271.864 271.864 0 0 0 43.774-24.8" />
        <motion.path variants={draw} data-name="Path 1649" d="M208.431 19.138a236.221 236.221 0 0 0 23.808 26.353" />
        <motion.path variants={draw} data-name="Path 1650" d="M42.146 179.609c-7.8-12.271-20.71-21.953-35.842-26.89" />
        <motion.path variants={draw} data-name="Path 1651" d="M116.426 8.868a61.1 61.1 0 0 0-37.005 17.631" />
        <motion.path variants={draw} data-name="Path 1652" d="M31.406 110.676a79.163 79.163 0 0 0 36.331-7.835" />
        <motion.path variants={draw} data-name="Path 1653" d="m48.232 142.065 15.735 39.005" />
        <motion.path variants={draw} data-name="Path 1654" d="m65.212 210.651 30.474 12.04" />
        <motion.path variants={draw} data-name="Path 1655" d="M109.188 255.272a120.6 120.6 0 0 1 46.877 13.622" />
        <motion.path variants={draw} data-name="Path 1656" d="m154.928 236.737 25.058 25.21" />
        <motion.path variants={draw} data-name="Path 1657" d="m287.457 206.66-12.188 15.873" />
        <motion.path variants={draw} data-name="Path 1658" d="M200.397 259.845a90.54 90.54 0 0 0 15.526 17" />
        <motion.path variants={draw} data-name="Path 1659" d="M101.985 285.564a121.289 121.289 0 0 0 25.895 3.845" />
        <motion.path variants={draw} data-name="Path 1660" d="M23.536 199.228a121.289 121.289 0 0 0 16.015 20.709" />
        <motion.path variants={draw} data-name="Path 1661" d="M49.27 60.838 32.928 77.985" />
      </g>
    </motion.svg>
  );
};

export default SvgLinesCircle;
