import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

// Framer motion variables
const project_container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.7,
      staggerChildren: 0.2,
    },
  },
};
const project_item = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1 },
};

const ModalLayoutClient = ({ data, closeCallback, scrollbarCallback }) => {
  const { client, projects } = data;

  const onAnimationComplete = (e) => {
    scrollbarCallback();
  };

  const renderClientDetails = () => {
    const { content, details } = client;
    const { logo } = content;
    const { name } = details;
    return (
      <>
        <div className="modal-layout__client__logo">
          <img src={logo} alt={name} title={name} />
        </div>
        <div className="modal-layout__client__title">{`We've worked with the ${name} on:`}</div>
      </>
    );
  };

  const renderProjects = () => {
    return (
      <motion.ul id="modal-content" className="modal-layout__project__list" variants={project_container} initial="hidden" animate="visible" onAnimationComplete={onAnimationComplete}>
        {projects.map((project, index) => {
          const { details, content, friendly_url } = project;
          const { name } = details;
          const { cta_image } = content;
          return (
            <motion.li key={index} className="modal-layout__project__item" variants={project_item}>
              <div className="modal-layout__project__details">
                <div className="title">{name}</div>
                <figure className="image">
                  <img src={cta_image} alt={name} title={name} />
                </figure>
                <Link to={friendly_url} onClick={closeCallback}>
                  <button className="button" aria-label={`Read our case study about ${name}`}>
                    Click me
                  </button>
                </Link>
              </div>
            </motion.li>
          );
        })}
      </motion.ul>
    );
  };
  return (
    <div className="modal-layout__client">
      {renderClientDetails()}
      {projects.length > 0 && renderProjects()}
    </div>
  );
};

export default ModalLayoutClient;
