//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';

// import consts
import { MODAL_OVERLAY_CLOSE, MODAL_OVERLAY_TYPE_CLIENT, MODAL_OVERLAY_TYPE_EMPLOYEE } from 'constants/app/AppConstants';

// import actions
import { activeOverlayModal } from 'store/app/App.actions';

// import selectors
import { getOverlayModalData, isActiveOverlayModal } from 'store/app/App.selectors';

// import components
import ModalLayoutClient from 'components/modal-layouts/ModalLayouts.client';
import ModalLayoutEmployee from 'components/modal-layouts/ModalLayouts.employee';
import SvgClose from 'components/svg/generic/Svg.close';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

class ModalOverlay extends Component {
  constructor(props) {
    super(props);

    // bind function
    this.closeModal = this.closeModal.bind(this);
    this.modal = React.createRef();

    this.scrollbarCallback = this.scrollbarCallback.bind(this);
  }

  componentWillUmount() {
    document.body.style.overflow = '';
  }

  componentDidUpdate() {
    document.body.style.overflow = this.props.active ? 'hidden' : '';
  }

  scrollbarCallback() {
    // if (this.props.active) {
    //   const contentHeight = document.getElementById('modal-content')?.getBoundingClientRect()?.height || 0;
    //   const modalHeight = this.modal.current.getBoundingClientRect().height;
    //   const contentContainer = this.modal.current.querySelectorAll('.container')[0];
    //
    //   if (contentHeight > modalHeight) {
    //     contentContainer.style.overflowY = 'scroll';
    //   } else {
    //     contentContainer.style.overflowY = 'hidden';
    //   }
    // }
  }

  closeModal() {
    this.props.closeModal({ active: false });
  }

  renderLayout() {
    const { data, type } = this.props;
    switch (type) {
      case MODAL_OVERLAY_TYPE_CLIENT:
        return <ModalLayoutClient data={data} closeCallback={this.closeModal} scrollbarCallback={this.scrollbarCallback} />;
        break;

      case MODAL_OVERLAY_TYPE_EMPLOYEE:
        return <ModalLayoutEmployee data={data} closeCallback={this.closeModal} scrollbarCallback={this.scrollbarCallback} />;
        break;
      default:
        return null;
    }
  }

  render() {
    const { active } = this.props;
    return (
      <AnimatePresence>
        {active && (
          <motion.div className="modal modal--overlay" key="overlay" initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 1 }} ref={this.modal}>
            <SimpleBar style={{ height: 'calc(100vh - 70px)' }} autoHide={false}>
              <div className="container">
                <button className="modal__close" aria-label="close overlay" onClick={this.closeModal}>
                  <SvgClose />
                </button>
                {this.renderLayout()}
              </div>
            </SimpleBar>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
}
const mapStateToProps = (state) => {
  const { type = '', data = {} } = getOverlayModalData(state);

  return {
    type,
    data,
    active: isActiveOverlayModal(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: (opts) => {
      dispatch(activeOverlayModal(opts));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalOverlay);
