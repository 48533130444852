// import consts
import { PAGES } from 'store/pages/Pages.reducer';

// import selectors
import { getBlogById } from 'store/blogs/Blogs.selectors';
import { getProjectDataByID } from 'store/projects/Projects.selectors';

export const getPageDataByName = (state, name) => {
  return state.pages[name] || {};
};

export const getCollageImages = (state) => {
  const currentDisplayCount = state.pages.numberToDisplay;

  const images = getPageDataByName(state, PAGES.WHO_WE_ARE).image_collage;
  return images.slice(0, currentDisplayCount);
};

export const shouldDisplayMoreButton = (state) => {
  const images = getPageDataByName(state, PAGES.WHO_WE_ARE).image_collage;
  const currentDisplayCount = state.pages.numberToDisplay;
  const totalCount = images.length;

  return currentDisplayCount < totalCount;
};

export const getHomepageShowcaseContent = (state) => {
  const ids = state.pages.home.showcase.ids.slice(0, 2);

  return ids.map((id) => {
    return id.includes('project') ? getProjectDataByID(state, id.replace('project_', '')) : getBlogById(state, id.replace('blog_', ''));
  });
};
