import React, { useEffect, useRef } from 'react';

// import scss
import './Home.header.scss';

// import consts
import { getUrlFrontend } from 'constants/app/AppUrls';

// import components
import HomeShowreel from 'components/pages/home/Home.showreel';
import HomeAwards from 'components/pages/home/Home.awards';
import SvgArrowDown from 'components/svg/generic/Svg.arrow_down';
import PictureMappings from 'components/picture-mappings/PictureMappings';
import { IS_DESKTOP } from 'constants/app/AppConstants';

const HomeHeader = ({ data, scrollToCallBack }) => {
  const { awards = [], showreel_link = '', title = '' } = data;

  const videoRef = useRef();
  const videoPath = IS_DESKTOP ? `videos/jw_header_video_v02_md.mp4` : `videos/jw_header_video_mobile_v02.mp4`;
  const videoPosterPath = IS_DESKTOP ? `videos/jw_header_video_v02_poster.png` : `videos/jw_header_video_v02_poster_mobile.png`;

  useEffect(() => {
    videoRef.current.defaultMuted = true;
    videoRef.current.muted = true;
  }, []);

  return (
    <section className="home-header">
      <video
        loop
        playsInline
        muted
        autoPlay
        preload="none"
        poster={getUrlFrontend('videos/jw_header_video_v02_poster.png')}
        ref={videoRef}
        onLoad={() => {
          // videoRef.current.play();
        }}
      >
        <source src={getUrlFrontend('videos/jw_header_video_v02_md.mp4#t=0.1')} type="video/mp4" />
      </video>

      <div className="home-header__middle-region">
        <div className="container">
          <div className="jollywise-logo">
            <PictureMappings src={getUrlFrontend('images/base/jollywise-logo.png')} alt={'Jollywise Digital Company'} title={'Jollywise Digital Company'} />
          </div>
        </div>
      </div>

      <div className="home-header__bottom-region">
        <div className="container">
          <div className="home-header__arrow">
            <button onClick={scrollToCallBack} aria-label="scroll to next page section">
              <SvgArrowDown />
            </button>
          </div>
          {awards.length > 0 && <HomeAwards awards={awards} />}
          {showreel_link !== '' && <HomeShowreel link={showreel_link} />}
        </div>
      </div>
    </section>
  );
};

export default HomeHeader;
