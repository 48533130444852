// import consts
import { EMPLOYEES_DISPATCH_DATA } from 'store/employees/Employees.actions';

// import parsers
import { parseEmployees } from 'store/employees/Employees.parsers';

const initState = {
  allIDs: [],
  byID: {},
};

const Employees = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case EMPLOYEES_DISPATCH_DATA: {
      const { allIDs, byID } = parseEmployees(action.payload);
      nextState.allIDs = allIDs;
      nextState.byID = byID;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default Employees;
