import { BOOTSTRAP_COMPLETE, ACTIVE_MOBILE_NAV, ACTIVE_OVERLAY_MODAL, VIEW_SOCIAL_CONTENT } from 'store/app/App.actions';
import { BOOTSTRAP_CONFIG_DATA } from 'store/bootstrap/Bootstrap.actions';

// import parsers
import { parseNavigation } from 'store/app/parsers/Navigation.parser';
import { parseFooter } from 'store/app/parsers/Footer.parser';
import { parseMetaData } from 'store/app/parsers/Metadata.parser';

const initState = {
  bootstrapComplete: false,
  footer: [],
  medium: {},
  meta: {},
  navigation: {},
  social_media: [],
  activeOverlayModal: false,
  overlayModalData: {},
  activeMobileNav: false,
  viewSocialContent: false,
};

const App = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case BOOTSTRAP_CONFIG_DATA: {
      const { footer = [], medium = {}, meta = {}, navigation = {}, social_media = [] } = action.payload;
      nextState.footer = parseFooter(footer);
      nextState.medium = medium;
      nextState.meta = parseMetaData(meta);
      nextState.navigation = parseNavigation(navigation);
      nextState.social_media = social_media.content || [];
      return { ...state, ...nextState };
    }
    case ACTIVE_OVERLAY_MODAL: {
      const { active = false, id, type } = action.payload;
      nextState.activeOverlayModal = active;
      nextState.overlayModalData = active ? { id, type } : {};
      return { ...state, ...nextState };
    }
    case ACTIVE_MOBILE_NAV:
      nextState.activeMobileNav = !state.activeMobileNav;
      return { ...state, ...nextState };
    case VIEW_SOCIAL_CONTENT:
      nextState.viewSocialContent = !state.viewSocialContent;
      return { ...state, ...nextState };
    case BOOTSTRAP_COMPLETE:
      nextState.bootstrapComplete = true;
      document.getElementById('preloader').classList.add('hidden');
      return { ...state, ...nextState };
  }
  return state;
};

export default App;
