import { getProjectByID } from 'store/projects/Projects.selectors';

export const getSocialBlocks = (state) => {
  const allIds = state.social.blocks.allIDs;
  const returnableData = [];

  allIds.map((id) => {
    const block = state.social.blocks.byID[id] || false;

    if (!block) return;
    const { type = '', details = {} } = block;
    let blockDetails = block;

    if (type === 'case_study') {
      const baseBlockDetails = { ...getProjectByID(state, details?.id || '') };
      blockDetails = { ...baseBlockDetails, details: { ...baseBlockDetails.details, override_title: details?.override_title || '' } };
    }

    blockDetails && returnableData.push(blockDetails);
  });

  return returnableData;
};

export const getSocialPageByUrl = (state, url) => {
  const allIds = state.social.pages.allIDs;

  let returnableData = {};

  allIds.find((id) => {
    const page = state.social.pages.byID[id] || false;
    const { friendly_url } = page;

    if (friendly_url == url) {
      returnableData = page;
      return;
    }
  });

  return returnableData;
};

export const getSocialContentByTypeIDs = (state, types) => {
  const returnableData = [];

  types.map((id) => {
    const allIds = state.social.content.contentByTypeId[parseInt(id)];

    allIds.find((id) => {
      const page = state.social.content.byID[id] || false;
      page && returnableData.push(page);
    });
  });

  return returnableData;
};
