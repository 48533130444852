import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
// import SVG
import { SvgChevronDown } from 'components/svg/generic/Svg.chevron_down';

const SocialContentBlock = ({ block, isActive, updateActiveBlock, isOnLastRow }) => {
  // state
  const [hasBeenSeen, updateHasBeenSeen] = useState(false);
  const [hasLoaded, updateHasLoaded] = useState(false);

  const videoRef = useRef();
  const controls = useAnimation();
  const { id, content, details } = block;
  const { cta_image, slides, cta_type, cta_video, cta_video_placeholder, activeSlideshow } = content;
  const { name, description } = details;

  const { ref, inView, entry } = useInView({
    threshold: window.innerWidth > 768 ? 0.1 : 0.4,
    triggerOnce: true,
  });

  // rise in
  const motion_styles = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const playVideo = () => {
    hasLoaded && videoRef.current && videoRef.current.play();
  };

  const pauseVideo = () => {
    setTimeout(() => {
      if (hasLoaded && videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }, 100);
  };

  useEffect(() => {
    !activeSlideshow && isActive ? playVideo() : pauseVideo();
    inView ? controls.start('visible') : controls.start('hidden');
  }, [isActive, inView, isOnLastRow]);

  const renderBlockContent = () => {
    return (
      <div
        className={
          'social-content__block__container' +
          (isActive ? ' social-content__block__container--active' : '') +
          (isOnLastRow ? ' social-content__block__container--last-row' : '') +
          (cta_type == 'video' ? ' has-video' : '') +
          (!activeSlideshow && cta_type !== 'video' ? ' has-image' : '')
        }
      >
        <div
          className={'social-content__block__inner' + (cta_type == 'video' && !hasLoaded ? ' social-content__block__inner--loading' : '')}
          onClick={() => {
            updateActiveBlock(block);
          }}
        >
          <div className="social-content__block__background-image" style={{ backgroundImage: `url(${cta_type == 'video' ? cta_video_placeholder : cta_image})` }} />
          <div className="social-content__block__arrow-icon">
            <button className="btn btn--flip-out" type="button">
              <SvgChevronDown clazz={'btn__icon btn__icon--bottom'} />
              <SvgChevronDown clazz={'btn__icon btn__icon--top'} />
            </button>
          </div>
          {cta_type == 'video' && (
            <video
              className="social-content__block__video"
              poster={cta_video_placeholder}
              ref={videoRef}
              muted
              loop
              playsInline
              preload="auto"
              onLoadedData={(res) => {
                updateHasLoaded(true);
              }}
              onError={(err) => {
                console.log('error', err);
              }}
            >
              <source src={`${cta_video}#t=0.1`} type="video/mp4" />
            </video>
          )}
        </div>
        {!activeSlideshow && <section className="social-content__block__desc">{description}</section>}
      </div>
    );
  };

  return (
    <motion.li key={name} className="social-content__block motion_styles" variants={motion_styles} initial="hidden" animate={controls} ref={ref}>
      {renderBlockContent()}
    </motion.li>
  );
};

export default SocialContentBlock;
