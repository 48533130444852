import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { Link } from 'react-router-dom';
import { LazyImage } from 'react-lazy-images';

const BlogRelatedBlock = ({ block, index }) => {
  const { content, details, friendly_url } = block;

  const { header_image = '' } = content;
  const { description = '', name = '' } = details;

  const { slider_image = header_image } = content;
  const { posted, intro = 'Read more about this case study' } = details;
  const { title = name } = block;

  const controls = useAnimation();

  const blog_motion = {
    hidden: { y: 60, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ease: 'easeInOut',
        duration: 1.2,
        delay: index % 2 === 0 ? 0.5 : 0.3,
      },
    },
  };

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [inView]);

  return (
    <motion.li key={title} variants={blog_motion} initial="hidden" animate={controls} ref={ref} className={'blog__related_block'}>
      <figure className="blog__related_block__image">
        <LazyImage src={slider_image} alt={title} placeholder={({ imageProps, ref }) => <div className="lazy-load__placeholder" ref={ref} />} actual={({ imageProps }) => <img {...imageProps} />} />
        <figcaption>{title}</figcaption>
      </figure>
      <div className="blog__related_block__body">
        <div className="blog__related_block__left-section">
          <div className="blog__related_block__date">{posted}</div>
          <div className="blog__related_block__intro" dangerouslySetInnerHTML={{ __html: intro }} />
        </div>
        <div className="blog__related_block__right-section">
          <Link to={friendly_url} className="blog__related_block__link">
            Read it
          </Link>
        </div>
      </div>
    </motion.li>
  );
};

export default BlogRelatedBlock;
