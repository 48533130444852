export const BLOGS_DISPATCH_DATA = 'BLOGS_DISPATCH_DATA';
export const BLOGS_DISPLAY_MORE = 'BLOGS_DISPLAY_MORE';
export const BLOGS_DISPLAY_RESET = 'BLOGS_DISPLAY_RESET';

export const displayMoreBlogs = () => ({
  type: BLOGS_DISPLAY_MORE,
});

export const displayResetBlogs = () => ({
  type: BLOGS_DISPLAY_RESET,
});
