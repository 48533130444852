import { getUrlDynamic, getUrlByContentType } from 'constants/app/AppUrls';
import { CONTENT_TYPE_ILLUSTRATION } from 'constants/app/AppConstants';

const parseData = (illustration) => {
  const { data, id, name } = illustration;
  const { background_image = '', description = '', placeholder_image = '' } = data;

  const parsedIllustration = {
    id,
    type: name,
    details: {
      name,
    },
    content: {
      background: getUrlDynamic(background_image),
      description,
      poster: getUrlDynamic(placeholder_image),
    },
  };

  return parsedIllustration;
};

export const parseIllustrations = (data) => {
  const allIDs = [];
  const byID = {};
  const illustrationIdByType = {};

  for (const illustration of data) {
    const { id, name } = illustration;
    const parsed = parseData(illustration);
    allIDs.push(id);
    byID[id] = { ...parsed };

    illustrationIdByType[name] = id;
  }
  return { allIDs, byID, illustrationIdByType };
};
