// Get document direction
import { APP_MODES, NUMBER_FORMATTING, USE_WEBP } from 'constants/app/AppConstants';

export const isRTL = () => {
  let direction;
  document.dir !== undefined ? (direction = document.dir) : (direction = document.getElementsByTagName('html')[0].getAttribute('dir'));
  return direction === 'rtl';
};

export const getAppMode = () => {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    return { mode: APP_MODES.APP, description: 'Viewing mode: WebApp' };
  } else if (window.navigator.standalone === true) {
    return { mode: APP_MODES.APP, description: 'Viewing mode: WebApp' };
  } else {
    return { mode: APP_MODES.DESKTOP, description: 'Viewing mode: Desktop' };
  }
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, duration = 300) => {
  let timeout;
  return function (...args) {
    const effect = () => {
      timeout = null;
      return func.apply(this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(effect, duration);
  };
};

// Format Large numbers
export function kFormatter(num) {
  return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k' : Math.sign(num) * Math.abs(num);
}

// Check of object is empty
export const isEmptyObj = (obj) => {
  if (typeof obj !== 'undefined') {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  } else {
    return null;
  }
};

export const numberWithFormatting = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, NUMBER_FORMATTING);
};

export const msieversion = () => {
  const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11

  return msie > 0 || trident > 0;
};

export const windowScrollTo = (pos) => {
  window.scrollTo({
    top: pos,
    left: 0,
    behavior: 'smooth',
  });
};

//stackoverflow.com/questions/5573096/detecting-webp-support
export const canUseWebP = () => {
  const elem = document.createElement('canvas');
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!(elem.getContext && elem.getContext('2d'))) {
    const testString = !(window.mozInnerScreenX == null) ? 'png' : 'webp';
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/' + testString) == 0;
  }
  // very old browser like IE 8, canvas not supported
  return false;
};

export const convertFileExtensionToWebp = (url) => {
  if (!url) {
    return '';
  }
  return USE_WEBP ? url.replace(new RegExp('.(' + ['jpg', 'jpeg', 'png', 'JPG', 'PNG', 'JPEG'].join('|') + ')$', 'i'), '.webp') : url;
};

export const processImages = (placeholder, images, widths = ['1x', '2x', '3x']) => {
  const imgs = images.map((image) => {
    return convertFileExtensionToWebp(image);
  });
  const srcSet = imgs.reduce((acc, image, index) => {
    if (image && image !== '') {
      const width = widths[index] || '';
      acc += `${image} ${width}`;
      if (index < imgs.length - 1) {
        acc += ', ';
      }
    }
    return acc;
  }, '');
  const placeholder_lazy = convertFileExtensionToWebp(placeholder);
  const src = DEVICE === 'desktop' ? imgs[2] : imgs[0];
  return { placeholder_lazy, srcSet, src };
};
