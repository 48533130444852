import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { isEmptyObj } from 'utils/general';

const ProjectBody = ({ data, client }) => {
  const { details, content, id, related_blog = false } = data;

  const { header_image } = content;
  const { name, description, external_url = '', external_url_cta } = details;
  const { content: clientContent = {}, details: clientDetails = {} } = client;
  const { name: clientName = '' } = clientDetails;
  const { logo } = clientContent;

  const descriptionRef = useRef();

  useEffect(() => {
    const videos = descriptionRef.current.querySelectorAll('video');

    if (videos.length > 0) {
      Array.from(videos).map((vid) => {
        vid.autoplay = false;
      });
    }
  }, []);

  return (
    <div className="project__body">
      <div className="left">
        {!isEmptyObj(client) && (
          <div className="project__body__logo">
            <img src={logo} alt={clientName} title={clientName} />
          </div>
        )}
      </div>
      <div className="middle">
        <div className="project__body__description wysiwyg" dangerouslySetInnerHTML={{ __html: description }} ref={descriptionRef} />
      </div>
      {external_url !== '' && (
        <div className="right">
          <button className="project__body__external-link" aria-label="click to view project on external website">
            <a href={external_url} target="_blank" rel="noreferrer">
              {external_url_cta}
            </a>
          </button>
        </div>
      )}
    </div>
  );
};

export default ProjectBody;
