import React from 'react';

// import scss
import './Contact.header.scss';

// import components
import SvgRedSwirls from 'components/svg/patterns/Svg.red_swirls';
import SvgYellowLines from 'components/svg/patterns/Svg.yellow_lines';

const ContactHeader = ({ data }) => {
  const { background_image = '', description = '' } = data;

  return (
    <section className="contact-header">
      <SvgRedSwirls />
      <SvgYellowLines />
      <div className="container contact-header__container">
        <img className="contact-header__heroimage" src={background_image} />
        <div className="contact-header__description wysiwyg" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </section>
  );
};

export default ContactHeader;
