import React from 'react';
import { connect } from 'react-redux';

// import selectors
import { getSocialData } from 'store/app/App.selectors';

// import components
import SvgInstagram from 'components/svg/social/Svg.instagram';
import SvgLinkedin from 'components/svg/social/Svg.linkedin';
import SvgTwitter from 'components/svg/social/Svg.twitter';
import SvgFacebook from 'components/svg/social/Svg.facebook';

const SocialIconsList = (props) => {
  const { data, viewType = 'default' } = props;

  if (data.length > 0) {
    return (
      <ul className={'social-icons__list' + (viewType !== 'default' ? ` social-icons__list--${viewType}` : '')}>
        {data.map((data, index) => {
          const { url, type, handle, target = 'blank' } = data;

          let icon;

          switch (type) {
            case 'instagram':
              icon = <SvgInstagram />;
              break;

            case 'twitter':
              icon = <SvgTwitter />;
              break;

            case 'linkedin':
              icon = <SvgLinkedin />;
              break;

            case 'facebook':
              icon = <SvgFacebook />;
              break;
          }

          return (
            <li className={`social-icons__link social-icons--${type}`} key={index}>
              <a href={url} aria-label={`Jollywise's ${type}`} target={`_${target}`}>
                {viewType === 'footer' && <div className="social-icons__handle">{handle}</div>}
                <div className="social-icons__icon">{icon}</div>
                {viewType === 'column' && <div className="social-icons__handle">{handle}</div>}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
  return null;
};

const mapStateToProps = (state) => {
  return {
    data: getSocialData(state),
  };
};

export default connect(mapStateToProps, null)(SocialIconsList);
