import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const random = Math.random() * (1 - 0) + 0;
    const delay = 0.5 + random * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.1 },
      },
    };
  },
};

const SvgPurpleLines = () => {
  const controls = useAnimation();

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      className="svg-icon--purple-lines"
      width="334.521"
      height="344.499"
      viewBox="0 0 334.521 344.499"
      ref={ref}
      variants={draw}
      initial="hidden"
      animate={controls}
    >
      <g data-name="Group 842" fill="rgba(195,98,163,0)" stroke="rgba(195,98,163,0.4)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="10">
        <motion.path variants={draw} data-name="Path 1577" d="M96.8172 150.6098a95.059 95.059 0 0 0 43.098 11.794" />
        <motion.path variants={draw} data-name="Path 1578" d="m126.4399 133.9517-6.6428-29.5796" />
        <motion.path variants={draw} data-name="Path 1579" d="m128.4365 59.256 29.9193-4.8862" />
        <motion.path variants={draw} data-name="Path 1580" d="m12.7558 146.2932 20.9115-21.9483" />
        <motion.path variants={draw} data-name="Path 1581" d="M138.1077 140.723a102.707 102.707 0 0 1 19.8327-14.6328" />
        <motion.path variants={draw} data-name="Path 1582" d="M159.2208 146.9739a41.382 41.382 0 0 0 23.8122 16.922" />
        <motion.path variants={draw} data-name="Path 1583" d="M51.4231 104.9149a41.4 41.4 0 0 0 23.8127 16.9228" />
        <motion.path variants={draw} data-name="Path 1584" d="M160.9243 172.3625a169.04 169.04 0 0 1 3.4684 31.6455" />
        <motion.path variants={draw} data-name="Path 1585" d="m118.9029 214.9972 23.0595-19.0338" />
        <motion.path variants={draw} data-name="Path 1586" d="m71.5782 207.4886 23.059-19.0347" />
        <motion.path variants={draw} data-name="Path 1587" d="M141.8111 219.3595a76.143 76.143 0 0 0 23.751 18.2218" />
        <motion.path variants={draw} data-name="Path 1588" d="M205.2748 14.2838a76.108 76.108 0 0 0 23.7509 18.2218" />
        <motion.path variants={draw} data-name="Path 1589" d="M185.0377 211.028a151.69 151.69 0 0 0 25.168-27.7255" />
        <motion.path variants={draw} data-name="Path 1590" d="M201.2505 170.1315a161.781 161.781 0 0 1 26.5069-28.1047" />
        <motion.path variants={draw} data-name="Path 1591" d="M245.5929 66.7268a161.737 161.737 0 0 1 17.4384 34.4722" />
        <motion.path variants={draw} data-name="Path 1592" d="M189.4324 136.128a146.578 146.578 0 0 0-11.2733-31.3379" />
        <motion.path variants={draw} data-name="Path 1593" d="M148.8233 102.535a139.839 139.839 0 0 1 16.5062-26.5964" />
        <motion.path variants={draw} data-name="Path 1594" d="M186.3687 90.1615a53.054 53.054 0 0 0 19.0485 8.593" />
        <motion.path variants={draw} data-name="Path 1595" d="m217.8858 120.2408 27.743-12.0257" />
        <motion.path variants={draw} data-name="Path 1596" d="M248.817 133.943a76.177 76.177 0 0 1 8.2524 28.7437" />
        <motion.path variants={draw} data-name="Path 1597" d="m238.4816 170.5097 16.758 29.3236" />
        <motion.path variants={draw} data-name="Path 1598" d="M214.6462 215.9295a67.637 67.637 0 0 0 42.7531 5.5026" />
        <motion.path variants={draw} data-name="Path 1599" d="M207.0622 258.2157a72.8 72.8 0 0 0 9.0984-21.6312c.3765-1.5638-.6796-4.097-1.9162-3.0668" stroke-linejoin="round" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1600" d="M183.0883 238.4957a141.853 141.853 0 0 0-15.9642 38.8431" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1601" d="M92.1329 208.8842q10.386 14.077 21.563 27.5483" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1602" d="m117.6916 271.1834 32.331-21.5531" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1603" d="M67.309 177.6322a230.49 230.49 0 0 1 14.096-37.3453" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1604" d="m77.9978 240.2096 26.03 16.4094" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1605" d="m162.4934 27.6202 26.0328 16.4102" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1606" d="M60.5985 217.353a244.892 244.892 0 0 0-24.2492-38.3227" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1607" d="M38.0614 160.7237a212.673 212.673 0 0 0 22.6813-22.5948" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1608" d="M194.6785 70.9485a212.839 212.839 0 0 0 22.6796-22.5938" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1609" d="M107.9175 99.498a212.853 212.853 0 0 0 22.6823-22.593" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1610" d="M93.4178 287.8918a55.089 55.089 0 0 0 36.1028 7.87" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1611" d="M149.2907 308.5685a71.359 71.359 0 0 0-8.6518-32.3653" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1612" d="M79.6788 92.8311a71.348 71.348 0 0 0 28.3871-17.79" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1613" d="m176.8122 292.0392 34.425-15.88" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1614" d="m237.815 273.739 9.5011-27.9674" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1615" d="M280.5638 243.8261a108.7 108.7 0 0 1 10.2038-42.8023" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1616" d="m278.1675 175.2937 31.0618 7.8705" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1617" d="m218.821 82.4584 14.8248 10.2774" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1618" d="M272.3997 129.3976a81.615 81.615 0 0 0 12.6263 16.4694" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1619" d="M306.354 219.7401a109.36 109.36 0 0 0 17.9388-15.3291" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1620" d="m119.4091 318.3381 16.1602 13.9603" />
        <motion.path variants={draw} initial="hidden" animate="visible" data-name="Path 1621" d="m106.1544 177.3242 19.4787 7.1761" />
      </g>
    </motion.svg>
  );
};

export default SvgPurpleLines;
