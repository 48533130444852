export const SvgHeart = `
<span>
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 110.9 119.76">
    <path class="svg-icon--heart" d="M66.29,119.45c-27.53-4-71.87-84.32-41.22-100C52,6.74,65.3,49.8,65.3,49.8S65,9.36,91.52,10.07,122.43,61,105.8,90.62C92.37,115.73,81.5,121.5,66.29,119.45Z" transform="translate(-9.7 -5.06)"/>
  </svg>
</span>`;

export const SvgHand = `
<span>
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 146.66 161.73">
    <path class="svg-icon--hand-cls-1" d="M104.86,41.25c-8.23-15.1-33.31.39-15.78,13.91,7.3,5.64,16.1,19.66,16.1,19.66l10.64,6.54c-4.21-20.89-1.15-25.61-14.17-46.33-11.58-18.42,6.84-27.42,21-6.89C137.5,49.52,138.86,69.4,146.76,89s-3.53,26.73-9.48,55.64c-5.63,27.38-43.16,23.22-43.16,23.22s-28.26,4.16-50.52-38c0,0-6.32-5.79-5.79-22.11,0,0-10.53-21.79,14.21-16.68,0,0,7.25,4.58,12.83,23,0,0,9.27,22.63,37.69-8.42,0,0-7.36-13.87-24.21-15.36s-34.36,1.2-30-15.54c2.79-10.68,42.64-10.89,56.85,0l25.94,16" transform="translate(-7.79 -11.39)"/>
    <path class="svg-icon--hand-cls-2" d="M34.17,67.51l-9.71-9" transform="translate(-7.79 -11.39)"/>
    <path class="svg-icon--hand-cls-1" d="M19.72,80.82,12.79,79" transform="translate(-7.79 -11.39)"/>
  </svg>
</span>`;
