//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import scss
import './Pages.blogs.scss';

// import consts
import { PAGES } from 'store/pages/Pages.reducer';

// import selectors
import { getPageDataByName } from 'store/pages/Pages.selectors';
import { getSiteMetaData } from 'store/app/App.selectors';

// import components
import BlogsHeader from 'components/pages/blogs/Blogs.header';
import BlogsGrid from 'components/blogs/Blogs.grid';
import SiteMetaData from 'components/generic/SiteMetaData';

// import SVG components
import SvgRedLines from 'components/svg/patterns/Svg.red_lines';

import { trackPageview } from 'utils/tracking';

class PageBlogs extends Component {
  componentDidMount() {
    trackPageview({ title: 'How We Play', path: this.props.location.pathname });
  }
  render() {
    const { data } = this.props;
    return (
      <div className="blogs">
        <SiteMetaData {...this.props.metadata} />
        <BlogsHeader data={data} />
        <div className="container">
          <BlogsGrid />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const data = getPageDataByName(state, PAGES.HOW_WE_PLAY);

  return {
    metadata: getSiteMetaData(state, 'blog'),
    data,
  };
};

export default withRouter(connect(mapStateToProps, null)(PageBlogs));
