//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import scss
import './Pages.categories.scss';

// import selectors
import { getSiteMetaData } from 'store/app/App.selectors';

// import components
import CategoriesGrid from 'components/categories/Categories.grid';
import SiteMetaData from 'components/generic/SiteMetaData';
import CategoriesHeader from 'components/pages/categories/Categories.header';
import CategoriesProcess from 'components/pages/categories/Categories.process';
import { getPageDataByName } from 'store/pages/Pages.selectors';
import { PAGES } from 'store/pages/Pages.reducer';

import { trackPageview } from 'utils/tracking';

class PageCategories extends Component {
  componentDidMount() {
    trackPageview({ title: 'What We Do', path: this.props.location.pathname });
  }
  render() {
    const { header, process } = this.props;
    return (
      <div className="categories">
        <SiteMetaData {...this.props.metadata} />
        <CategoriesHeader data={header} />
        <CategoriesGrid />
        <CategoriesProcess data={process} />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const data = getPageDataByName(state, PAGES.WHAT_WE_DO);
  return {
    metadata: getSiteMetaData(state, 'categories'),
    ...data,
  };
};

export default withRouter(connect(mapStateToProps, null)(PageCategories));
