export const IMAGE_COLLAGE_DISPLAY_MORE = 'IMAGE_COLLAGE_DISPLAY_MORE';
export const IMAGE_COLLAGE_DISPLAY_RESET = 'IMAGE_COLLAGE_DISPLAY_RESET';

export const displayMoreCollageImages = () => ({
  type: IMAGE_COLLAGE_DISPLAY_MORE,
});

export const displayResetCollageImages = () => ({
  type: IMAGE_COLLAGE_DISPLAY_RESET,
});
