// import consts
import { SOCIAL_DISPATCH_DATA } from 'store/social/Social.actions';

// import parsers
import { parseSocialContentData, parseTags, parseBlocks } from 'store/social/Social.parsers';

const initState = {
  tags: {},
  content: {},
  blocks: {},
  pages: {},
};

const Social = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case SOCIAL_DISPATCH_DATA: {
      const { tags = [], content = [], blocks = [] } = action.payload;
      const { allIDs, byID, pages } = parseBlocks(blocks);
      nextState.tags = parseTags(tags);
      nextState.content = parseSocialContentData(content);
      nextState.blocks = { allIDs, byID };
      nextState.pages = pages;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default Social;
