//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import consts
import { PAGES } from 'store/pages/Pages.reducer';

// import selectors
import { getPageDataByName } from 'store/pages/Pages.selectors';
import { getSiteMetaData } from 'store/app/App.selectors';

// import components
import SiteMetaData from 'components/generic/SiteMetaData';
import ContactHeader from 'components/pages/contact/Contact.header';
import ContactAddress from 'components/pages/contact/Contact.address';
import ContactJoinUs from 'components/pages/contact/Contact.join_us';

import { trackPageview } from 'utils/tracking';

class PageContact extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    trackPageview({ title: 'Contact', path: this.props.location.pathname });
  }

  render() {
    const { header = {}, address = {}, join_us = {}, become_jollywise = {} } = this.props;

    return (
      <div className="contact">
        <SiteMetaData {...this.props.metadata} />
        <div className="contact__header">
          <ContactHeader data={header} />
        </div>
        <div className="contact__address">
          <ContactAddress data={address} />
        </div>
        <div className="contact__join-us">
          <ContactJoinUs data={join_us} jollywise={become_jollywise} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const data = getPageDataByName(state, PAGES.CONTACT);

  return {
    ...data,
    metadata: getSiteMetaData(state, 'contact'),
  };
};

export default withRouter(connect(mapStateToProps, null)(PageContact));
