import React from 'react';

// import scss
import './Home.showreel.scss';

// import svgs
import SvgVimeo from 'components/svg/social/Svg.vimeo';

const HomeShowreel = ({ link }) => {
  return (
    <div className="home-header__showreel">
      <a href={link} target="_blank" rel="noreferrer">
        <div className="home-header__showreel__link-content">
          <SvgVimeo /> Watch Our Showreel
        </div>
      </a>
    </div>
  );
};

export default HomeShowreel;
