import React from 'react';

const SvgClose = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 252 252" xmlSpace="preserve" className="svg-icon svg-icon--close">
      <path d="M126 0C56.523 0 0 56.523 0 126s56.523 126 126 126 126-56.523 126-126S195.477 0 126 0zm0 234c-59.551 0-108-48.449-108-108S66.449 18 126 18s108 48.449 108 108-48.449 108-108 108z" />
      <path d="M164.612 87.388c-3.515-3.515-9.213-3.515-12.728 0L126 113.272l-25.885-25.885c-3.515-3.515-9.213-3.515-12.728 0-3.515 3.515-3.515 9.213 0 12.728L113.272 126l-25.885 25.885c-3.515 3.515-3.515 9.213 0 12.728 1.757 1.757 4.061 2.636 6.364 2.636s4.606-.879 6.364-2.636L126 138.728l25.885 25.885c1.757 1.757 4.061 2.636 6.364 2.636s4.606-.879 6.364-2.636c3.515-3.515 3.515-9.213 0-12.728L138.728 126l25.885-25.885c3.514-3.514 3.514-9.213-.001-12.727z" />
    </svg>
  );
};

export default SvgClose;
