import React from 'react';

// import components
import CategoryHeader from 'components/category/Category.header';

const CategoryBase = ({ data = {}, back_link }) => {
  return (
    <article className="category__base">
      <CategoryHeader data={data} back_link={back_link} />
    </article>
  );
};

export default CategoryBase;
