import React from 'react';

const SvgPostItBlue = () => {
  return (
    <svg className="svg-postit svg-postit--blue" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 361.58 321.46">
      <path
        className="clr"
        d="M10.5,18.46c0,0,1.28,165.88,5.29,296.08c108.52-1.08,153.39-10.52,335.28-11.71
	C349.9,164.95,340.74,6.92,340.74,6.92L10.5,18.46z"
      />
    </svg>
  );
};

const SvgPostItPink = () => {
  return (
    <svg className="svg-postit svg-postit--pink" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 361.58 321.46">
      <path
        className="clr"
        d="M12.89,16.99c0,0,1.28,165.9,5.29,296.12c108.52-1.08,153.39-10.52,335.28-11.71
	C352.29,163.51,343.13,5.46,343.13,5.46L12.89,16.99z"
      />
    </svg>
  );
};

const SvgPostItYellow = () => {
  return (
    <svg className="svg-postit svg-postit--yellow" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 361.58 321.46">
      <path
        className="clr"
        d="M17.29,24.43c0,0-4.51,147.32-5.04,263.04c108.49,2.41,153.66-4.58,335.49,0
	c3.63-122.45,0-263.04,0-263.04H17.29z"
      />
    </svg>
  );
};

const getPostit = (color) => {
  switch (color) {
    case 'blue':
      return <SvgPostItBlue />;
    case 'pink':
      return <SvgPostItPink />;
    case 'yellow':
      return <SvgPostItYellow />;
    default:
      console.warn(`getPostit : color not recognised : ${color}`);
      return <SvgPostItYellow />;
  }
};

export default getPostit;
