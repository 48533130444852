import * as Constants from 'constants/app/AppConstants';
import { convertFileExtensionToWebp } from 'utils/general';

// ****
// Base site URLS
// ****
export const URL_BASE = Constants.APP_CONFIG.base_url;
export const URL_DYNAMIC = Constants.APP_CONFIG.dynamic_url;
export const URL_STATIC = Constants.APP_CONFIG.static_url;
export const URL_FRONTEND = Constants.APP_CONFIG.frontend_url;

// ******
// Page URLS
// ******
export const URL_PAGE_WHAT_WE_DO = '/what-we-do';
export const URL_PAGE_WHO_WE_ARE = '/about-us';
export const URL_PAGE_WHO_WE_WORK_WITH = '/who-we-work-with';
export const URL_PAGE_ILLUSTRATIONS = '/illustration-and-animation';
export const URL_PAGE_HOW_WE_PLAY = '/blog';
export const URL_PAGE_CONTACT = '/contact';
export const URL_PAGE_BLOG = '/blog';
export const URL_PAGE_PRIVACY_POLICY = '/privacy-policy';
export const URL_PAGE_PAGE_NOT_FOUND = '/page_not_found';
export const URL_PAGE_CASE_STUDIES = '/case-studies';

// Pages to redirect
// NOTE: should really be 301's in place
export const URL_PAGE_OUR_SERVICES = '/our-services';
export const URL_PAGE_OUR_PRODUCTS = '/our-products';

// ******
// friendy_url by content type
// ******
export const getUrlByContentType = (type, url) => {
  switch (type) {
    case Constants.CONTENT_TYPE_PROJECT:
      return `${URL_PAGE_CASE_STUDIES}/${url}`;
    case Constants.CONTENT_TYPE_BLOG:
      return `${URL_PAGE_HOW_WE_PLAY}/${url}`;
    case Constants.CONTENT_TYPE_CLIENT:
      return `${URL_PAGE_WHO_WE_WORK_WITH}/${url}`;
    case Constants.CONTENT_TYPE_CATEGORY:
      return `${URL_PAGE_WHAT_WE_DO}/${url}`;
    case Constants.CONTENT_TYPE_SOCIAL:
      return `${URL_PAGE_WHAT_WE_DO}/social/${url}`;

    default:
      break;
  }
};

// ******
// url image "stitching" methods
// ******
export const getUrlDynamic = (suffix = '') => {
  return URL_DYNAMIC + suffix;
};

export const getUrlStatic = (suffix = '') => {
  return URL_STATIC + suffix + Constants.CACHEBUST;
};

export const getUrlFrontend = (suffix = '') => {
  return URL_FRONTEND + suffix + Constants.CACHEBUST;
};

export const getUrlStaticImages = (suffix = '') => {
  return URL_STATIC + 'frontend/images/' + suffix + Constants.CACHEBUST;
};

export const getUrlFrontendImage = (suffix = '') => {
  return getUrlFrontend('images/' + suffix);
};

// *************
// API URLS
// *************

// ------
// Base api url
const URL_API = URL_BASE + 'api/';

// ------
// site data
export const getUrlSiteData = () => {
  return URL_API + 'get_site_data/' + Constants.LANGUAGE_CODE;
};

export const getUrlSendEmail = () => {
  return URL_API + 'send_email/';
};
