import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'utils/general';

// import selectors
import { getIllustrations } from 'store/illustrations/Illustrations.selectors';

// import components
import IllustrationsBlock from 'components/illustrations/Illustrations.block';

const IllustrationsGrid = (props) => {
  const [activeBlock, updateActiveBlock] = useState(false);
  const [blockSpan, updateBlockSpan] = useState(5);

  const checkWidth = () => {
    const currentBlockSpan = window.innerWidth < 576 ? 1 : window.innerWidth < 768 ? 2 : 5;
    blockSpan !== currentBlockSpan && updateBlockSpan(currentBlockSpan);
  };

  useEffect(() => {
    checkWidth();
    window.addEventListener('resize', checkWidth);
    return () => {
      updateActiveBlock(false);
      window.removeEventListener('resize', checkWidth);
    };
  }, []);

  const toggleOverlay = (id) => {
    updateActiveBlock(activeBlock == id ? false : id);
  };

  const renderBlocks = () => {
    const numberOfRows = props.illustrations.length / blockSpan;
    const lastRowThreshold = (numberOfRows % 1 === 0 ? numberOfRows - 1 : Math.floor(numberOfRows)) * blockSpan;

    return props.illustrations.map((block, index) => {
      const { id } = block;
      return <IllustrationsBlock block={block} key={index} isActive={activeBlock === id} updateActiveBlock={toggleOverlay} isOnLastRow={index + 1 > lastRowThreshold} />;
    });
  };

  return <ul className={'illustrations__grid' + (activeBlock ? ' active' : '')}>{renderBlocks()}</ul>;
};

const mapStateToProps = (state) => {
  return {
    illustrations: getIllustrations(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(IllustrationsGrid);
