import React from 'react';

function SvgSlideshowArrowLeft() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70" xmlSpace="preserve">
      <g data-name="Group 1290">
        <g fill="#fff" stroke="#707070" data-name="Ellipse 87" opacity="0.003" transform="rotate(180 35 35)">
          <circle cx="35" cy="35" r="35" stroke="none"></circle>
          <circle cx="35" cy="35" r="34.5" fill="none"></circle>
        </g>
        <g data-name="Group 1192">
          <g data-name="Group 837">
            <g fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="6" data-name="Group 836">
              <g data-name="Group 831">
                <path d="M28.758 35.5l10.484-10.484" data-name="Line 54"></path>
                <path d="M28.758 35.5l10.484-10.484" data-name="Line 55"></path>
              </g>
              <g data-name="Group 835">
                <path d="M28.757 35.5l10.484 10.484" data-name="Line 54"></path>
                <path d="M28.757 35.5l10.484 10.484" data-name="Line 55"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgSlideshowArrowLeft;
