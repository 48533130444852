import { URL_DYNAMIC } from 'constants/app/AppUrls';

export const parseMetaData = (metadata) => {
  const allIDs = [];
  const byID = {};
  for (const page of [
    'home',
    'what-we-do',
    'about-us',
    'who-we-work-with',
    'blog',
    'contact',
    'project',
    'individual_blog',
    'categories',
    'individual_category',
    'individual_who-we-work-with',
    'page-not-found',
    'privacy-policy',
  ]) {
    const o = {};

    const og_image = (metadata[page]?.og_image || '') !== '' ? `${URL_DYNAMIC}${metadata[page].og_image}` : '';

    o.title = metadata[page]?.meta_title || 'Jollywise - Official website of Brighton-based digital agency Jollywise.';
    o.description = metadata[page]?.meta_description || 'Official website of Brighton-based digital agency Jollywise.';
    o.keywords = metadata[page]?.meta_keywords || '';
    o.og_description = metadata[page]?.og_description || 'Official website of Brighton-based digital agency Jollywise.';
    o.og_image = og_image;
    o.og_sitename = metadata[page]?.og_sitename || 'Jollywise';
    o.og_title = metadata[page]?.og_title || 'Jollywise';
    o.og_type = metadata[page]?.og_type || 'website';
    o.og_url = metadata[page]?.og_url || '';
    byID[page] = o;
    allIDs.push(page);
  }
  return { allIDs, byID };
};
