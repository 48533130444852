import React from 'react';

// import scss
import './Clients.header.scss';

// import components
import { SvgHeart } from 'components/svg/generic/Svg.inline_text';
import SvgLinesCircle from 'components/svg/patterns/Svg.lines_circle';
import SvgBlueLinesTriangle from 'components/svg/patterns/Svg.blue_lines_triangle';
import SvgRedLinesRectangle from 'components/svg/patterns/Svg.red_lines_rectangle';

const ClientsHeader = ({ data }) => {
  const { title = '', description = '' } = data;
  const renderTitle = title.replace('{heart}', SvgHeart);
  const opts = { clazz: 'svg-icon--lines-circle', clr: 'rgba(106,176,99,0.4)' };
  return (
    <section className="clients__header">
      <div className="container">
        <SvgLinesCircle {...opts} />
        <SvgBlueLinesTriangle />
        <SvgRedLinesRectangle />
        <h1 className="clients__title wysiwyg" dangerouslySetInnerHTML={{ __html: renderTitle }} />
        <h2 className="clients__description wysiwyg" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </section>
  );
};

export default ClientsHeader;
