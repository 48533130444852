import React from 'react';
import { connect } from 'react-redux';

// import selectors
import { getCategories } from 'store/categories/Categories.selectors';

// import components
import CategoriesBlock from 'components/categories/Categories.block';

const CategoriesGrid = ({ categories }) => {
  const renderBlocks = () => {
    return categories.map((block, index) => {
      return <CategoriesBlock block={block} key={index} />;
    });
  };

  return <ul className="categories__grid">{renderBlocks()}</ul>;
};

const mapStateToProps = (state) => {
  return {
    categories: getCategories(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesGrid);
