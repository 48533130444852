import React from 'react';

const SvgArrowLeft = () => {
  return (
    <svg className="svg-icon svg-icon--arrow-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 482.6 512">
      <g data-name="Layer 2">
        <path
          d="M195.4 44.7v311.2L79.6 235a47.8 47.8 0 0 0-65.8 0c-18.4 17.5-18.4 45.6 0 63.1l195.6 200.7a45 45 0 0 0 32.4 13.2c12.3 0 24.5-4.4 32.4-13.2L468.8 298c18.4-17.5 18.4-45.6 0-63.1a47.8 47.8 0 0 0-65.8 0l-114.8 121V44.7c0-24.5-21-44.7-46.5-44.7-26.2 0-46.3 20.2-46.3 44.7Z"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
};

export default SvgArrowLeft;
