// import consts
import { BLOGS_DISPATCH_DATA, BLOGS_DISPLAY_MORE, BLOGS_DISPLAY_RESET } from 'store/blogs/Blogs.actions';

// import parsers
import { parseBlogs } from 'store/blogs/Blogs.parsers';

const BLOGS_DEFAULT_DISPLAY_LIMIT = 5;
const BLOGS_ADDITIONIAL_DISPLAY_LIMIT = 4;

const initState = {
  allBlogPageIDs: [],
  byBlogPageID: {},
  allContactPageIDs: [],
  byContactPageID: {},
  numberToDisplay: BLOGS_DEFAULT_DISPLAY_LIMIT,
};

const Blogs = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case BLOGS_DISPATCH_DATA: {
      const { allBlogPageIDs, byBlogPageID, allContactPageIDs, byContactPageID } = parseBlogs(action.payload);
      nextState.allBlogPageIDs = allBlogPageIDs;
      nextState.byBlogPageID = byBlogPageID;
      nextState.allContactPageIDs = allContactPageIDs;
      nextState.byContactPageID = byContactPageID;
      return { ...state, ...nextState };
    }
    case BLOGS_DISPLAY_MORE: {
      const nextCount = state.numberToDisplay + BLOGS_ADDITIONIAL_DISPLAY_LIMIT;
      nextState.numberToDisplay = Math.min(state.allBlogPageIDs.length, nextCount);

      return { ...state, ...nextState };
    }
    case BLOGS_DISPLAY_RESET: {
      nextState.numberToDisplay = BLOGS_DEFAULT_DISPLAY_LIMIT;

      return { ...state, ...nextState };
    }
  }
  return state;
};

export default Blogs;
