import { getUrlDynamic } from 'constants/app/AppUrls';

export const parseFooter = (data = []) => {
  const { memberships = [] } = data;

  if (memberships.length > 0) {
    memberships.map((member) => {
      member.image = getUrlDynamic(member.image);
    });
  }

  return data;
};
