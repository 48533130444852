import { getUrlDynamic } from 'constants/app/AppUrls';
import { processImages } from 'utils/imageProcessing';

export const parseAbout = (data = []) => {
  const { header = {}, dna_sequence = null, image_collage = null } = data;

  const { background_image = '' } = header;
  if (background_image !== '') {
    data.header.background_image = getUrlDynamic(background_image);
  }

  const collage = [];

  if (image_collage) {
    const { content } = image_collage;

    let i;
    let j;
    let temporary;
    let chunk = 4;
    for (i = 0, j = content.length; i < j; i += chunk) {
      temporary = content.slice(i, i + chunk);
      const group = temporary
        .filter((data) => {
          const { image } = data;
          return image !== null;
        })
        .map((data, index) => {
          const { alt, image } = data;

          // sort multisized images
          if (Array.isArray(image)) {
            const { 0: img_lg = '', 1: img_md = '', 2: img_sm = '' } = image;

            const img_lg_file = getUrlDynamic(img_lg.file_name);
            const img_md_file = getUrlDynamic(img_md.file_name);
            const img_sm_file = getUrlDynamic(img_sm.file_name);

            const img_srcSet = processImages('', [img_lg_file, img_md_file, img_sm_file], [`${img_lg.width}w`, `${img_md.width}w`, `${img_sm.width}w`]);

            return { image: img_lg_file, img_srcSet, alt: alt !== '' ? alt : `Jollywise company image ${index}` };
          }

          return { image: getUrlDynamic(image.image), srcSet: [], alt: alt !== '' ? alt : `Jollywise company image ${index}` };
        });

      collage.push(group);
    }
  }

  return { header, dna_sequence, image_collage: collage };
};
