import React from 'react';

const SvgVimeo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="svg-icon svg-icon--viemo">
      <path d="M19 0H5C2.239 0 0 2.239 0 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5V5c0-2.761-2.238-5-5-5zm.248 10.732c-1.627 3.478-5.558 8.213-8.042 8.213-2.448 0-2.802-5.221-4.139-8.696-.657-1.709-1.082-1.317-2.315-.454L4 8.825c1.798-1.581 3.599-3.418 4.705-3.52 1.245-.12 2.012.731 2.299 2.554.379 2.396.908 6.114 1.832 6.114.719 0 2.495-2.95 2.585-4.004.161-1.544-1.136-1.591-2.261-1.109 1.781-5.836 9.194-4.761 6.088 1.872z" />
    </svg>
  );
};

export default SvgVimeo;
