import React from 'react';

// import components
import EmployeeTestimonialsBlock from 'components/employee_testimonials/EmployeeTestimonials.block';

const EmployeeTestimonialsGrid = ({ testimonials }) => {
  const renderBlocks = () => {
    return testimonials.map((block, index) => {
      return <EmployeeTestimonialsBlock block={block} key={index} />;
    });
  };

  return <ul className="employee-testimonials__grid">{renderBlocks()}</ul>;
};

export default EmployeeTestimonialsGrid;
