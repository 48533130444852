import React from 'react';
import { windowScrollTo } from 'utils/general';

const CreativeServicesCtaBlock = ({ data }) => {
  const { id, target, class_name } = data;

  const scrollToPos = () => {
    windowScrollTo(window.pageYOffset + document.getElementById(target).getBoundingClientRect().top - 90);
  };

  return (
    <li className={`creative-services__cta-block creative-services__cta-block__${class_name}`}>
      <div className="creative-services__cta-block__clickable-area" onClick={scrollToPos}>
        <h2 className="title">{id}</h2>
      </div>
    </li>
  );
};

export default CreativeServicesCtaBlock;
