import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { LazyImage } from 'react-lazy-images';

import PictureMappings from 'components/picture-mappings/PictureMappings';

const duration = 1.2;

const ImageCollageGridOne = ({ data }) => {
  const { 0: first = '', 1: second = '', 2: third = '', 3: fourth = '' } = data;

  const controls = useAnimation();

  const { ref, inView, entry } = useInView({
    threshold: 0.05,
    triggerOnce: true,
  });

  // rise in
  const motion_styles = {
    hidden: { y: 60, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ease: 'easeInOut',
        duration,
        delay: 0.7,
      },
    },
  };

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <motion.section className="image-collages__grid-one__grid" variants={motion_styles} initial="hidden" animate={controls} ref={ref}>
      <div className="left">
        <div className="left">
          {first !== '' && (
            <LazyImage
              src={first.img_srcSet.src}
              srcSet={first.img_srcSet.srcSet}
              alt={first.alt}
              placeholder={({ imageProps, ref }) => <div className="lazy-load__placeholder" ref={ref} />}
              actual={({ imageProps }) => <PictureMappings {...imageProps} />}
            />
          )}
        </div>
        <div className="right">
          {second !== '' && (
            <LazyImage
              src={second.img_srcSet.src}
              srcSet={second.img_srcSet.srcSet}
              alt={second.alt}
              placeholder={({ imageProps, ref }) => <div className="lazy-load__placeholder" ref={ref} />}
              actual={({ imageProps }) => <PictureMappings {...imageProps} class_name="right--first" />}
            />
          )}
          {third !== '' && (
            <LazyImage
              src={third.img_srcSet.src}
              srcSet={third.img_srcSet.srcSet}
              alt={third.alt}
              placeholder={({ imageProps, ref }) => <div className="lazy-load__placeholder" ref={ref} />}
              actual={({ imageProps }) => <PictureMappings {...imageProps} class_name="right--second" />}
            />
          )}
        </div>
      </div>
      <div className="right">
        {fourth !== '' && (
          <LazyImage
            src={fourth.img_srcSet.src}
            srcSet={fourth.img_srcSet.srcSet}
            alt={fourth.alt}
            placeholder={({ imageProps, ref }) => <div className="lazy-load__placeholder" ref={ref} />}
            actual={({ imageProps }) => <PictureMappings {...imageProps} />}
          />
        )}
      </div>
    </motion.section>
  );
};

export default ImageCollageGridOne;
