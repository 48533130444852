import { getUrlDynamic, getUrlByContentType } from 'constants/app/AppUrls';
import { CONTENT_TYPE_CATEGORY } from 'constants/app/AppConstants';

const parseData = (category) => {
  const { data, id, name } = category;
  const trimmedName = name.trim();

  const { background_image = '', description = '', placeholder_image = '', friendly_url } = data;

  const parsedCategory = {
    id,
    type: trimmedName,
    friendly_url: getUrlByContentType(CONTENT_TYPE_CATEGORY, friendly_url.trim()),
    details: {
      name: trimmedName,
    },
    content: {
      background: getUrlDynamic(background_image),
      description,
      poster: getUrlDynamic(placeholder_image),
    },
  };

  return parsedCategory;
};

export const parseCategories = (data) => {
  const allIDs = [];
  const byID = {};
  const categoryIdByType = {};

  for (const category of data) {
    const { id, name } = category;
    const trimmedName = name.trim();
    const parsed = parseData(category);
    allIDs.push(id);
    byID[id] = { ...parsed };

    categoryIdByType[trimmedName] = id;
  }
  return { allIDs, byID, categoryIdByType };
};
