import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './rootReducer';
import reduxfreezeMiddleware from 'redux-freeze';
import { composeWithDevTools } from 'redux-devtools-extension';
// middleware
import AppMiddleware from './middleware/AppMiddleware';
import { LOCAL_STORAGE_OPTIONS } from 'constants/app/AppConstants';
import throttle from 'lodash.throttle';

const configureStore = () => {
  const middlewares = [...AppMiddleware, thunkMiddleware];
  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(
      createLogger({
        collapsed: true,
        duration: true,
        timestamp: true,
      })
    );
  }
  if (__WATCH__) {
    middlewares.unshift(reduxfreezeMiddleware);
  }

  const persistedState = retrieveSerialisedLocalStorage();
  const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(...middlewares)));

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer', () => {
        const nextRootReducer = require('./rootReducer').default;
        store.replaceReducer(nextRootReducer);
      });
    }
  }

  return store;
};

const retrieveSerialisedLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_OPTIONS.namespace);
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

const saveState = ({ userAuth, likes, onboarding }) => {
  try {
    const serializedState = JSON.stringify({ userAuth, likes, onboarding });
    // console.log('::: localstorage save', serializedState);
    localStorage.setItem(LOCAL_STORAGE_OPTIONS.namespace, serializedState);
  } catch {
    // ignore write errors
  }
};

export const addSaveStorage = ({ store }) => {
  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }, 1000)
  );
};

export default configureStore;
