//magic things
import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { HelmetProvider } from 'react-helmet-async';

// import consts
import {
  URL_PAGE_WHAT_WE_DO,
  URL_PAGE_WHO_WE_ARE,
  URL_PAGE_WHO_WE_WORK_WITH,
  URL_PAGE_HOW_WE_PLAY,
  URL_PAGE_CONTACT,
  URL_PAGE_BLOG,
  URL_PAGE_OUR_SERVICES,
  URL_PAGE_OUR_PRODUCTS,
  URL_PAGE_PRIVACY_POLICY,
  URL_PAGE_CASE_STUDIES,
} from 'constants/app/AppUrls';

// import pages
import PageHome from 'containers/pages/Pages.home';
import PageNotFound from 'containers/pages/Pages.not_found';
import PageAbout from 'containers/pages/Pages.about';
import PageContact from 'containers/pages/Pages.contact';
import PagePrivacyPolicy from 'containers/pages/Pages.privacy_policy';

// import containers
import Main from 'containers/sections/Sections.main';

// import routers
import RouterProjects from 'containers/router/Router.projects';
import RouterBlogs from 'containers/router/Router.blogs';
import RouterCategories from 'containers/router/Router.categories';
import RouterClients from 'containers/router/Router.clients';

class Router extends Component {
  render() {
    return (
      <HelmetProvider>
        <React.Fragment>
          <AnimatePresence exitBeforeEnter initial={false}>
            <Switch location={this.props.location} key={this.props.location.pathname}>
              <Route
                exact
                path={'/'}
                render={(props) => (
                  <Main isHome={true}>
                    <PageHome {...props} />
                  </Main>
                )}
              />
              <Route
                exact
                path={URL_PAGE_WHO_WE_ARE}
                render={(props) => (
                  <Main>
                    <PageAbout {...props} />
                  </Main>
                )}
              />
              <Route
                exact
                path={URL_PAGE_CONTACT}
                render={(props) => (
                  <Main>
                    <PageContact {...props} />
                  </Main>
                )}
              />
              <Route
                exact
                path={URL_PAGE_PRIVACY_POLICY}
                render={(props) => (
                  <Main>
                    <PagePrivacyPolicy {...props} />
                  </Main>
                )}
              />

              <Route path={URL_PAGE_WHAT_WE_DO} render={(props) => <RouterCategories {...props} />} />
              <Route path={URL_PAGE_CASE_STUDIES} render={(props) => <RouterProjects {...props} />} />
              <Route path={URL_PAGE_WHO_WE_WORK_WITH} render={(props) => <RouterClients {...props} />} />
              <Route path={URL_PAGE_HOW_WE_PLAY} render={(props) => <RouterBlogs {...props} />} />
              <Redirect from={URL_PAGE_OUR_SERVICES} to={URL_PAGE_WHAT_WE_DO} />
              <Redirect from={URL_PAGE_OUR_PRODUCTS} to={URL_PAGE_WHAT_WE_DO} />
              <Route
                render={(props) => (
                  <Main>
                    <PageNotFound {...props} />
                  </Main>
                )}
              />
            </Switch>
          </AnimatePresence>
        </React.Fragment>
      </HelmetProvider>
    );
  }
}
export default withRouter(Router);
