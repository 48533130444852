import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';

// import const
import { MODAL_OVERLAY_TYPE_EMPLOYEE } from 'constants/app/AppConstants';

// import actions
import { activeOverlayModal } from 'store/app/App.actions';

// import selectors
import { getAllEmployeesCtas } from 'store/employees/Employees.selectors';

// import components
import EmployeesItems from 'components/employees/Employees.items';

// Framer motion variables
const project_container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.7,
      staggerChildren: 0.2,
    },
  },
};

const EmployeesList = (props) => {
  const { data } = props;

  const controls = useAnimation();

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  const modalCallback = (id) => {
    props.callback(id);
    // props.triggerModalOverlay({ active: true, type: MODAL_OVERLAY_TYPE_EMPLOYEE, id });
  };

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <AnimatePresence>
      <motion.ul className="employees__list" variants={project_container} initial="hidden" animate={controls} ref={ref}>
        <EmployeesItems data={data} modalCallback={modalCallback} />
      </motion.ul>
    </AnimatePresence>
  );
};

const mapStateToProps = (state) => {
  return {
    data: getAllEmployeesCtas(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    triggerModalOverlay: (opts) => {
      dispatch(activeOverlayModal(opts));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesList);
