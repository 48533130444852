import React from 'react';

// import components
import BlogHeader from 'components/blog/Blog.header';
import BlogBody from 'components/blog/Blog.body';
import BlogRelatedGrid from 'components/blog/Blog.related_grid';

const BlogBase = ({ data }) => {
  const { related_blog_ids } = data.details;

  return (
    <>
      <div className="blog_container container">
        <article className="blog__base">
          <BlogHeader data={data} />
          <BlogBody data={data} />
        </article>
      </div>
      {related_blog_ids.length > 0 && (
        <div className="related_blog_container container">
          <BlogRelatedGrid ids={related_blog_ids} />
        </div>
      )}
    </>
  );
};

export default BlogBase;
