import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const random = Math.random() * (1 - 0) + 0;
    const delay = 0.5 + random * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.1 },
      },
    };
  },
};

// blue : 47cdc7 | opacity .294

const SvgBlueDots = ({ clazz = 'svg-icon--blue-dots', clr = '#47cdc7', opacity = '.294' }) => {
  const controls = useAnimation();

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <motion.svg className={clazz} ref={ref} variants={draw} initial="hidden" animate={controls} xmlns="http://www.w3.org/2000/svg" width="687.45" height="229.832" viewBox="0 0 687.45 229.832">
      <g data-name="Group 830" opacity={opacity} fill={clr}>
        <motion.path
          variants={draw}
          data-name="Path 1404"
          d="m129.859 8.2708-1.4154-.25a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.861 7.861 0 0 0 1.387 3.7686l1.3532 1.4884a7.835 7.835 0 0 0 3.721 1.7461l1.4193.2508a8.076 8.076 0 0 0 9.1098-6.3745l.0867-2.1065a7.825 7.825 0 0 0-1.3871-3.7687l-1.3531-1.4883a7.83 7.83 0 0 0-3.725-1.747Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1405"
          d="m23.2911 37.3477 1.4194.2508a8.076 8.076 0 0 0 9.1098-6.3745l.0867-2.1065a7.827 7.827 0 0 0-1.3871-3.7687l-1.3531-1.4883a7.831 7.831 0 0 0-3.7208-1.7472l-1.4194-.2507a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1066a7.859 7.859 0 0 0 1.3871 3.7686l1.3531 1.4884a7.833 7.833 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1406"
          d="m60.5676 17.1426 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3745l.0867-2.1065a7.845 7.845 0 0 0-1.387-3.7686l-1.3535-1.4865a7.815 7.815 0 0 0-3.7206-1.7481l-1.4194-.2507a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1066a7.861 7.861 0 0 0 1.3871 3.7686l1.3531 1.4884a7.816 7.816 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1407"
          d="m70.6535 40.3918 1.4194.2508a8.076 8.076 0 0 0 9.1097-6.3745l.0867-2.1065a7.827 7.827 0 0 0-1.387-3.7687l-1.3531-1.4884a7.831 7.831 0 0 0-3.7208-1.7471l-1.4194-.2507a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1066a7.859 7.859 0 0 0 1.3871 3.7686l1.3531 1.4884a7.833 7.833 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1408"
          d="m61.2373 63.5663 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3744l.0867-2.1066a7.825 7.825 0 0 0-1.387-3.7686l-1.3532-1.4884a7.83 7.83 0 0 0-3.7208-1.7472l-1.4193-.2507a8.082 8.082 0 0 0-9.1098 6.3745l-.0867 2.1065a7.861 7.861 0 0 0 1.387 3.7686l1.3532 1.4884a7.835 7.835 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1409"
          d="m36.056 73.2621 1.4037.3222a8.08 8.08 0 0 0 9.4251-5.9003l.193-2.099a7.848 7.848 0 0 0-1.1927-3.8338l-1.2745-1.5537a7.816 7.816 0 0 0-3.6266-1.9357l-1.4064-.3185a8.075 8.075 0 0 0-9.4232 5.9006l-.195 2.0986a7.865 7.865 0 0 0 1.1928 3.8338l1.2765 1.554a7.816 7.816 0 0 0 3.6265 1.9358Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1410"
          d="m7.6992 75.3976 1.4194.2508a8.076 8.076 0 0 0 9.1097-6.3745l.0867-2.1066a7.826 7.826 0 0 0-1.387-3.7686l-1.3532-1.4884a7.83 7.83 0 0 0-3.7208-1.7471l-1.4193-.2508a8.082 8.082 0 0 0-9.1098 6.3745l-.0867 2.1066a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.833 7.833 0 0 0 3.721 1.7461Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1411"
          d="m78.3336 107.3905 1.4194.2508a8.076 8.076 0 0 0 9.1097-6.3745l.0867-2.1066a7.827 7.827 0 0 0-1.387-3.7686l-1.3532-1.4884a7.83 7.83 0 0 0-3.7208-1.7471l-1.4194-.2508a8.082 8.082 0 0 0-9.1097 6.3745l-.0867 2.1066a7.859 7.859 0 0 0 1.387 3.7686l1.3532 1.4884a7.834 7.834 0 0 0 3.72 1.746Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1412"
          d="m111.636 51.1763-1.4155-.25a8.085 8.085 0 0 0-9.1098 6.3744l-.087 2.1085a7.865 7.865 0 0 0 1.3874 3.7667l1.3531 1.4884a7.816 7.816 0 0 0 3.721 1.7462l1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3745l.0867-2.1065a7.844 7.844 0 0 0-1.387-3.7687l-1.3535-1.4864a7.832 7.832 0 0 0-3.7246-1.7488Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1413"
          d="m137.6478 34.4845-1.4154-.25a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.835 7.835 0 0 0 3.721 1.7461l1.4193.2508a8.076 8.076 0 0 0 9.1097-6.3745l.0867-2.1065a7.827 7.827 0 0 0-1.387-3.7687l-1.3532-1.4883a7.829 7.829 0 0 0-3.7249-1.747Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1414"
          d="m153.7338 63.9374-1.4154-.25a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1065a7.86 7.86 0 0 0 1.3871 3.7687l1.3531 1.4884a7.834 7.834 0 0 0 3.72 1.746l1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3745l.0867-2.1065a7.827 7.827 0 0 0-1.387-3.7686l-1.3531-1.4884a7.831 7.831 0 0 0-3.724-1.7467Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1415"
          d="m173.7757 40.8665-1.4155-.25a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1065a7.86 7.86 0 0 0 1.387 3.7687l1.3532 1.4883a7.835 7.835 0 0 0 3.721 1.7462l1.4193.2508a8.076 8.076 0 0 0 9.1098-6.3745l.0867-2.1066a7.824 7.824 0 0 0-1.3871-3.7686l-1.3531-1.4884a7.829 7.829 0 0 0-3.725-1.7468Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1416"
          d="m154.0328 92.375-1.4154-.25a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1065a7.86 7.86 0 0 0 1.387 3.7687l1.3532 1.4883a7.834 7.834 0 0 0 3.721 1.7462l1.4194.2508a8.076 8.076 0 0 0 9.1097-6.3745l.0867-2.1066a7.826 7.826 0 0 0-1.387-3.7686l-1.3532-1.4884a7.831 7.831 0 0 0-3.7249-1.7468Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1417"
          d="m155.5464 113.9312-1.4155-.25a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1065a7.859 7.859 0 0 0 1.387 3.7687l1.3532 1.4883a7.834 7.834 0 0 0 3.72 1.746l1.4193.2508a8.076 8.076 0 0 0 9.1098-6.3745l.0867-2.1065a7.827 7.827 0 0 0-1.3871-3.7687l-1.3531-1.4883a7.83 7.83 0 0 0-3.724-1.7467Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1418"
          d="m107.2722 116.0485-1.4154-.25a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1065a7.86 7.86 0 0 0 1.387 3.7687l1.3532 1.4883a7.834 7.834 0 0 0 3.721 1.7462l1.4194.2508a8.076 8.076 0 0 0 9.1097-6.3745l.0867-2.1066a7.825 7.825 0 0 0-1.387-3.7686l-1.3532-1.4884a7.829 7.829 0 0 0-3.7249-1.7468Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1419"
          d="m130.0501 77.4945-1.4154-.25a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1066a7.86 7.86 0 0 0 1.3871 3.7686l1.3531 1.4884a7.835 7.835 0 0 0 3.721 1.7462l1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3745l.0867-2.1065a7.826 7.826 0 0 0-1.387-3.7686l-1.3532-1.4884a7.83 7.83 0 0 0-3.7249-1.7469Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1420"
          d="m474.7418 141.531 1.4174.2503a8.076 8.076 0 0 0 9.1097-6.3744l.0877-2.1064a7.865 7.865 0 0 0-1.388-3.7688l-1.3516-1.486a7.838 7.838 0 0 0-3.7216-1.7484l-1.4184-.2505a8.079 8.079 0 0 0-9.1097 6.3744l-.088 2.1084a7.848 7.848 0 0 0 1.3884 3.7668l1.3511 1.488a7.818 7.818 0 0 0 3.722 1.7464Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1421"
          d="m554.217 180.4528 1.4155.25a8.082 8.082 0 0 0 9.1098-6.3745l.0867-2.1065a7.841 7.841 0 0 0-1.3871-3.7686l-1.3531-1.4884a7.818 7.818 0 0 0-3.721-1.7462l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.861 7.861 0 0 0 1.387 3.7686l1.3535 1.4864a7.819 7.819 0 0 0 3.7206 1.7482Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1422"
          d="m643.2292 115.6786 1.4194.2507a8.082 8.082 0 0 0 9.1098-6.3745l.0867-2.1065a7.879 7.879 0 0 0-1.3871-3.7686l-1.3535-1.4865a7.836 7.836 0 0 0-3.7206-1.7481l-1.4194-.2507a8.076 8.076 0 0 0-9.1097 6.3744l-.089 2.1082a7.849 7.849 0 0 0 1.3894 3.767l1.3511 1.488a7.819 7.819 0 0 0 3.722 1.7464Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1423"
          d="m663.2725 76.7756 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3744l.0887-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.835 7.835 0 0 0-3.722-1.7464l-1.4193-.2507a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1066a7.859 7.859 0 0 0 1.3871 3.7686l1.3531 1.4884a7.834 7.834 0 0 0 3.72 1.746Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1424"
          d="m675.6633 117.1074 1.4193.2507a8.076 8.076 0 0 0 9.1098-6.3744l.0887-2.1062a7.846 7.846 0 0 0-1.3891-3.769l-1.3511-1.488a7.837 7.837 0 0 0-3.7228-1.7476l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3745l-.0867 2.1065a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.833 7.833 0 0 0 3.72 1.746Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1425"
          d="m639.5244 171.042 1.4194.2507a8.076 8.076 0 0 0 9.1098-6.3744l.0886-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7227-1.7475l-1.4194-.2508a8.082 8.082 0 0 0-9.1098 6.3745l-.0867 2.1065a7.86 7.86 0 0 0 1.3871 3.7687l1.3531 1.4883a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1426"
          d="m611.4874 189.1526 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3744l.0887-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7228-1.7476l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3745l-.0867 2.1065a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.835 7.835 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1427"
          d="m582.5446 182.2664 1.4193.2507a8.076 8.076 0 0 0 9.1098-6.3745l.0887-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.837 7.837 0 0 0-3.7228-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.833 7.833 0 0 0 3.72 1.746Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1428"
          d="m562.1064 157.3661 1.4194.2508a8.076 8.076 0 0 0 9.1097-6.3745l.0887-2.1062a7.844 7.844 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7228-1.7475l-1.4194-.2508a8.082 8.082 0 0 0-9.1097 6.3745l-.0867 2.1066a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.834 7.834 0 0 0 3.721 1.7461Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1429"
          d="m570.9137 137.6331 1.4193.2507a8.076 8.076 0 0 0 9.1098-6.3744l.0887-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.835 7.835 0 0 0-3.7228-1.7476l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3745l-.0867 2.1065a7.859 7.859 0 0 0 1.387 3.7686l1.3532 1.4884a7.834 7.834 0 0 0 3.72 1.746Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1430"
          d="m525.0606 186.3047 1.4194.2508a8.076 8.076 0 0 0 9.1098-6.3745l.0886-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7227-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1066a7.86 7.86 0 0 0 1.3871 3.7686l1.3531 1.4884a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1431"
          d="m527.5947 161.9146 1.4193.2507a8.076 8.076 0 0 0 9.1098-6.3745l.0886-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3511-1.488a7.837 7.837 0 0 0-3.7228-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.859 7.859 0 0 0 1.387 3.7686l1.3532 1.4884a7.833 7.833 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1432"
          d="m500.4653 205.0223 1.4194.2507a8.076 8.076 0 0 0 9.1098-6.3744l.0886-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7227-1.7475l-1.4194-.2508a8.082 8.082 0 0 0-9.1097 6.3745l-.0867 2.1065a7.86 7.86 0 0 0 1.387 3.7687l1.3531 1.4883a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1433"
          d="m504.0137 164.845 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3745l.0887-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.837 7.837 0 0 0-3.7228-1.7475l-1.4194-.2508a8.082 8.082 0 0 0-9.1097 6.3745l-.0867 2.1066a7.858 7.858 0 0 0 1.387 3.7686l1.3532 1.4884a7.834 7.834 0 0 0 3.72 1.746Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1434"
          d="m501.7936 127.1976 1.4194.2507a8.076 8.076 0 0 0 9.1098-6.3745l.0886-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.837 7.837 0 0 0-3.7227-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.859 7.859 0 0 0 1.387 3.7686l1.3532 1.4884a7.833 7.833 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1435"
          d="m525.9826 120.8256 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3744l.0887-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7228-1.7476l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3745l-.0867 2.1065a7.86 7.86 0 0 0 1.387 3.7687l1.3532 1.4883a7.833 7.833 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1436"
          d="m521.0274 98.6614 1.4194.2508a8.076 8.076 0 0 0 9.1098-6.3745l.0886-2.1062a7.844 7.844 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7227-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0868 2.1066a7.86 7.86 0 0 0 1.3871 3.7686l1.3531 1.4884a7.835 7.835 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1437"
          d="m505.5492 65.768 1.4194.2507a8.076 8.076 0 0 0 9.1098-6.3745l.0886-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7227-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1066a7.86 7.86 0 0 0 1.3871 3.7686l1.3531 1.4884a7.833 7.833 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1438"
          d="m482.4673 106.0427 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3744l.0887-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.835 7.835 0 0 0-3.7227-1.7475l-1.4194-.2508a8.082 8.082 0 0 0-9.1098 6.3745l-.0867 2.1065a7.86 7.86 0 0 0 1.3871 3.7687l1.3531 1.4883a7.835 7.835 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1439"
          d="m464.249 118.7908 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3744l.0887-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3512-1.488a7.835 7.835 0 0 0-3.7228-1.7475l-1.4193-.2508a8.082 8.082 0 0 0-9.1098 6.3745l-.0867 2.1065a7.86 7.86 0 0 0 1.387 3.7687l1.3532 1.4883a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1440"
          d="m227.6157 115.4393 1.4193.2507a8.082 8.082 0 0 0 9.1098-6.3744l.0867-2.1066a7.843 7.843 0 0 0-1.3871-3.7686l-1.3531-1.4884a7.817 7.817 0 0 0-3.72-1.746l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.852 7.852 0 0 0 1.387 3.7686l1.3532 1.4884a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1441"
          d="m432.7074 94.8992 1.4627.2583a8.319 8.319 0 0 0 9.3786-6.5626l.0908-2.1698a8.089 8.089 0 0 0-1.4292-3.8807l-1.3934-1.531a8.063 8.063 0 0 0-3.8303-1.8l-1.4627-.2584a8.321 8.321 0 0 0-9.379 6.5645l-.0907 2.1699a8.068 8.068 0 0 0 1.4296 3.8787l1.3931 1.532a8.044 8.044 0 0 0 3.8304 1.794Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1442"
          d="m408.841 116.2584 1.4607.258a8.318 8.318 0 0 0 9.381-6.5642l.0884-2.1682a8.086 8.086 0 0 0-1.4273-3.8803l-1.3931-1.532a8.055 8.055 0 0 0-3.8323-1.7943l-1.4608-.258a8.319 8.319 0 0 0-9.3806 6.5622l-.0888 2.1702a8.088 8.088 0 0 0 1.4273 3.8803l1.3933 1.531a8.051 8.051 0 0 0 3.8313 1.8002Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1443"
          d="m397.4737 159.919 1.4627.2583a8.319 8.319 0 0 0 9.3787-6.5626l.0907-2.1698a8.09 8.09 0 0 0-1.4292-3.8807l-1.3933-1.531a8.063 8.063 0 0 0-3.8303-1.8l-1.4627-.2585a8.318 8.318 0 0 0-9.379 6.5646l-.0908 2.1699a8.094 8.094 0 0 0 1.4296 3.8787l1.3932 1.532a8.043 8.043 0 0 0 3.8303 1.794Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1444"
          d="m377.0552 130.7388 1.4607.258a8.321 8.321 0 0 0 9.381-6.5642l.0888-2.1702a8.068 8.068 0 0 0-1.4296-3.8787l-1.3912-1.5317a8.073 8.073 0 0 0-3.8313-1.8002l-1.4607-.258a8.321 8.321 0 0 0-9.381 6.5642l-.0907 2.1699a8.118 8.118 0 0 0 1.4292 3.8806l1.3933 1.531a8.068 8.068 0 0 0 3.8313 1.8003Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1445"
          d="m383.3903 104.5072 1.4194.2507a8.076 8.076 0 0 0 9.1098-6.3745l.0886-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.835 7.835 0 0 0-3.7227-1.7475l-1.4194-.2507a8.081 8.081 0 0 0-9.1097 6.3744l-.0867 2.1066a7.859 7.859 0 0 0 1.387 3.7686l1.3532 1.4884a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1446"
          d="m398.0102 74.1565 1.4608.258a8.318 8.318 0 0 0 9.381-6.5642l.0887-2.1702a8.091 8.091 0 0 0-1.4276-3.8783l-1.3931-1.532a8.048 8.048 0 0 0-3.8324-1.7943l-1.4617-.2583a8.318 8.318 0 0 0-9.3806 6.5623l-.0888 2.1702a8.087 8.087 0 0 0 1.4273 3.8803l1.3933 1.531a8.068 8.068 0 0 0 3.8313 1.8003Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1447"
          d="m360.407 164.3147 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3745l.0887-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.837 7.837 0 0 0-3.7228-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.859 7.859 0 0 0 1.387 3.7686l1.3532 1.4884a7.833 7.833 0 0 0 3.72 1.746Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1448"
          d="m333.2867 147.1055 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3745l.0887-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.835 7.835 0 0 0-3.7228-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.859 7.859 0 0 0 1.387 3.7686l1.3532 1.4884a7.834 7.834 0 0 0 3.72 1.746Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1449"
          d="m349.6497 120.4167 1.4608.258a8.321 8.321 0 0 0 9.381-6.5642l.0887-2.1702a8.069 8.069 0 0 0-1.4296-3.8787l-1.3921-1.5318a8.074 8.074 0 0 0-3.8313-1.8002l-1.4608-.258a8.321 8.321 0 0 0-9.381 6.5642l-.0907 2.1698a8.102 8.102 0 0 0 1.4296 3.8787l1.3932 1.532a8.069 8.069 0 0 0 3.8312 1.8002Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1450"
          d="m322.5633 127.4703 1.4194.2508a8.076 8.076 0 0 0 9.1098-6.3745l.0886-2.1062a7.843 7.843 0 0 0-1.389-3.769l-1.3511-1.488a7.836 7.836 0 0 0-3.7228-1.7475l-1.4194-.2508a8.082 8.082 0 0 0-9.1097 6.3745l-.0867 2.1066a7.861 7.861 0 0 0 1.387 3.7686l1.3532 1.4884a7.835 7.835 0 0 0 3.721 1.7461Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1451"
          d="m331.9786 104.2957 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3744l.0887-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7228-1.7475l-1.4194-.2508a8.081 8.081 0 0 0-9.1097 6.3745l-.0867 2.1065a7.859 7.859 0 0 0 1.387 3.7687l1.3532 1.4883a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1452"
          d="m345.2812 83.11 1.4627.2584a8.321 8.321 0 0 0 9.379-6.5646l.0907-2.1699a8.068 8.068 0 0 0-1.4296-3.8787l-1.3921-1.5318a8.054 8.054 0 0 0-3.8323-1.7943l-1.4628-.2584a8.318 8.318 0 0 0-9.3786 6.5626l-.0908 2.1698a8.102 8.102 0 0 0 1.4293 3.8807l1.3933 1.531a8.063 8.063 0 0 0 3.8293 1.8Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1453"
          d="m298.6886 71.8037 1.4194.2508a8.076 8.076 0 0 0 9.1097-6.3745l.0887-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7228-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1454"
          d="m268.8342 70.078 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3745l.0887-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7228-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.833 7.833 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1455"
          d="m298.3796 103.683 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3745l.0887-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7228-1.7475l-1.4193-.2508a8.082 8.082 0 0 0-9.1098 6.3745l-.0867 2.1066a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.834 7.834 0 0 0 3.72 1.746Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1456"
          d="m264.2756 95.884 1.4193.2507a8.076 8.076 0 0 0 9.1098-6.3745l.0887-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7228-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1457"
          d="m262.5496 125.7393 1.4194.2508a8.076 8.076 0 0 0 9.1097-6.3745l.0887-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7228-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.833 7.833 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1458"
          d="m300.192 153.6777 1.4193.2507a8.076 8.076 0 0 0 9.1098-6.3744l.0886-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.837 7.837 0 0 0-3.7227-1.7476l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3745l-.0867 2.1065a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.833 7.833 0 0 0 3.72 1.746Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1459"
          d="m246.7564 154.8832 1.4194.2508a8.076 8.076 0 0 0 9.1097-6.3745l.0887-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7228-1.7475l-1.4193-.2507a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1066a7.861 7.861 0 0 0 1.387 3.7686l1.3532 1.4884a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1460"
          d="m470.311 174.8587 1.4194.2508a8.076 8.076 0 0 0 9.1097-6.3745l.0887-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.835 7.835 0 0 0-3.7228-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.835 7.835 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1461"
          d="m605.7292 131.3648 1.4194.2508a8.076 8.076 0 0 0 9.1097-6.3745l.0887-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7228-1.7475l-1.4193-.2507a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1066a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1462"
          d="m578.29 206.3516 1.4193.2507a8.076 8.076 0 0 0 9.1097-6.3744l.0887-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.837 7.837 0 0 0-3.7227-1.7476l-1.4194-.2507a8.082 8.082 0 0 0-9.1098 6.3745l-.0867 2.1065a7.86 7.86 0 0 0 1.3871 3.7686l1.3531 1.4884a7.833 7.833 0 0 0 3.72 1.746Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1463"
          d="m552.0774 214.1396 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3745l.0887-2.1062a7.843 7.843 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7228-1.7475l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.86 7.86 0 0 0 1.387 3.7686l1.3532 1.4884a7.835 7.835 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1464"
          d="m574.339 228.717 1.4195.2507a8.076 8.076 0 0 0 9.1097-6.3744l.0887-2.1062a7.846 7.846 0 0 0-1.389-3.769l-1.3512-1.488a7.837 7.837 0 0 0-3.7228-1.7475l-1.4194-.2508a8.082 8.082 0 0 0-9.1097 6.3745l-.0867 2.1065a7.86 7.86 0 0 0 1.387 3.7687l1.3532 1.4883a7.834 7.834 0 0 0 3.72 1.746Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1465"
          d="m604.601 218.0964 1.4194.2507a8.076 8.076 0 0 0 9.1097-6.3744l.0887-2.1062a7.845 7.845 0 0 0-1.389-3.769l-1.3512-1.488a7.836 7.836 0 0 0-3.7228-1.7476l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3745l-.0867 2.1065a7.861 7.861 0 0 0 1.387 3.7686l1.3532 1.4884a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1466"
          d="m430.7476 137.7106 1.4193.2507a8.076 8.076 0 0 0 9.1098-6.3744l.0887-2.1062a7.845 7.845 0 0 0-1.3891-3.769l-1.3511-1.488a7.835 7.835 0 0 0-3.7228-1.7475l-1.4194-.2508a8.082 8.082 0 0 0-9.1097 6.3745l-.0867 2.1065a7.86 7.86 0 0 0 1.387 3.7687l1.3532 1.4883a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1467"
          d="m226.1043 83.8196 1.4194.2507a8.082 8.082 0 0 0 9.1097-6.3745l.0867-2.1065a7.844 7.844 0 0 0-1.387-3.7686l-1.3532-1.4884a7.835 7.835 0 0 0-3.7207-1.7472l-1.4194-.2507a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1066a7.841 7.841 0 0 0 1.3871 3.7686l1.3531 1.4884a7.834 7.834 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1468"
          d="m201.3138 63.473 1.4194.2507a8.082 8.082 0 0 0 9.1097-6.3745l.0867-2.1065a7.843 7.843 0 0 0-1.387-3.7687l-1.3532-1.4884a7.834 7.834 0 0 0-3.7208-1.7471l-1.4194-.2507a8.082 8.082 0 0 0-9.1097 6.3744l-.0867 2.1066a7.842 7.842 0 0 0 1.387 3.7686l1.3532 1.4884a7.833 7.833 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1469"
          d="m198.0684 101.9293 1.4194.2508a8.082 8.082 0 0 0 9.1098-6.3745l.0867-2.1065a7.843 7.843 0 0 0-1.3871-3.7687l-1.3531-1.4883a7.833 7.833 0 0 0-3.7208-1.7472l-1.4194-.2507a8.082 8.082 0 0 0-9.1098 6.3744l-.0867 2.1066a7.844 7.844 0 0 0 1.3871 3.7686l1.3531 1.4884a7.835 7.835 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1470"
          d="m184.9076 126.2168 1.4194.2507a8.082 8.082 0 0 0 9.1097-6.3744l.0867-2.1066a7.843 7.843 0 0 0-1.387-3.7686l-1.3531-1.4884a7.833 7.833 0 0 0-3.7208-1.7471l-1.4194-.2508a8.082 8.082 0 0 0-9.1098 6.3745l-.0867 2.1065a7.843 7.843 0 0 0 1.3871 3.7687l1.3531 1.4883a7.835 7.835 0 0 0 3.721 1.7462Z"
        />
        <motion.path
          variants={draw}
          data-name="Path 1471"
          d="m213.443 145.4494 1.4193.2508a8.082 8.082 0 0 0 9.1097-6.3745l.0867-2.1066a7.843 7.843 0 0 0-1.387-3.7686l-1.3531-1.4884a7.834 7.834 0 0 0-3.7208-1.7471l-1.4194-.2508a8.082 8.082 0 0 0-9.1098 6.3745l-.0867 2.1066a7.843 7.843 0 0 0 1.3871 3.7686l1.3531 1.4884a7.834 7.834 0 0 0 3.72 1.746Z"
        />
      </g>
    </motion.svg>
  );
};

export default SvgBlueDots;
