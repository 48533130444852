import { getUrlDynamic, getUrlByContentType } from 'constants/app/AppUrls';
import { CONTENT_TYPE_CLIENT } from 'constants/app/AppConstants';

const parseData = (client) => {
  const {
    data: { background_image = '', logo = '', related_blogs = [] },
    id,
    name,
  } = client;

  const parsedClient = {
    id,
    details: {
      name,
      friendly_url: getUrlByContentType(CONTENT_TYPE_CLIENT, name.replace(/\s+/g, '-').toLowerCase()),
      related_blog_ids: related_blogs,
    },
    content: {
      background_image: getUrlDynamic(background_image),
      logo: getUrlDynamic(logo),
    },
  };

  return parsedClient;
};

export const parseClients = (data) => {
  const allIDs = [];
  const byID = {};

  for (const client of data) {
    const { id, name } = client;
    const parsed = parseData(client);
    allIDs.push(id);
    byID[id] = { ...parsed };
  }

  return { allIDs, byID };
};
