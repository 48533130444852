// Bootstrap Constants
export const BOOTSTRAP_CONFIG_DATA = 'BOOTSTRAP_CONFIG_DATA';
export const BOOTSTRAP_CONTENT_DATA = 'BOOTSTRAP_CONTENT_DATA';
export const BOOTSTRAP_PAGES_DATA = 'BOOTSTRAP_PAGES_DATA';

// import constants
import { CLIENTS_DISPATCH_DATA } from 'store/clients/Clients.actions';
import { PROJECTS_DISPATCH_DATA } from 'store/projects/Projects.actions';
import { CATEGORIES_DISPATCH_DATA } from 'store/categories/Categories.actions';
import { SOCIAL_DISPATCH_DATA } from 'store/social/Social.actions';
import { ILLUSTRATIONS_DISPATCH_DATA } from 'store/illustrations/Illustrations.actions';
import { EMPLOYEES_DISPATCH_DATA } from 'store/employees/Employees.actions';
import { BLOGS_DISPATCH_DATA } from 'store/blogs/Blogs.actions';

// *******
// Config data
// *******
export const dispatchConfigData = (dispatch, data) => {
  const { cms_footer, cms_meta, cms_navigation } = data;
  const { cms_social_media, config_medium, config_meta } = data;

  dispatch({
    type: BOOTSTRAP_CONFIG_DATA,
    payload: {
      footer: cms_footer,
      meta: { ...cms_meta, ...config_meta },
      navigation: cms_navigation,
      social_media: cms_social_media,
      medium: config_medium,
    },
  });
};

// *******
// Content data
// *******
export const dispatchContentData = (dispatch, data) => {
  const { categories, clients, employees, projects, blogs, illustrations, social_section } = data;

  dispatch({
    type: BLOGS_DISPATCH_DATA,
    payload: blogs,
  });

  dispatch({
    type: CLIENTS_DISPATCH_DATA,
    payload: clients,
  });

  dispatch({
    type: EMPLOYEES_DISPATCH_DATA,
    payload: employees,
  });

  dispatch({
    type: PROJECTS_DISPATCH_DATA,
    payload: projects,
  });

  dispatch({
    type: CATEGORIES_DISPATCH_DATA,
    payload: categories,
  });

  dispatch({
    type: SOCIAL_DISPATCH_DATA,
    payload: social_section,
  });

  dispatch({
    type: ILLUSTRATIONS_DISPATCH_DATA,
    payload: illustrations,
  });
};

// *******
// Page data
// *******
export const dispatchPageData = (dispatch, data) => {
  const { cms_contact, cms_home, cms_how_we_play } = data;
  const { cms_what_we_do, cms_who_we_are, cms_who_we_work_with, cms_privacy_policy, cms_creative_services } = data;

  dispatch({
    type: BOOTSTRAP_PAGES_DATA,
    payload: {
      contact: cms_contact,
      home: cms_home,
      how_we_play: cms_how_we_play,
      what_we_do: cms_what_we_do,
      who_we_are: cms_who_we_are,
      who_we_work_with: cms_who_we_work_with,
      privacy_policy: cms_privacy_policy,
      creative_services: cms_creative_services,
    },
  });
};
