import React from 'react';

// import scss
import './Navigation.base.scss';

// import components
import NavigationLink from 'components/navigation/Navigation.link';

const NaviagtionBase = ({ navigation, activeNav }) => {
  const linkCount = Object.keys(navigation).length;
  const renderLinks = () => {
    return Object.keys(navigation).map((id, index) => {
      return <NavigationLink link={navigation[id]} key={index} index={index} activeNav={activeNav} linkCount={linkCount} />;
    });
  };

  return <ul className="navigation__list">{renderLinks()}</ul>;
};

export default NaviagtionBase;
