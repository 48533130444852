import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { isEmptyObj } from 'utils/general';

// import components
import CreativeServicesCtaGrid, { CTA_ITEMS } from 'components/creative-services/CreativeServices.cta-grid';

const CreativeServicesBody = ({ data }) => {
  const { details = {} } = data;

  const { description = '', external_url = '', external_url_cta = '' } = details;

  const descriptionRef = useRef();

  useEffect(() => {
    const videos = descriptionRef.current && descriptionRef.current.querySelectorAll('video');

    if (videos.length > 0) {
      Array.from(videos).map((vid) => {
        vid.autoplay = false;
      });
    }
  }, []);

  return (
    <>
      <div className="project__body" style={{ minHeight: 0 }}>
        <div className="left"></div>
        <div className="middle">
          <div className="project__body__description wysiwyg">
            <h2>
              We’ve built a world-class team of creative thinkers, designers and developers who are passionate about kids, family, and the entertainment space. Our creative team have been delivering
              award-winning work for almost two decades for some of the world's most loved brands and properties.
            </h2>
          </div>
        </div>
      </div>

      <CreativeServicesCtaGrid />
      <div className="project__body">
        <div className="left"></div>
        <div className="middle" ref={descriptionRef}>
          {CTA_ITEMS.map((item, index) => {
            const { id, target } = item;
            const { section_title = id, section_description = '' } = data[target] || {};

            return (
              <div className="project__body__description wysiwyg project__body__description--divider" key={index}>
                <h1 id={target}>{section_title}</h1>
                <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: section_description }} />
              </div>
            );
          })}
        </div>
        {external_url !== '' && (
          <div className="right">
            <button className="project__body__external-link" aria-label="click to view project on external website">
              <a href={external_url} target="_blank" rel="noreferrer">
                {external_url_cta}
              </a>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default CreativeServicesBody;
