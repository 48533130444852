import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const random = Math.random() * (1 - 0) + 0;
    const delay = 0.5 + random * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.1 },
      },
    };
  },
};

const SvgOrangeSquares = () => {
  const controls = useAnimation();

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <motion.svg
      className="svg-icon--orange-squares"
      ref={ref}
      variants={draw}
      initial="hidden"
      animate={controls}
      xmlns="http://www.w3.org/2000/svg"
      width="551.549"
      height="222.339"
      viewBox="0 0 551.549 222.339"
    >
      <g data-name="Group 896" fill="none" stroke="rgba(255,210,8,0.3)" strokeMiterlimit="10" strokeWidth="8">
        <motion.path variants={draw} data-name="Path 1813" d="m46.638 81.506 3.218 20.447 23.172.774a1.725 1.725 0 0 0 2.1-2.368q-1.174-8.739-2.35-17.478a4.024 4.024 0 0 0-4.8-4.367l-29.648-.975" />
        <motion.path
          variants={draw}
          data-name="Path 1814"
          d="M66.197 59.474a63.825 63.825 0 0 0-.386 22.136c.3 1.918.755 3.937 2.114 5.324 1.924 1.964 5 2.053 7.753 2.013l10.348-.15a2.867 2.867 0 0 0 3.557-3.42l.106-15.75c.012-1.752-.047-3.7-1.255-4.966-1.254-1.32-3.284-1.433-5.1-1.46l-18.127-.263"
        />
        <motion.path
          variants={draw}
          data-name="Path 1815"
          d="m116.178 82.694.856 16.51c.1 1.873.336 4.017 1.877 5.087 1.172.814 2.725.724 4.146.6l17.678-1.531c-.835-7.672-2.265-16.363-8.724-20.587-6.134-4.011-14.221-2.27-21.3-.387"
        />
        <motion.path
          variants={draw}
          data-name="Path 1816"
          d="M110.521 51.209c-1.762 3.8-2.972 9.394.775 11.271a8.228 8.228 0 0 0 4.214.48l22.842-1.41a37.334 37.334 0 0 0-1.823-12.241A6.62 6.62 0 0 0 134.3 45.7a7.075 7.075 0 0 0-3.768-.873 146.024 146.024 0 0 0-25.087 1.232"
        />
        <motion.path
          variants={draw}
          data-name="Path 1817"
          d="m88.33 124.179.079 18.067c.009 2.015.179 4.355 1.835 5.5a6.354 6.354 0 0 0 3.5.783l10.366.291a4.141 4.141 0 0 0 2.206-.346c1.32-.762 1.38-2.612 1.277-4.132q-.577-8.463-1.153-16.925a2.989 2.989 0 0 0-3.8-3.355l-17.932.176"
        />
        <motion.path
          variants={draw}
          data-name="Path 1818"
          d="M143.891 124.46c-2.072 4.541 1.649 10.023 6.354 11.687s9.886.625 14.764-.43l.377-10.221a4.15 4.15 0 0 0-.556-2.774c-.767-.968-2.183-1.044-3.418-1.037l-16.913.085"
        />
        <motion.path
          variants={draw}
          data-name="Path 1819"
          d="M157.486 96.175c-3.046 2.028-1.375 7.211 1.867 8.908s7.127 1.282 10.76 1.726c2.272.278 5.3.5 6.112-1.637a4.665 4.665 0 0 0 .008-2.449l-1.663-9.4a6.426 6.426 0 0 0-1.209-3.234c-1.462-1.651-4.068-1.368-6.232-.947l-13.009 2.53"
        />
        <motion.path
          variants={draw}
          data-name="Path 1820"
          d="m155.498 54.625-.058 13.316c-.005 1.155.059 2.467.941 3.212a4.022 4.022 0 0 0 2.684.62l15.579-.141a35.358 35.358 0 0 0-.585-13.907c-.376-1.533-.991-3.2-2.413-3.889a5.872 5.872 0 0 0-3.325-.2l-14.085 1.9"
        />
        <motion.path
          variants={draw}
          data-name="Path 1821"
          d="m432.498 137.625-.058 13.316c-.005 1.155.059 2.467.941 3.212a4.022 4.022 0 0 0 2.684.62l15.579-.141a35.357 35.357 0 0 0-.585-13.907c-.376-1.533-.991-3.2-2.413-3.889a5.872 5.872 0 0 0-3.325-.2l-14.085 1.9"
        />
        <motion.path
          variants={draw}
          data-name="Path 1822"
          d="m199.425 65.597 1.553 14.224c.172 1.571.392 3.254 1.452 4.426 1.255 1.387 3.3 1.649 5.169 1.788a146.1 146.1 0 0 0 18.468.2c-.165-4.693 1.451-9.6-.275-13.968-1.7-4.314-6.368-6.878-10.975-7.416s-9.227.587-13.729 1.7"
        />
        <motion.path
          variants={draw}
          data-name="Path 1823"
          d="M181.811 84.121c-.409 4.516-.781 9.268 1.091 13.4s6.822 7.238 10.994 5.463c5.621-2.392 5.237-10.382 3.9-16.342a2.8 2.8 0 0 0-3.411-2.787l-14.5.085"
        />
        <motion.path
          variants={draw}
          data-name="Path 1824"
          d="M121.689 118.167c-.757 3.24-1.452 6.725-.228 9.82s5.19 5.309 8 3.517a6.257 6.257 0 0 0 1.888-2.066c2-3.268 2.12-7.794-.279-10.78s-7.424-3.673-10.147-.98"
        />
        <motion.path
          variants={draw}
          data-name="Path 1825"
          d="M92.393 101.208a7.234 7.234 0 0 0 2.035 9.152 7.307 7.307 0 0 0 9.362-1.107 7.455 7.455 0 0 0 .057-9.463c-2.743-3.211-7.729-3.432-11.75-2.14"
        />
        <motion.path
          variants={draw}
          data-name="Path 1826"
          d="M447.393 103.208a7.234 7.234 0 0 0 2.035 9.152 7.307 7.307 0 0 0 9.362-1.107 7.455 7.455 0 0 0 .057-9.463c-2.743-3.211-7.729-3.432-11.75-2.14"
        />
        <motion.path
          variants={draw}
          data-name="Path 1827"
          d="m133.505 152.258 1.524 26.754a1.933 1.933 0 0 0 2.647 2.178l22.657-2.661a2.423 2.423 0 0 0 2.607-3.084l-.274-13.749c-.069-3.442-.414-7.426-3.256-9.371-1.79-1.226-4.108-1.272-6.277-1.275l-24.5-.032"
        />
        <motion.path
          variants={draw}
          data-name="Path 1828"
          d="M115.23 147.367a30.271 30.271 0 0 0 1.5 13.626c.544 1.54 1.317 3.151 2.774 3.889a7.251 7.251 0 0 0 4.115.329l22.049-2.528-3.1-17.115c-.486-2.683-1.15-5.652-3.4-7.192-1.919-1.313-4.445-1.191-6.76-.97a169.89 169.89 0 0 0-22.133 3.6"
        />
        <motion.path
          variants={draw}
          data-name="Path 1829"
          d="M198.765 128.315c-1.91 2.469-.361 6.344 2.309 7.96s5.98 1.611 9.1 1.556l19.293-.335a3.386 3.386 0 0 0 2.651-.831 3.326 3.326 0 0 0 .359-2.613 101.666 101.666 0 0 0-2.5-12.067 4.5 4.5 0 0 0-5.585-4.1 127.439 127.439 0 0 0-27.439 3.8"
        />
        <motion.path
          variants={draw}
          data-name="Path 1830"
          d="M178.793 135.143c-.277 4.36-.338 8.881 1.316 12.924s5.431 7.513 9.8 7.525c6.8.018 11.051-8.3 9.611-14.942a3.977 3.977 0 0 0-1.237-2.409 4.412 4.412 0 0 0-2.568-.64 154.5 154.5 0 0 0-19.553.532"
        />
        <motion.path
          variants={draw}
          data-name="Path 1831"
          d="M215.255 77.812a54 54 0 0 0 2.043 18.372c.461 1.584 1.092 3.273 2.5 4.135a7.209 7.209 0 0 0 3.817.7l21.788-.012a2.832 2.832 0 0 0 1.873-.448c.719-.611.643-1.722.505-2.656q-1.29-8.723-2.579-17.446c-.335-2.265-.778-4.735-2.515-6.227-1.842-1.581-4.535-1.55-6.955-1.349a104.985 104.985 0 0 0-20.447 3.762"
        />
        <motion.path
          variants={draw}
          data-name="Path 1832"
          d="M266.59 57.059a62.2 62.2 0 0 0 1.674 20.358 9.358 9.358 0 0 0 2.26 4.677c1.85 1.745 4.666 1.836 7.21 1.826l23.017-.1-6.038-28.307a6.65 6.65 0 0 0-1.668-3.731c-1.46-1.3-3.683-1.06-5.611-.74l-19.715 3.265"
        />
        <motion.path
          variants={draw}
          data-name="Path 1833"
          d="M282.745 77.017c1.077 4.183 2.162 8.514 1.465 12.777s-3.671 8.469-7.93 9.187c-1.688.284-3.448.013-5.108.431a4.228 4.228 0 0 1-2.522.278c-.91-.357-1.269-1.431-1.519-2.376l-3.333-12.6c-.3-1.125-.555-2.453.191-3.346a3.711 3.711 0 0 1 2.494-.976l13.429-1.673"
        />
        <motion.path
          variants={draw}
          data-name="Path 1834"
          d="M277.23 124.217a31.631 31.631 0 0 0 3.581 10.61l-11.163 1.523c-1.429.2-3.016.355-4.194-.477-3.027-2.139.069-7.417-1.985-10.5a20.9 20.9 0 0 1 10.381-2.4"
        />
        <motion.path
          variants={draw}
          data-name="Path 1835"
          d="m230.002 155.919-.244 15.284c-.023 1.433.011 3.022.987 4.071a5.231 5.231 0 0 0 3.277 1.291 32.529 32.529 0 0 0 14-1.31 92.183 92.183 0 0 0-.085-14.945 3.128 3.128 0 0 0-3.7-3.514 63.842 63.842 0 0 0-20.455 2.628"
        />
        <motion.path
          variants={draw}
          data-name="Path 1836"
          d="m177.706 199.796 1.685 10.183c2.506 1.087 5.111 2.194 7.841 2.129s5.627-1.653 6.3-4.3c.877-3.467-2.571-6.812-6.124-7.228s-7 1.184-10.216 2.743"
        />
        <motion.path variants={draw} data-name="Path 1837" d="M78.611 201.067a49.4 49.4 0 0 0-.933 17.044l31.093-1.95-1.285-14.409a2.929 2.929 0 0 0-3.911-3.3l-32.313 3.5" />
        <motion.path
          variants={draw}
          data-name="Path 1838"
          d="M78.011 175.089c-2.161 1.634-1.565 5.333.551 7.024s5.071 1.83 7.764 1.539c2.388-.257 4.957-.925 6.386-2.855 2.36-3.186-.058-8.17-3.786-9.519s-7.921.018-11.3 2.091"
        />
        <motion.path
          variants={draw}
          data-name="Path 1839"
          d="M60.503 25.567c-1.782 3.61-2.885 9.184.876 10.62a7.307 7.307 0 0 0 3.564.119l12.02-1.6c-1.624-2.027-.173-5.03-.576-7.6-.507-3.235-4.055-5.271-7.33-5.236s-6.313 1.6-9.209 3.13"
        />
        <motion.path variants={draw} data-name="Path 1840" d="M4.252 140.055a41.855 41.855 0 0 0 .61 12.992 30.557 30.557 0 0 1 14.846.125l1.464-15.51a95.551 95.551 0 0 0-16.942 1.531" />
        <motion.path
          variants={draw}
          data-name="Path 1841"
          d="M308.466 101.243c-1.587 2.91.667 6.715 3.729 7.986s6.536.747 9.806.2a2.936 2.936 0 0 0 3.028-3.335l.972-9.964a129.745 129.745 0 0 0-22.868 3.617"
        />
        <motion.path
          variants={draw}
          data-name="Path 1842"
          d="M338.93 77.884c-.886 3.7-1.329 7.618-.281 11.272s3.84 6.986 7.552 7.8c6.259 1.378 12.263-6.6 9.209-12.231-3.547-6.542-13.078-5.609-20.284-3.752"
        />
        <motion.path
          variants={draw}
          data-name="Path 1843"
          d="m317.805 49.744-.064 15.145a2.489 2.489 0 0 0 3.081 3.069l19.162-.276c3.808-.055 8.087-.314 10.62-3.157 3.656-4.1.876-11.2-4.017-13.7s-10.775-1.7-16.136-.494-10.9 2.764-16.265 1.584"
        />
        <motion.path variants={draw} data-name="Path 1844" d="m297.057 122.967-1.469 19.444-24.443.03.161-10.9a2.758 2.758 0 0 1 2.986-3.4l19.134-2.206" />
        <motion.path
          variants={draw}
          data-name="Path 1845"
          d="m270.238 188.867-.138 10.9a4.258 4.258 0 0 0 .608 2.771c.791.985 2.237 1.059 3.5 1.051l11.012-.073c1.081-.008 2.315-.085 2.99-.929a3.7 3.7 0 0 0 .411-2.764q-.78-6.753-1.686-13.489l-13.093 1.2"
        />
        <motion.path
          variants={draw}
          data-name="Path 1846"
          d="M215.449 192.451c-3.069.42-3.421 5.278-1.067 7.291s5.788 1.94 8.88 1.759l9.194-.539a1.922 1.922 0 0 0 2.133-2.668l-1.394-11.1a65.131 65.131 0 0 0-16.66 1.826"
        />
        <motion.path
          variants={draw}
          data-name="Path 1847"
          d="m207.448 24.082-1.008 16.351c-.149 2.423-.264 5 .94 7.111s4.266 3.327 6.136 1.78c1.186-4.49 2.372-9.246 1.093-13.71s-6.019-8.286-10.427-6.826"
        />
        <motion.path variants={draw} data-name="Path 1848" d="m146.936 9.467.07 9.68 18.262-.923a6.732 6.732 0 0 0 3.263-.724c2.6-1.613 1.6-6.024-1.053-7.55s-5.953-1.055-8.96-.5-6.268 1.125-9-.254" />
        <motion.path variants={draw} data-name="Path 1849" d="m323.936 4.467.07 9.68 18.262-.923a6.732 6.732 0 0 0 3.263-.724c2.6-1.613 1.6-6.024-1.053-7.55s-5.953-1.055-8.96-.5-6.268 1.125-9-.254" />
        <motion.path
          variants={draw}
          data-name="Path 1850"
          d="m432.586 69.25 1.524 8.342a58.391 58.391 0 0 0 19.8-1.338c1.262-4.633 1.729-11.018-2.726-12.81a9.933 9.933 0 0 0-3.908-.455l-14.787.258"
        />
        <motion.path variants={draw} data-name="Path 1851" d="m381.277 41.205-.108 11.151a2.771 2.771 0 0 0 3.31 3.511h9.222c.385-3.74.7-7.813-1.349-10.968s-7.466-4.183-9.376-.945" />
        <motion.path
          variants={draw}
          data-name="Path 1852"
          d="m379.987 80.249.119 23.108 27.71.1a5.987 5.987 0 0 0 3.932-.913c1.318-1.1 1.432-3.052 1.379-4.766a69.679 69.679 0 0 0-3.138-18.582 7.989 7.989 0 0 0-2.2-3.957c-1.348-1.108-3.215-1.268-4.957-1.381l-19.139-1.239"
        />
        <motion.path
          variants={draw}
          data-name="Path 1853"
          d="M396.39 62.152c-1.272 7.1-1.52 16.339 5.013 19.4 3.483 1.629 7.568.662 11.283-.331a3.023 3.023 0 0 0 2.787-3.716l-.028-11.4a5.748 5.748 0 0 0-.689-3.327c-1.022-1.464-3.087-1.64-4.871-1.625a93.192 93.192 0 0 0-16.522 1.62"
        />
        <motion.path
          variants={draw}
          data-name="Path 1854"
          d="M527.092 46.533c-2.433 3.309.053 8.365 3.8 10.055s8.077 1.028 12.126.335a4.061 4.061 0 0 0 4.157-4.216c.772-4.3.652-10.145-3.548-11.339a8.945 8.945 0 0 0-3.725.018l-15.861 2.316"
        />
      </g>
    </motion.svg>
  );
};

export default SvgOrangeSquares;
