import { DEVICE_TYPE } from 'constants/app/AppConstants';

// SET IF WEBP SHOULD BE ENABLED
// For local dev - I don't have webp versions being created in dynamic
// So disable for local
const ENABLE_WEBP_SUPPORT = false;

//stackoverflow.com/questions/5573096/detecting-webp-support
export const canUseWebP = () => {
  const elem = document.createElement('canvas');
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!(elem.getContext && elem.getContext('2d'))) {
    const testString = !(window.mozInnerScreenX == null) ? 'png' : 'webp';
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/' + testString) == 0;
  }
  // very old browser like IE 8, canvas not supported
  return false;
};

export const convertFileExtensionToWebp = (url) => {
  if (!url) {
    return '';
  }
  return canUseWebP() && ENABLE_WEBP_SUPPORT ? url.replace(new RegExp('.(' + ['jpg', 'jpeg', 'png'].join('|') + ')$', 'i'), '.webp') : url;
};

export const processImages = (placeholder, images, widths = ['1x', '2x', '3x']) => {
  const srcSet = images.reduce((acc, image, index) => {
    if (image && image !== '') {
      const width = widths[index] || '';
      acc += `${image} ${width}`;
      if (index < images.length - 1) {
        acc += ', ';
      }
    }
    return acc;
  }, '');
  const placeholder_lazy = convertFileExtensionToWebp(placeholder);
  const src = DEVICE_TYPE === 'desktop' ? images[2] : images[0];
  return { placeholder_lazy, srcSet, src };
};
