import React from 'react';
import { Link, useHistory } from 'react-router-dom';

// import const
import { URL_PAGE_HOW_WE_PLAY } from 'constants/app/AppUrls';

// import components
import SvgArrowLeft from 'components/svg/generic/Svg.arrow_left';

const BlogHeader = ({ data }) => {
  const history = useHistory();

  const { details, content, title, meta } = data;
  const { author, main_image, slider_image, text } = content;
  const { intro, posted, subheading } = details;

  return (
    <div className="blog__header" style={{ backgroundImage: `url(${slider_image})` }}>
      <div className="blog__header__container">
        <div className="back-button">
          <button
            className="go-back"
            onClick={() => {
              history.push('/blog');
            }}
          >
            <SvgArrowLeft /> Back
          </button>
        </div>
        <div className="title">
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default BlogHeader;
