//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import consts
import { URL_PAGE_WHAT_WE_DO } from 'constants/app/AppUrls';

// import selectors
import { getSiteMetaData } from 'store/app/App.selectors';
import { getSocialPageByUrl } from 'store/social/Social.selectors';

// import components
import CategoryBase from 'components/pages/category/Category.base';
import CategoryGrid from 'components/category/Category.grid';
import SvgBlueDots from 'components/svg/patterns/Svg.blue_dots';
import SvgOrangeSquares from 'components/svg/patterns/Svg.orange_squares';
import SiteMetaData from 'components/generic/SiteMetaData';
import PageNotFoundBase from 'components/page-not-found/PageNotFound.base';
import SocialContentGrid from 'components/social-content/SocialContent.grid';

import { trackPageview } from 'utils/tracking';

class PageSocialContent extends Component {
  componentDidMount() {
    trackPageview({ title: this.props?.page?.details?.name || 'unknown social content name', path: this.props.location.pathname });
  }
  render() {
    const { page = false, metadata, isExact } = this.props;
    if (!page || !isExact) {
      return <PageNotFoundBase />;
    }

    const { type, id, details: { types = [] } = {} } = page;

    const { title, description, og_title, og_description } = metadata;

    const base_meta_title = title.replace('{title}', type);
    const base_meta_description = description.replace('{title}', type);
    const base_og_title = og_title.replace('{title}', type);
    const base_og_description = og_description.replace('{title}', type);

    if (page) {
      return (
        <div className={'category' + ' category--illustration'}>
          <SiteMetaData {...this.props.metadata} title={base_meta_title} description={base_meta_description} og_description={base_og_description} og_title={base_og_title} />
          <div className="container">
            <SvgBlueDots />
            <SvgOrangeSquares />
            <CategoryBase data={page} back_link={`${URL_PAGE_WHAT_WE_DO}/social`} />
          </div>
          <SocialContentGrid types={types} />
        </div>
      );
    }
    return (
      <div className="category">
        <SiteMetaData {...this.props.metadata} />
        <div className="container">No Data</div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const urlFragment = ownProps.match.url || '';
  const page = getSocialPageByUrl(state, urlFragment);

  return {
    page,
    metadata: getSiteMetaData(state, 'individual_category'),
    isExact: ownProps.match.isExact,
  };
};

export default withRouter(connect(mapStateToProps, null)(PageSocialContent));
