import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';

// import scss
import './Navigation.link.scss';

// import consts
import { NAV_TYPE } from 'constants/app/AppConstants';

const NaviagtionLink = ({ link, index, activeNav, linkCount }) => {
  const { id, name, url, type, target = '', classFriendlyName } = link;
  const containerDelay = `${0.2 * (index + 1)}s`;
  const linkDelay = `${0.25 * (index + 1)}s`;
  const count = `${100 / linkCount}%`;

  const renderInternalLink = () => {
    return (
      <NavLink to={`/${url}`} className="navigation__link" activeClassName="active" onClick={activeNav}>
        <span style={{ transitionDelay: linkDelay }}>{name}</span>
      </NavLink>
    );
  };

  const renderExternalLink = () => {
    return (
      <a href={url} className="navigation__link" target={target} rel="noopener" onClick={activeNav}>
        <span style={{ transitionDelay: linkDelay }}>{name}</span>
      </a>
    );
  };

  return (
    <li className={`navigation__${classFriendlyName}`} key={id} style={{ transitionDelay: containerDelay, height: count }}>
      {type === NAV_TYPE.INTERNAL ? renderInternalLink() : renderExternalLink()}
    </li>
  );
};

export default withRouter(NaviagtionLink);
