import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// import utils
import { debounce } from 'utils/general';

// import scss
import './Sections.header.scss';

// import consts
import { getUrlFrontendImage } from 'constants/app/AppUrls';

// import containers
import Nav from 'containers/sections/Sections.nav';

// import components
import SocialIconList from 'components/social-icons/SocialIcons.list';
import NaviagtionMobileButton from 'components/navigation/Navigation.mobile_button';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHomepage: false,
      isTop: false,
    };

    // bind functions
    this.scrollListener = this.scrollListener.bind(this);
    this.debouncedFunction = debounce(this.scrollListener, 10);
  }

  componentDidMount() {
    const { location } = this.props;
    const { pathname } = location;

    this.setState({ isHomepage: pathname === '/' });
    this.checkUserScrollPosition(pathname === '/');
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ isHomepage: this.props.location.pathname === '/' });
      this.checkUserScrollPosition(this.props.location.pathname === '/');
    }
  }

  checkUserScrollPosition(attachListener) {
    if (attachListener) {
      window.addEventListener('scroll', this.debouncedFunction);
      this.scrollListener();
    } else {
      window.removeEventListener('scroll', this.debouncedFunction);
      this.setState({ isTop: false });
    }
  }

  scrollListener() {
    this.setState({ isTop: window.pageYOffset === 0 });
  }

  render() {
    const { isHomepage, isTop } = this.state;
    return (
      <header className={'header' + (isHomepage ? ' header--home' : '') + (isTop ? ' header--home--is-top' : '')}>
        <div className="container">
          <div className="logo">
            <img
              src={getUrlFrontendImage('base/logo.png')}
              alt="Jollywise"
              title="Jollywise"
              onClick={() => {
                window.open(window.location.origin, '_self');
              }}
            />
          </div>
          <Nav />
          <NaviagtionMobileButton />
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
