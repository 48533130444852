import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import PictureMappings from 'components/picture-mappings/PictureMappings';

const BlogBody = ({ data }) => {
  const { details, content, title, meta } = data;

  const { author, main_image, slider_image, text } = content;
  const { intro, posted, previous_blog_url, next_blog_url } = details;

  const { image, name, description } = author;

  const descriptionRef = useRef(null);

  useEffect(() => {
    // if (descriptionRef.current) {
    //   Array.from(descriptionRef.current.querySelectorAll('img')).map((img, index) => {
    //     if (index !== 0) {
    //       const value = index % 2 == 0 ? 'right' : 'left';
    //       img.classList.add(`align-${value}`);
    //     }
    //   });
    // }

    const videos = descriptionRef.current.querySelectorAll('video');

    if (videos.length > 0) {
      Array.from(videos).map((vid) => {
        vid.autoplay = false;
      });
    }
  }, []);

  return (
    <div className="blog__body">
      <div className="left">
        {author && (
          <div className="blog__body__author">
            <div className={'blog__author__image' + (image && image !== '' ? '' : ' blog__author__image--no-image')}>
              {image && image !== '' && <PictureMappings src={image} alt={name} title={name} />}
            </div>

            <div className="blog__author__name">{name}</div>
            <div className="blog__author__description">{description}</div>
          </div>
        )}
      </div>
      <div className="middle">
        <div className="blog__body__description wysiwyg" ref={descriptionRef} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
      <div className="right">
        {previous_blog_url && (
          <button className="blog__body__blog-link" aria-label="Click to read the previous blog post">
            <Link to={previous_blog_url}>Previous Blog Post</Link>
          </button>
        )}
        {next_blog_url && (
          <button className="blog__body__blog-link" aria-label="Click to read the next blog post">
            <Link to={next_blog_url}>Next Blog Post</Link>
          </button>
        )}
      </div>
    </div>
  );
};

export default BlogBody;
