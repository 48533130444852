import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { LazyImage } from 'react-lazy-images';

// import constants
import { CONTENT_TYPE_BLOG } from 'constants/app/AppConstants';

// import scss
import './Home.showcase_content.scss';

// import utils
import { convertFileExtensionToWebp } from 'utils/general';

const duration = 1.2;

const HomeShowcaseContent = ({ data, type }) => {
  const controls = useAnimation();

  const { ref, inView, entry } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  // rise in
  const motion_styles = {
    hidden: { y: 60, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ease: 'easeInOut',
        duration,
      },
    },
  };

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  const { friendly_url, details = {}, content = {} } = data;
  const { name = '', description, title = '' } = details;
  const { header_image = '', slider_image = '', header_srcset = false } = content;

  const showcase_title = name !== '' ? name : title;
  const showcase_image = header_image !== '' ? header_image : slider_image;
  const showcase_link_text = type === CONTENT_TYPE_BLOG ? 'Read our latest blog post' : 'Read about our latest case study';

  const { src = showcase_image, srcSet = [] } = header_srcset;

  return (
    <motion.article className="home-showcase_content" variants={motion_styles} initial="hidden" animate={controls} ref={ref}>
      <figure>
        <LazyImage
          src={convertFileExtensionToWebp(src)}
          alt={showcase_title}
          placeholder={({ imageProps, ref }) => (
            <div style={{ minHeight: '500px' }}>
              <div className="lazy-load__placeholder" ref={ref} />
            </div>
          )}
          actual={({ imageProps }) => <img {...imageProps} width="1920" height="946" />}
        />
        <figcaption>
          <div className="container">
            <div className="home-showcase_content__details">
              <h2 className="home-showcase_content__title">{showcase_title}</h2>
              <Link to={friendly_url}>{showcase_link_text}</Link>
            </div>
          </div>
        </figcaption>
      </figure>
    </motion.article>
  );
};

export default HomeShowcaseContent;
