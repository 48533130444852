import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// import selectors
import { getCurrentVacancies } from 'store/blogs/Blogs.selectors';

// import components
import CurrentVacanciesBlock from 'components/current-vacancies/CurrentVacancies.block';

const CurrentVacanciesGrid = (props) => {
  const renderBlocks = () => {
    return props.data.map((block, index) => {
      return <CurrentVacanciesBlock block={block} key={index} index={index} />;
    });
  };
  return props.data.length > 0 ? (
    <>
      <h2 className="current-vacancies__title">Current Vacancies</h2>
      <ul className="current-vacancies__grid">{renderBlocks()}</ul>
    </>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    data: getCurrentVacancies(state),
  };
};

export default connect(mapStateToProps, null)(CurrentVacanciesGrid);
