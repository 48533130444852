// impot consts
import { CONTENT_TYPE_CLIENT } from 'constants/app/AppConstants';

// import selectors
import { getClientByID } from 'store/clients/Clients.selectors';
import { getBlogById } from 'store/blogs/Blogs.selectors';

const getProjectsClientData = ({ data, state }) => {
  const { details } = data;
  const { client } = details;

  return getClientByID(state, client);
};

const getCategoryProjects = (state, id, skipID = false) => {
  const returnableData = [];
  const static_ids = state.projects.byCategoryID[id];
  if (static_ids && static_ids.length > 0) {
    const projects = static_ids.map((id, index) => {
      const project = state.projects.byID[id];

      if (skipID) {
        if (project.id !== skipID) {
          returnableData.push(project);
          const { details } = project;
          const { external_url = '' } = details;
        }
      } else {
        returnableData.push(project);
        const { details } = project;
        const { external_url = '' } = details;
      }

      // if (external_url !== '') {
      //   const copy = { ...project };
      //
      //   copy.isExternal = true;
      //   returnableData.push(copy);
      // }
    });

    return returnableData;
  }

  return returnableData;
};

export const getProjectDataByID = (state, id) => {
  let searchID = id == 'latest' ? state.projects.latestProjectByID[0] : id;
  return state.projects.byID[searchID] || [];
};

export const getProjectIDs = (state) => {
  return state.projects.allIDs;
};

export const getProjectByID = (state, id) => {
  return state.projects.byID[id] || false;
};

export const getProjectsByClientID = (state, id) => {
  const ids = state.projects.byClientID[id] || [];

  if (ids.length > 0) {
    return ids.map((id) => {
      return state.projects.byID[id];
    });
  }

  return [];
};

export const getLimitedProjectsByClientID = (state, id) => {
  const currentDisplayCount = state.projects.numberToDisplay;
  return getProjectsByClientID(state, id).slice(0, currentDisplayCount);
};

export const getProjectsByUrl = (state, url) => {
  const ids = getProjectIDs(state);
  let returnableData = [];

  if (ids.length > 0) {
    let i = 0;
    for (i; i < ids.length; i++) {
      const project = { ...state.projects.byID[ids[i]] };

      if (project.friendly_url === url) {
        const { details } = project;
        const { related_blog } = details;

        if (related_blog) {
          const blog_url = getBlogById(state, related_blog, 'friendly_url');
          project.related_blog = blog_url;
        }
        returnableData = { data: project, clientData: getProjectsClientData({ data: project, state }) };
        break;
      }
    }
  }

  return returnableData;
};

export const getProjectsByCategoryID = (state, id) => {
  const currentDisplayCount = state.projects.numberToDisplay;
  return getCategoryProjects(state, id).slice(0, currentDisplayCount);
};

export const getRelatedLimitedProjectsByCategoryID = (state, id, count, currentProjectID) => {
  const data = getCategoryProjects(state, id, currentProjectID).slice(0, count);
  return data;
};

export const getRelatedLimitedProjectsByClientID = (state, id, count = false, currentProjectID = false) => {
  const ids = state.projects.byClientID[id] || [];

  if (ids.length > 0) {
    let array = [...ids];
    if (currentProjectID) {
      array.splice(ids.indexOf(currentProjectID), 1);
    }
    if (count) {
      array.slice(0, count);
    }

    return array.map((id) => {
      return state.projects.byID[id];
    });
  }

  return [];
};

export const shouldDisplayMoreButton = (state, id, type) => {
  const currentDisplayCount = state.projects.numberToDisplay;
  const totalCount = type === CONTENT_TYPE_CLIENT ? getProjectsByClientID(state, id).length : getCategoryProjects(state, id).length;
  return currentDisplayCount < totalCount;
};
