import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const random = Math.random() * (1 - 0) + 0;
    const delay = 0.1 + random * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.1 },
      },
    };
  },
};

const SvgLineyLines = () => {
  return (
    <AnimatePresence>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.44 242.12" className="svg-icon--liney-lines">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g id="Group_829" data-name="Group 829">
              <motion.path
                variants={draw}
                initial="hidden"
                animate="visible"
                id="Path_1400"
                data-name="Path 1400"
                className="cls-1"
                d="M5 167.28c16.38-17.37 40-32.91 66.81-44 9.77 10 25.24 17.24 43.94 20.48l47.45-34c10.3 12.5 25.6 22.74 44.56 29.85 12.13-22.39 30.87-44.46 54.79-64.51l30.33 40.73 52.43-39c18 2.59 11.9 25.13 31.71 24.42 15.29-.55 25-14.17 38.6-21.44 22.09-11.82 48.31-4.39 66.07 3.67"
              />
              <motion.path
                variants={draw}
                initial="hidden"
                animate="visible"
                id="Path_1401"
                data-name="Path 1401"
                className="cls-1"
                d="M13.9 206.79c15.75-14.79 35.82-28.21 58.35-39l62.62 14.93c3.24.78 7.15 1.49 10.85 0 3.4-1.35 5.08-3.94 6.39-6.23l15-26.38a309 309 0 0 0 59.31 29.53l4.82-32.49c21.82 14.67 55.21 22 91.74 20.17l-2.61-30.21a338.44 338.44 0 0 0 79.33-.87c3.95-11.76 18.38-23.75 36.14-30s38.06-6.54 50.85-.68"
              />
              <motion.path
                variants={draw}
                initial="hidden"
                animate="visible"
                id="Path_1402"
                data-name="Path 1402"
                className="cls-1"
                d="M45.86 237.12c11.25-18.45 52.63-30.26 72.21-20.62 8.37 4.12 14.54 11.47 26.89 10 14.16-1.68 22.87-13.14 27.6-22.22 23 9.41 49.87 15.6 79.06 18.16a61 61 0 0 0 .94-16.57l59.59 8.2c4.64.63 10.1 1.15 14.86-1 3.42-1.57 5.45-4.08 7.24-6.43l21.35-27.85c18.5-.25 33 9.5 51.92 4.11 9.82-2.8 17.28-8.92 24.19-14.56 22.63-18.49 57.55-38.76 85.36-33.29"
              />
              <motion.path
                variants={draw}
                initial="hidden"
                animate="visible"
                id="Path_1403"
                data-name="Path 1403"
                className="cls-1"
                d="m42.31 102.86 122.52-43.4c-15 11.35-26.37 24.44-32.54 37.62l113.83-73.44-39.54 53.94A1008.18 1008.18 0 0 1 339.2 5C330 24.55 317.16 44.06 301 62.9a799.9 799.9 0 0 1 119.59-51.68L393 51.05c41.61-15.89 84.25-30 127.44-42.29-9.54 12.89-13.06 26.59-9.87 38.48"
              />
            </g>
          </g>
        </g>
      </svg>
    </AnimatePresence>
  );
};

export default SvgLineyLines;
