import React from 'react';
import { useHistory } from 'react-router-dom';

// import const
import { getUrlFrontendImage } from 'constants/app/AppUrls';

// import scss
import './CreativeServices.header.scss';

// import components
import SvgArrowLeft from 'components/svg/generic/Svg.arrow_left';

// import images

const CreativeServicesHeader = () => {
  const history = useHistory();

  return (
    <div className="creative-services__header" style={{ backgroundImage: `url(${getUrlFrontendImage('base/creative-services-bg.png')})` }}>
      <div className="creative-services__header__container">
        <div className="back-button">
          <button
            className="go-back"
            onClick={() => {
              history.push('/what-we-do');
            }}
          >
            <SvgArrowLeft /> Back
          </button>
        </div>
        <div className="title">
          <h1>Creative Services</h1>
        </div>
      </div>
    </div>
  );
};

export default CreativeServicesHeader;
