import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const random = Math.random() * (1 - 0) + 0;
    const delay = 0.5 + random * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.1 },
      },
    };
  },
};

const SvgYellowLineLines = () => {
  const controls = useAnimation();

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 614.79 248.23" className="svg-icon--yellow-line-lines" ref={ref} variants={draw} initial="hidden" animate={controls}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Group_828" data-name="Group 828">
            <motion.path variants={draw} id="Path_1358" data-name="Path 1358" className="cls-1" d="M424.48 38.65a166.48 166.48 0 0 1 37-7.13" />
            <motion.path variants={draw} id="Path_1359" data-name="Path 1359" className="cls-1" d="m457.62 49.46 25-3.63" />
            <motion.path variants={draw} id="Path_1360" data-name="Path 1360" className="cls-1" d="M424.52 65.32A132.25 132.25 0 0 1 438 61.94" />
            <motion.path variants={draw} id="Path_1361" data-name="Path 1361" className="cls-1" d="m458.59 74.66 41.77-5.85" />
            <motion.path variants={draw} id="Path_1362" data-name="Path 1362" className="cls-1" d="m511.87 53 28.33-3.81" />
            <motion.path variants={draw} id="Path_1363" data-name="Path 1363" className="cls-1" d="m497 33.32 29.28-3" />
            <motion.path variants={draw} id="Path_1364" data-name="Path 1364" className="cls-1" d="m532 71.69 19.68-2" />
            <motion.path variants={draw} id="Path_1365" data-name="Path 1365" className="cls-1" d="m400 98.64 27.38-8.78" />
            <motion.path variants={draw} id="Path_1366" data-name="Path 1366" className="cls-1" d="m380.85 74.23 9.6-2" />
            <motion.path variants={draw} id="Path_1367" data-name="Path 1367" className="cls-1" d="m401.48 56.49 8.16-2.49" />
            <motion.path variants={draw} id="Path_1368" data-name="Path 1368" className="cls-1" d="M397.17 28.49a153 153 0 0 1 32.63-10.36" />
            <motion.path variants={draw} id="Path_1369" data-name="Path 1369" className="cls-1" d="M481.64 9.84 506.14 5" />
            <motion.path variants={draw} id="Path_1370" data-name="Path 1370" className="cls-1" d="m564.19 9.34 9.12-1" />
            <motion.path variants={draw} id="Path_1371" data-name="Path 1371" className="cls-1" d="m557 28.44 7.67-1.44" />
            <motion.path variants={draw} id="Path_1372" data-name="Path 1372" className="cls-1" d="m535.39 17.18 6.24-1" />
            <motion.path variants={draw} id="Path_1374" data-name="Path 1374" className="cls-1" d="M461.94 103.08q26.52-5.38 53.31-9.08" />
            <motion.path variants={draw} id="Path_1375" data-name="Path 1375" className="cls-1" d="m560.35 91.88 14.4-2.46" />
            <motion.path variants={draw} id="Path_1376" data-name="Path 1376" className="cls-1" d="m594.43 71.24 15.36-2.46" />
            <motion.path variants={draw} id="Path_1377" data-name="Path 1377" className="cls-1" d="m560.83 56.49 3.84-.49" />
            <motion.path variants={draw} id="Path_1378" data-name="Path 1378" className="cls-1" d="m342.93 108.09 11.52-3.93" />
            <motion.path variants={draw} id="Path_1379" data-name="Path 1379" className="cls-1" d="M306.42 90.33a293.19 293.19 0 0 1 43.26-16" />
            <motion.path variants={draw} id="Path_1380" data-name="Path 1380" className="cls-1" d="m341.48 56 18.75-7.78" />
            <motion.path variants={draw} id="Path_1382" data-name="Path 1382" className="cls-1" d="M272.86 138.08a119.47 119.47 0 0 1 16.3-7.9" />
            <motion.path variants={draw} id="Path_1383" data-name="Path 1383" className="cls-1" d="M276.69 166.07a82.07 82.07 0 0 1 17.76-9.81" />
            <motion.path variants={draw} id="Path_1384" data-name="Path 1384" className="cls-1" d="m336.21 132.66 11.52-4.91" />
            <motion.path variants={draw} id="Path_1385" data-name="Path 1385" className="cls-1" d="m391.88 118.9 13.92-4.42" />
            <motion.path variants={draw} id="Path_1387" data-name="Path 1387" className="cls-1" d="M281 111.09a272.93 272.93 0 0 1-42.75 15.61" />
            <motion.path variants={draw} id="Path_1388" data-name="Path 1388" className="cls-1" d="m210 144.38-30.71 4" />
            <motion.path variants={draw} id="Path_1389" data-name="Path 1389" className="cls-1" d="M246 155.4q-30.63 6.6-61.48 12.08" />
            <motion.path variants={draw} id="Path_1390" data-name="Path 1390" className="cls-1" d="M92.8 121.36a293 293 0 0 0 84-.86" />
            <motion.path variants={draw} id="Path_1391" data-name="Path 1391" className="cls-1" d="m123.1 150.36 14.4-2" />
            <motion.path variants={draw} id="Path_1392" data-name="Path 1392" className="cls-1" d="m117.34 179.84 8.16-2.45" />
            <motion.path variants={draw} id="Path_1393" data-name="Path 1393" className="cls-1" d="M139.9 197q35.21-5.66 70.08-13.22" />
            <motion.path variants={draw} id="Path_1394" data-name="Path 1394" className="cls-1" d="M220.06 112.51q8.3-2.36 16.8-3.93" />
            <motion.path variants={draw} id="Path_1395" data-name="Path 1395" className="cls-1" d="M5 85.14a243.87 243.87 0 0 0 31.23 4.7" />
            <motion.path variants={draw} id="Path_1396" data-name="Path 1396" className="cls-1" d="m35.75 137.1 25.92-.49" />
            <motion.path variants={draw} id="Path_1397" data-name="Path 1397" className="cls-1" d="M12.7 159.73a252.39 252.39 0 0 0 73.93 2" />
            <motion.path variants={draw} id="Path_1398" data-name="Path 1398" className="cls-1" d="M30.47 206.38 73.66 198" />
            <motion.path variants={draw} id="Path_1399" data-name="Path 1399" className="cls-1" d="M25.66 243.23 91 223.62" />
          </g>
        </g>
      </g>
    </motion.svg>
  );
};

export default SvgYellowLineLines;
