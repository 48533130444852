import React from 'react';

// import components
import PictureMappings from 'components/picture-mappings/PictureMappings';

// import scss
import './Home.awards.scss';

// import svgs
import SvgVimeo from 'components/svg/social/Svg.vimeo';

const HomeAwards = ({ awards }) => {
  const renderAwardLogos = () => {
    const awardCount = awards.length;
    const marginright = awardCount > 3 ? 6 : 13;

    return awards.map((logo, index) => {
      const { image, name, external_url = '', external_url_target = '_blank' } = logo;
      const style = {
        marginRight: `${marginright}%`,
      };
      return (
        <li key={index} className="home-header__awards__logo" style={style}>
          {external_url && external_url !== '' ? (
            <a href={external_url} target={external_url_target} rel="noopener noreferrer">
              <PictureMappings src={image} alt={name} title={name} />
            </a>
          ) : (
            <PictureMappings src={image} alt={name} title={name} />
          )}
        </li>
      );
    });
  };
  return <ul className="home-header__awards">{renderAwardLogos()}</ul>;
};

export default HomeAwards;
