//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { URL_PAGE_WHO_WE_WORK_WITH } from 'constants/app/AppUrls';
import { CONTENT_TYPE_CLIENT } from 'constants/app/AppConstants';

// import selectors
import { getClientDataByUrl } from 'store/clients/Clients.selectors';
import { getSiteMetaData } from 'store/app/App.selectors';

// import components
import CategoryBase from 'components/pages/category/Category.base';
import CategoryGrid from 'components/category/Category.grid';
import SvgBlueDots from 'components/svg/patterns/Svg.blue_dots';
import SvgOrangeSquares from 'components/svg/patterns/Svg.orange_squares';
import SiteMetaData from 'components/generic/SiteMetaData';
import PageNotFoundBase from 'components/page-not-found/PageNotFound.base';
import BlogRelatedGrid from 'components/blog/Blog.related_grid';

import { trackPageview } from 'utils/tracking';

class PageCategory extends Component {
  componentDidMount() {
    trackPageview({ title: this.props?.client?.details?.name || 'Unknown client name', path: this.props.location.pathname });
  }
  render() {
    const { projects = false, clientID, client = false, metadata, isExact } = this.props;
    if (clientID == '' || !isExact) {
      return <PageNotFoundBase />;
    }

    const { details: { related_blog_ids = [], name = '' } = {}, content: { background_image = '' } = {} } = client;

    const { title, description, og_title, og_description } = metadata;

    const base_meta_title = title.replace('{title}', name);
    const base_meta_description = description.replace('{title}', name);
    const base_og_title = og_title.replace('{title}', name);
    const base_og_description = og_description.replace('{title}', name);

    if (projects) {
      return (
        <div className="category client">
          <SiteMetaData
            {...this.props.metadata}
            title={base_meta_title}
            description={base_meta_description}
            og_description={base_og_description}
            og_title={base_og_title}
            og_image={background_image}
          />
          <div className="container">
            <SvgBlueDots />
            <SvgOrangeSquares />
            <CategoryBase data={client} back_link={URL_PAGE_WHO_WE_WORK_WITH} />
          </div>
          <CategoryGrid id={clientID} currentCategory={projects} type={CONTENT_TYPE_CLIENT} />
          {related_blog_ids.length > 0 && (
            <div className="related_blog_container container">
              <BlogRelatedGrid ids={related_blog_ids} />
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="category">
        <SiteMetaData {...this.props.metadata} />
        <div className="container">No Data</div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const urlFragment = ownProps.match.url || '';
  const { projects, id, client } = getClientDataByUrl(state, urlFragment);

  return {
    client,
    projects,
    clientID: id,
    metadata: getSiteMetaData(state, 'individual_who-we-work-with'),
    isExact: ownProps.match.isExact,
  };
};

export default withRouter(connect(mapStateToProps, null)(PageCategory));
