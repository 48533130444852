// import constants
import { MODAL_OVERLAY_TYPE_CLIENT, MODAL_OVERLAY_TYPE_EMPLOYEE } from 'constants/app/AppConstants';

// import selectors
import { getEmployeeByID } from 'store/employees/Employees.selectors';
import { getClientByID } from 'store/clients/Clients.selectors';
import { getProjectsByClientID } from 'store/projects/Projects.selectors';

export const getBootstrapComplete = (state) => {
  return state.app.bootstrapComplete;
};

export const getSiteMetaData = (state, id) => {
  if (state.app.meta.byID) {
    return state.app.meta.byID[id] || state.app.meta.byID.home;
  }
  return state.app.meta.byID.home;
};

export const getSiteNavigation = (state) => {
  return state.app.navigation;
};

export const getActiveMobileNavState = (state) => {
  return state.app.activeMobileNav;
};

export const getSocialData = (state) => {
  return state.app.social_media;
};

export const getFooterData = (state) => {
  return state.app.footer;
};

export const isActiveOverlayModal = (state) => {
  return state.app.activeOverlayModal;
};

export const getViewSocialContent = (state) => {
  return state.app.viewSocialContent;
};

export const getOverlayModalData = (state) => {
  const { type = '', id = '' } = state.app.overlayModalData;
  if (type !== '' && id !== '') {
    switch (type) {
      case MODAL_OVERLAY_TYPE_CLIENT:
        const { id: clientID } = getClientByID(state, id);
        return {
          type,
          data: {
            client: getClientByID(state, id),
            projects: getProjectsByClientID(state, clientID),
          },
        };
        break;
      case MODAL_OVERLAY_TYPE_EMPLOYEE:
        return {
          type,
          data: getEmployeeByID(state, id),
        };
        break;
      default:
    }
  }

  return {};
};
