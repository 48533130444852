//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import const
import { URL_PAGE_PAGE_NOT_FOUND } from 'constants/app/AppUrls';

// import selectors
import { getProjectsByUrl } from 'store/projects/Projects.selectors';
import { getSiteMetaData } from 'store/app/App.selectors';

// import components
import ProjectBase from 'components/pages/project/Project.base';
import SiteMetaData from 'components/generic/SiteMetaData';
import PageNotFoundBase from 'components/page-not-found/PageNotFound.base';
import { trackPageview } from 'utils/tracking';

class PageProject extends Component {
  componentDidMount() {
    trackPageview({ title: this.props?.data?.details?.name || 'unknown case study name', path: this.props.location.pathname });
  }
  render() {
    const { data = false, clientData = false, metadata, isExact } = this.props;
    if (!data || !isExact) {
      return <PageNotFoundBase />;
    }
    const { content = {}, details = {} } = data;

    const { name = '' } = details;
    const { cta_image = '' } = content;

    const { description: original_meta_description = '', og_description = '', og_title = '', title: meta_title = '' } = metadata;
    const { meta = {} } = data;
    const { meta_description, meta_keywords } = meta;

    const base_meta_title = meta_title.replace('{title}', name);
    const base_meta_description = meta_description !== '' ? meta_description : original_meta_description.replace('{title}', name);
    const base_meta_keywords = meta_keywords;
    const base_og_title = og_title.replace('{title}', name);
    const base_og_description = meta_description !== '' ? meta_description : og_description.replace('{title}', name);

    return (
      <div className="project">
        <SiteMetaData title={base_meta_title} description={base_meta_description} keywords={base_meta_keywords} og_description={base_og_description} og_title={base_og_title} og_image={cta_image} />
        <div className="container">
          <ProjectBase data={data} client={clientData} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const urlFragment = ownProps.match.url || '';

  const { data, clientData } = getProjectsByUrl(state, urlFragment);

  return {
    data,
    clientData,
    metadata: getSiteMetaData(state, 'project'),
    isExact: ownProps.match.isExact,
  };
};

export default withRouter(connect(mapStateToProps, null)(PageProject));
