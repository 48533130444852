// ******
// About Page
// aka: 'who we are' page
// url /who-we-are
// ******
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { motion, useAnimation, AnimatePresence } from 'framer-motion';

// import helper functions
import { windowScrollTo } from 'utils/general';

// import scss
import './Pages.about.scss';

// import consts
import { PAGES } from 'store/pages/Pages.reducer';

// import selectors
import { getPageDataByName } from 'store/pages/Pages.selectors';
import { getSiteMetaData } from 'store/app/App.selectors';

// import components
import AboutHeader from 'components/pages/about/About.header';
import EmployeesList from 'components/employees/Employees.list';
import AboutTimeline from 'components/pages/about/About.timeline';
import ImageCollageBase from 'components/image-collage/ImageCollage.base';
import SiteMetaData from 'components/generic/SiteMetaData';
import Employee from 'components/employees/Employee';
// SVG components
import SvgPurpleLines from 'components/svg/patterns/Svg.purple_lines';
import SvgRedLinesRectangle from 'components/svg/patterns/Svg.red_lines_rectangle';
import SvgBlueLinesTriangle from 'components/svg/patterns/Svg.blue_lines_triangle';
import SvgLinesCircle from 'components/svg/patterns/Svg.lines_circle';

import { trackPageview } from 'utils/tracking';

const transition = { duration: 0.7, ease: 'easeInOut' };
const variants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition },
  exit: { opacity: 0, transition: { duration: 0.5, ease: 'easeInOut' } },
};

class PageAbout extends Component {
  constructor(props) {
    super(props);

    this.scrollTo = React.createRef();
    this.scrollToCallBack = this.scrollToCallBack.bind(this);
    this.updateEmployeeDisplay = this.updateEmployeeDisplay.bind(this);

    this.state = {
      toggleEmployee: false,
      activeEmployee: '',
      prevScrollPos: 0,
      pos: 0,
    };
  }

  componentDidMount() {
    trackPageview({ title: 'Who We Are', path: this.props.location.pathname });
    this.setState({ pos: this.scrollTo.current.getBoundingClientRect().top - 70 });
  }

  updateEmployeeDisplay(id) {
    if (id === '') {
      if (this.state.prevScrollPos >= this.state.pos) {
        setTimeout(() => {
          windowScrollTo(this.state.prevScrollPos);
        }, 500);
      }

      this.setState({ toggleEmployee: false, activeEmployee: id });
      return;
    }
    this.setState({ prevScrollPos: window.pageYOffset });

    this.scrollToCallBack();

    setTimeout(() => {
      this.setState({ activeEmployee: id, toggleEmployee: true });
    }, 500);
  }

  scrollToCallBack() {
    windowScrollTo(window.pageYOffset + this.scrollTo.current.getBoundingClientRect().top - 70);
  }

  render() {
    const { header, dna_sequence, image_collage } = this.props;
    const circleYellow = { clazz: 'svg-icon--yellow-lines-circle', clr: 'rgba(255,210,8,0.4)' };
    const circleGreen = { clazz: 'svg-icon--green-lines-circle', clr: 'rgba(106,176,99,0.4)' };
    const circleYellow2 = { clazz: 'svg-icon--yellow-lines-circle2', clr: 'rgba(255,210,8,0.4)' };
    return (
      <div className="about">
        <SiteMetaData {...this.props.metadata} />
        <AboutHeader data={header} scrollToCallBack={this.scrollToCallBack} />
        <div ref={this.scrollTo} className="about__employees">
          <div className="container" style={{ overflow: 'hidden' }}>
            <AnimatePresence>
              <motion.div className="" style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }} initial={{ x: 0 }} animate={{ x: !this.state.toggleEmployee ? 0 : '-100%', transition }}>
                <motion.div className="list" initial="initial" animate={{ opacity: !this.state.toggleEmployee ? 1 : 0, transition }} variants={variants} style={{ width: '100%', flexShrink: 0 }}>
                  <h2
                    onClick={() => {
                      this.setState({ toggleEmployee: true });
                    }}
                  >
                    Meet The Team
                  </h2>
                  <EmployeesList callback={this.updateEmployeeDisplay} />
                </motion.div>
                <motion.div className="person" initial="initial" animate={{ opacity: this.state.toggleEmployee ? 1 : 0, transition }} variants={variants} style={{ width: '100%', flexShrink: 0 }}>
                  <Employee id={this.state.activeEmployee} callback={this.updateEmployeeDisplay} />
                </motion.div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>

        <div className="about__timeline">
          <div className="about__timeline__container">
            <div className="container">
              <SvgLinesCircle {...circleYellow} />
              <SvgLinesCircle {...circleGreen} />
              <SvgRedLinesRectangle />
              <SvgPurpleLines />
              <div className="centered">
                <h3>Our DNA Sequence</h3>
              </div>
              <AboutTimeline data={dna_sequence} />
              <SvgBlueLinesTriangle />
              <SvgLinesCircle {...circleYellow2} />
            </div>
          </div>
        </div>

        <div className="about__image-collage">
          <ImageCollageBase />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const data = getPageDataByName(state, PAGES.WHO_WE_ARE);

  const { header = {}, dna_sequence = {} } = data;
  return {
    metadata: getSiteMetaData(state, 'about-us'),
    header,
    dna_sequence,
  };
};

export default withRouter(connect(mapStateToProps, null)(PageAbout));
