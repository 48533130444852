import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import consts
import { getUrlFrontendImage } from 'constants/app/AppUrls';

// import selectors
import { getFooterData } from 'store/app/App.selectors';

// import containers
import Nav from 'containers/sections/Sections.nav';

// import components
import SocialIconList from 'components/social-icons/SocialIcons.list';
import PictureMappings from 'components/picture-mappings/PictureMappings';
import SvgYellowLineLines from 'components/svg/patterns/Svg.yellow_line_lines';
import SvgBlueDots from 'components/svg/patterns/Svg.blue_dots.jsx';

class Footer extends Component {
  render() {
    const { memberships } = this.props;
    return (
      <footer>
        <div className="footer__svg-container">
          <SvgYellowLineLines />
          <SvgBlueDots />
        </div>
        <div className="container">
          <Nav viewType={'footer'} />
          <div className="logo">
            <img
              src={getUrlFrontendImage('base/logo.png')}
              alt="Jollywise"
              title="Jollywise"
              onClick={() => {
                window.open(window.location.origin, '_self');
              }}
            />
          </div>

          <div className="details">
            <div className="details-address">
              <h1>Jollywise</h1>
              <div>
                Level 8, New England House
                <br />
                New England Street
                <br />
                Brighton, BN1 4GH
              </div>
            </div>
            <div className="details-contact">
              <span>Telephone:</span> +44 (0)1273 669669
              <br />
              <span>Email:</span> workwithus@jollywise.co.uk
            </div>
          </div>
          <section className="footer__lower-section">
            <SocialIconList />
            <div className="copyright wysiwyg" dangerouslySetInnerHTML={{ __html: `&copy; Jollywise ${new Date().getFullYear()}. <span class="company-number">Company Number 5868152</span>` }} />
            {memberships.length > 0 && (
              <div className="memberships">
                <span className="title">Memberships</span>
                <ul className="memberships__list">
                  {memberships.map((member, index) => {
                    const { image, name, external_url = '', external_url_target = '_blank' } = member;
                    return (
                      <li className="memberships__image" key={index}>
                        {external_url && external_url !== '' ? (
                          <a href={external_url} target={external_url_target} rel="noopener noreferrer">
                            <PictureMappings src={image} alt={name} title={name} />
                          </a>
                        ) : (
                          <PictureMappings src={image} alt={name} title={name} />
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </section>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  const data = getFooterData(state);
  const { memberships = [] } = data;
  return {
    memberships,
  };
};

export default connect(mapStateToProps, null)(Footer);
