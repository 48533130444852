import { getProjectsByCategoryID } from 'store/projects/Projects.selectors';

export const getCategories = (state) => {
  const allIds = state.categories.allIDs;

  return allIds.map((id, index) => {
    const category = { ...state.categories.byID[id] };
    const categoryProjects = getProjectsByCategoryID(state, id);

    category.categoryProjects = categoryProjects;
    category.categoryProject_count = categoryProjects.length;

    return category;
  });
};

export const getCategoryByID = (state, id) => {
  if (state.categories.byID) {
    return state.categories.byID[id] || {};
  }
  return {};
};

export const getCategoryAllIDs = (state) => {
  return state.categories.allIDs;
};

export const getCategoryByUrl = (state, url) => {
  const ids = getCategoryAllIDs(state);
  let returnableData = [];

  if (ids.length > 0) {
    let i = 0;
    for (i; i < ids.length; i++) {
      const category = state.categories.byID[ids[i]];

      if (category.friendly_url === url) {
        return category;
      }
    }
  }

  return false;
};
