/* global __WATCH__:false */
// import platform from 'platform';

/**
 * using modified version of current-device in js/libs
 * Was causing issues with bitmovin
 *
 * walkOnChangeOrientationList
 *  for (var index in changeOrientationList) {}
 * -- In IE11, empty array was finding 'find'
 *  changeOrientationList['find'](newOrientation);
 * -- Bitmovin was catching this somehow and erroring
 */
//
import device from 'current-device.min';

export const getDevice = () => {
  return device.type === 'mobile' ? 'smartphone' : device.type;
};

export const getOrientation = () => {
  return device.orientation;
};

export const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  } else if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
    return 13;
  }
};

// export const getPlatform = (platforms) => {
//   let user_platform = platforms.other;
//   if (platform.manufacturer == 'Amazon') {
//     user_platform = platforms.amazon;
//   } else if (platform.os.family == 'Android') {
//     user_platform = isKindle() ? platforms.amazon : platforms.android;
//   } else if (platform.os.family == 'Windows Phone') {
//     user_platform = platforms.windowsPhone;
//   } else if (platform.manufacturer == 'Apple') {
//     user_platform = platforms.ios;
//   } else if (platform.os.family == 'BlackBerry' || platform.manufacturer == 'BlackBerry') {
//     user_platform = platforms.blackberry;
//   }
//   return user_platform;
// };

export const isiOS = () => {
  return !!navigator.platform.match(/iPhone|iPod|iPad/);
};

export const isOldDevice = () => {
  const isOld = isOldiPhone() || isOldiPad() || isKindle() || isOldAndroid();
  const testOldDevice = isOld;
  return __WATCH__ ? testOldDevice : isOld;
};

export const isOldAppleDevice = () => {
  return isOldiPhone() || isOldiPad();
};

export const isNewAppleDevice = () => {
  return isNewiPhone() || isNewiPad();
};

export const isOldiPhone = () => {
  const model = getiPhoneModel();
  return model === iPHONE1_3 || model === iPHONE4_4S || model === iPHONE5_5C_5S;
};

export const isNewiPhone = () => {
  const model = getiPhoneModel();
  return model === iPHONEX;
};

export const isOldiPad = () => {
  const model = getiPadModel();
  return model === IPAD1_2_MINI1;
};

export const isNewiPad = () => {
  const model = getiPadModel();
  return model === IPAD_PRO_10 || model === IPAD_PRO_12;
};

export const getiPhoneModel = () => {
  if (/iPhone/.test(navigator.userAgent) && !window.MSStream) {
    const width = window.screen.width > window.screen.height ? window.screen.height : window.screen.width;
    const height = window.screen.width > window.screen.height ? window.screen.width : window.screen.height;
    const wh = width / height;
    const ratio = window.devicePixelRatio || window.screen.deviceXDPI / window.screen.logicalXDPI;

    if (wh == 320 / 480 && ratio == 1) {
      return iPHONE1_3;
    }

    if (wh == 640 / 960 && ratio == 2) {
      return iPHONE4_4S;
    }

    if (wh == 640 / 1136 && ratio == 2) {
      return iPHONE5_5C_5S;
    }

    if (wh == 750 / 1334 && ratio == 2) {
      return iPHONE6_6S_7_8;
    }

    if (wh == 640 / 1136 && ratio == 2) {
      return iPHONE6_6S_7_8_DZ;
    }

    if (wh == 1242 / 2208 && ratio == 3) {
      return iPHONE6_6S_7P_8P;
    }

    if (wh == 1125 / 2001 && ratio == 3) {
      return iPHONE6_6S_7P_8P_DZ;
    }

    if (wh == 375 / 812 && ratio == 3) {
      return iPHONEX;
    }
    return '';
  } else {
    return '';
  }
};

export const getiPadModel = () => {
  // typeof window.androidBridge === 'undefined'
  if (/iPad/.test(navigator.userAgent) && !window.MSStream) {
    const width = window.screen.width > window.screen.height ? window.screen.height : window.screen.width;
    const height = window.screen.width > window.screen.height ? window.screen.width : window.screen.height;
    const wh = width / height;
    const ratio = window.devicePixelRatio || window.screen.deviceXDPI / window.screen.logicalXDPI;
    if (wh == 768 / 1024) {
      if (ratio == 1) {
        return IPAD1_2_MINI1;
      } else {
        return IPAD3_5_PRO_MINI2_4;
      }
    }
    if (wh == 834 / 1112) {
      return IPAD_PRO_10;
    }
    if (wh == 1024 / 1366) {
      return IPAD_PRO_12;
    }
  } else {
    return '';
  }
};

/**
 * detect if old Android
 */
export const isOldAndroid = () => {
  const isNexus7 = navigator.userAgent.indexOf('Nexus 7') > -1;
  const isGalaxyTab4 = navigator.userAgent.indexOf('SM-T530') > -1 || navigator.userAgent.indexOf('SM-T330') > -1 || navigator.userAgent.indexOf('GT-N8010') > -1;
  return isNexus7 || isGalaxyTab4;
};
/**
 * detect if kindle device
 */
export const isKindle = () => {
  for (let i = 0; i < KINDLE_DEVICES.length; i++) {
    if (navigator.userAgent.indexOf(KINDLE_DEVICES[i]) > -1) {
      return true;
    }
  }
  return false;
};

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }
  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }
  // const edge = ua.indexOf('Edge/');
  // if (edge > 0) {
  //     // Edge (IE 12+) => return version number
  //     return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  // }
  return false;
};

const KINDLE_DEVICES = [
  'KFSUWI',
  'KFAUWI',
  'KFDOWI',
  'KFGIWI',
  'KFFOWI',
  'KFMEWI',
  'KFTBWI',
  'KFARWI',
  'KFASWI',
  'KFSAWA',
  'KFSAWI',
  'KFAPWA',
  'KFAPWI',
  'KFAPWA',
  'KFAPWI',
  'KFSOWI',
  'KFJWA',
  'KFJWI',
  'KFTT',
  'KFOT',
  'Kindle Fire',
];

const iPHONE1_3 = 'iPhone 1/3g/3gs';
const iPHONE4_4S = 'iPhone 4/4s';
const iPHONE5_5C_5S = 'iPhone 5/5c/5s';
const iPHONE6_6S_7_8 = 'iPhone 6/6s/7/8';
const iPHONE6_6S_7_8_DZ = 'iPhone 6/6s/7/8 (Display Zoom)';
const iPHONE6_6S_7P_8P = 'iPhone 6/6s/7 Plus/8 Plus';
const iPHONE6_6S_7P_8P_DZ = 'iPhone 6/6s/7 Plus/8 Plus (Display Zoom)';
const iPHONEX = 'iPhone X';

const IPAD1_2_MINI1 = 'iPad 1/2 | Mini 1';
const IPAD3_5_PRO_MINI2_4 = 'iPad 3-5, iPad Air 1-2, iPad Mini 2-4, iPad Pro 9.7';
const IPAD_PRO_10 = 'iPad Pro 10.5';
const IPAD_PRO_12 = 'iPad Pro 12.9, Pro 12.9 (2nd Gen)';
