import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const random = Math.random() * (1 - 0) + 0;
    const delay = 0.5 + random * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.1 },
      },
    };
  },
};

const SvgSwirls = ({ clazz = 'svg-icon--swirls', clr = '#fa91a3' }) => {
  const controls = useAnimation();
  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <motion.svg className={clazz} xmlns="http://www.w3.org/2000/svg" width="565.121" height="570.04" viewBox="0 0 565.121 570.04" ref={ref} variants={draw} initial="hidden" animate={controls}>
      <g id="Group_838" data-name="Group 838" transform="translate(774.881 1630.017) rotate(-143)">
        <motion.path
          variants={draw}
          id="Path_1474"
          data-name="Path 1474"
          d="M899.482,630.153c1.317-1.222-1.6-3.116-2.828-1.8s-.743,3.412-.183,5.12"
          transform="translate(0 53.844)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1475"
          data-name="Path 1475"
          d="M907.682,630.654c.714,1.341,3.228.723,3.243-.792s-2.493-2.177-3.228-.848"
          transform="translate(17.031 54.411)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1476"
          data-name="Path 1476"
          d="M906.855,641.617c-.371,1.948-3.629-1.127-1.706-1.608"
          transform="translate(12.503 71.321)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1477"
          data-name="Path 1477"
          d="M919.5,638.1l-2.336,2.336"
          transform="translate(30.719 68.559)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1478"
          data-name="Path 1478"
          d="M911.5,652.1l-2.336,2.336"
          transform="translate(19.168 88.773)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1479"
          data-name="Path 1479"
          d="M915.41,619.2c-.767-1.386,2.81-.743,1.608.291"
          transform="translate(28.033 40.125)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1480"
          data-name="Path 1480"
          d="M916.988,606.871v-2.339"
          transform="translate(30.468 20.095)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1481"
          data-name="Path 1481"
          d="M900.988,617.871v-2.339"
          transform="translate(7.365 35.978)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1482"
          data-name="Path 1482"
          d="M936.957,598.706a9.259,9.259,0,0,0-6.349,5.968c-.379-2.087-3.791-2.644-4.814-.789"
          transform="translate(43.182 11.683)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1483"
          data-name="Path 1483"
          d="M932.877,614.17c-3.179,1.96-3.39,7.288-.374,9.487,3.148-.538,6.579-2.632,6.6-5.824s-5.838-4.487-6.1-1.308"
          transform="translate(49.779 34.011)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1484"
          data-name="Path 1484"
          d="M924.644,633.106a1.63,1.63,0,1,0-1.63-1.982"
          transform="translate(39.169 56.771)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1485"
          data-name="Path 1485"
          d="M900.644,671.106a1.63,1.63,0,1,0-1.63-1.982"
          transform="translate(4.515 111.64)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1486"
          data-name="Path 1486"
          d="M909.644,668.106a1.63,1.63,0,1,0-1.63-1.982"
          transform="translate(17.51 107.308)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1487"
          data-name="Path 1487"
          d="M926.916,646.063a4.063,4.063,0,0,0-.65,6.687l.694-7.08"
          transform="translate(41.749 79.495)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1488"
          data-name="Path 1488"
          d="M920.813,660.007l2.339,2.339"
          transform="translate(35.991 100.196)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1489"
          data-name="Path 1489"
          d="M905.933,698.266H903.6"
          transform="translate(11.132 155.439)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1490"
          data-name="Path 1490"
          d="M909.933,682.266H907.6"
          transform="translate(16.908 132.336)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1491"
          data-name="Path 1491"
          d="M937,662.1c-2.957.369-3.126,5.687-.2,6.24-2.058-1.787-5.626,1.786-3.839,3.844"
          transform="translate(52.843 103.226)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1492"
          data-name="Path 1492"
          d="M947.055,651.927c-.533.995,1.7,1.325,1.95.225s-.755-2.05-1.65-2.735a9.838,9.838,0,0,0-7.344,4.942"
          transform="translate(63.713 84.905)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1493"
          data-name="Path 1493"
          d="M947.32,627.4a5.8,5.8,0,0,0-1.686,7.144l9.03-.247c-4.145-6.667-16.379-4.8-18.351,2.793"
          transform="translate(58.372 53.112)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1494"
          data-name="Path 1494"
          d="M954.9,640.627a10.214,10.214,0,0,0-5.4,6.76c3.732.381,7.913-2.747,7.273-6.442s-7.454-4.1-7.649-.354"
          transform="translate(76.865 68.376)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1495"
          data-name="Path 1495"
          d="M952.873,663.7a5.673,5.673,0,1,0,11.3.78c-.841-4.243-9.008-2.28-7.828,1.884"
          transform="translate(82.224 103.235)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1496"
          data-name="Path 1496"
          d="M937.325,675.64c-2.429,2.517,2.31,6.589,5.4,4.947s3.915-5.611,4.4-9.077"
          transform="translate(58.867 116.806)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1497"
          data-name="Path 1497"
          d="M964.123,684.8l-6.941.423a8.393,8.393,0,0,0,16.643.027,8.238,8.238,0,0,0-7.07,2.168"
          transform="translate(88.505 136.002)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1498"
          data-name="Path 1498"
          d="M987.6,690.682c-4.719,7.833,6.139,18.975,14.091,14.456"
          transform="translate(130.767 144.488)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1499"
          data-name="Path 1499"
          d="M1013.2,719.728c5.78,2.881,13.764-2.312,13.473-8.764-7.129-.3-13.271,7.672-11.161,14.49a9.392,9.392,0,1,0-16.022-9.614"
          transform="translate(149.586 173.189)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1500"
          data-name="Path 1500"
          d="M972.547,694.455a9,9,0,0,0,2.331,7.19,5.449,5.449,0,0,0,1.938-7.065"
          transform="translate(110.592 149.936)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1501"
          data-name="Path 1501"
          d="M983.6,668.635a51.039,51.039,0,0,0-2.229,15.6,21.4,21.4,0,0,0,12.1-7.1A8.227,8.227,0,0,0,980.8,687.543"
          transform="translate(120.62 112.654)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1502"
          data-name="Path 1502"
          d="M967.473,669.972c-3.708,1.933-3.228,8.5.723,9.876a12.281,12.281,0,0,0,.987-8.471"
          transform="translate(99.713 114.585)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1503"
          data-name="Path 1503"
          d="M978.209,651.771a14.666,14.666,0,0,0-4.353,9.492c4.377-.983,8.964-3.192,10.609-7.364s-1.818-10.032-6.19-9.038c-1.813.41-3.219,1.8-4.519,3.135-2.62,2.686-5.33,5.518-6.415,9.109s.054,8.155,3.475,9.7"
          transform="translate(102.601 78.167)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1504"
          data-name="Path 1504"
          d="M958.13,629.968c-3.732,1.012-3.651,7.195-.254,9.035s7.847-.034,10.154-3.133,3-7.075,3.632-10.89c-6.24-2.146-13.363,5.166-11.059,11.347a5.127,5.127,0,1,0-7.2-7.193"
          transform="translate(83.042 49.068)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1505"
          data-name="Path 1505"
          d="M974.945,618.727c-5.731,1.092-3.807,11.482,2.016,11.828s9.979-6.6,8.832-12.317a19.644,19.644,0,0,0-21.281-2.549"
          transform="translate(99.089 33.277)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1506"
          data-name="Path 1506"
          d="M984.141,626.818c-5.115,3.656-3.725,13.317,2.368,14.876s11.826-7.266,7.569-11.895-13.769,1.466-10.963,7.095"
          transform="translate(122.903 52.274)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1507"
          data-name="Path 1507"
          d="M995.866,643.707c-2.524,4.575-8.158,6.293-12.115,9.707s-4.968,11.467.122,12.645a8.343,8.343,0,0,0,4.7-.694c3.461-1.29,6.943-3.111,8.93-6.222s1.9-7.8-1.051-10.023-8.319.093-7.865,3.759"
          transform="translate(122.046 76.66)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1508"
          data-name="Path 1508"
          d="M1000.808,659.039c-5.335,2.894-1.833,12.149,4.113,13.373s11.687-2.432,16.66-5.912a13.711,13.711,0,0,0-13.454,4.548"
          transform="translate(147.702 98.798)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1509"
          data-name="Path 1509"
          d="M1005.088,672.452c-3.59,3.5-1.2,10.149,3.216,12.52s9.781,1.765,14.754,1.095a10.068,10.068,0,0,0-8.375,7.759c4.013,2.378,9.58-3.407,7.051-7.327-2.994.909-6.327,2.1-7.63,4.944s1.75,7.085,4.436,5.482c-.1-5.33-9.707-6.325-10.895-1.127"
          transform="translate(155.158 118.166)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1510"
          data-name="Path 1510"
          d="M1002.189,699.248c6.628,3.717,16.171.34,18.989-6.716a12,12,0,0,0-11.968,9.722c2.669,4.548,10.572,4.313,12.97-.384a9.932,9.932,0,0,0-8.473-2.29"
          transform="translate(153.491 147.145)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1511"
          data-name="Path 1511"
          d="M1010.026,621.791c-1.569,6.381-2.468,14.593,3.065,18.139,4.888,3.133,12.322-.89,12.962-6.657s-4.907-11.174-10.712-11.042-10.885,5.11-11.87,10.829"
          transform="translate(155.343 45.015)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1512"
          data-name="Path 1512"
          d="M1004.7,646.047c-3.856,2.977-1.762,10,2.83,11.631s9.805-.982,12.789-4.837c1.3-1.674,2.329-3.786,1.872-5.853-.836-3.8-6.3-4.807-9.558-2.691s-4.792,6.022-6.132,9.673"
          transform="translate(154.208 75.941)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1513"
          data-name="Path 1513"
          d="M945.554,599.134c-2.659.044-3.006,4.612-.606,5.755s5.345-.6,6.572-2.957c1.769-3.4.259-8.495-3.483-9.348s-7.219,4.8-4.208,7.183"
          transform="translate(67.574 2.718)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1514"
          data-name="Path 1514"
          d="M951.614,610.958c1.645.726-.442,3.368-2.148,2.8s-2.671-2.39-4.228-3.289-4.343.452-3.4,1.984"
          transform="translate(66.073 28.242)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1515"
          data-name="Path 1515"
          d="M979.4,596.187c-2.329.467-3.038,4.184-1.043,5.477s5.1-.875,4.57-3.192a14.123,14.123,0,0,0-14.343,9.155c4.983,3.414,12.982.34,14.4-5.531a25.611,25.611,0,0,0-15.289-1.681"
          transform="translate(103.686 8.046)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1516"
          data-name="Path 1516"
          d="M1019.821,609.544a50.611,50.611,0,0,0-8.976,16.081c5.67.249,8.735-8.011,5.308-12.537s-10.377-5-15.524-2.605-8.915,6.936-12.5,11.335c4.792,1.567,10.523-2.466,10.665-7.505"
          transform="translate(133.189 26.637)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1517"
          data-name="Path 1517"
          d="M1026.21,604.775a33.844,33.844,0,0,0-6.34,12.371,8.787,8.787,0,0,0-.269,4.81c1.41,4.656,8.234,4.7,12.466,2.3,5.365-3.04,9.455-8.974,8.764-15.1s-7.369-11.335-13.163-9.231-6.64,12.09-.845,14.192c4.575,2.251,10.86-1.755,10.758-6.85a9.043,9.043,0,0,0-4.1,9.25"
          transform="translate(178.292 12.752)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1518"
          data-name="Path 1518"
          d="M1024.538,628.781c-7.021,3.138-6.132,15.333,1.068,18.041s15.717-4.656,14.8-12.293a12.337,12.337,0,0,0-4.257-7.881,12.784,12.784,0,0,0-5.406,11.379"
          transform="translate(178.796 52.029)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1519"
          data-name="Path 1519"
          d="M1024.272,651.648c-4.873,5.425-6.337,14.057-2.461,20.233s13.356,8.393,18.96,3.727,4.3-15.46-2.6-17.826-14.595,6.972-10.311,12.875c7.429,2.5,16.335-4.138,16.061-11.973a15.9,15.9,0,0,0-13.141,15.947"
          transform="translate(178.629 88.126)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1520"
          data-name="Path 1520"
          d="M1050.382,619.771c-5.127,4.338-9.827,12.215-5.279,17.156a9.536,9.536,0,0,0,6.679,2.693c5.961.249,12.1-3.6,13.686-9.355s-2.354-12.652-8.261-13.493-11.8,5.609-9.809,11.232,11.56,6.34,13.852.833"
          transform="translate(212.8 37.661)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1521"
          data-name="Path 1521"
          d="M1048.5,603.512c-4.5,2.344-1.931,10.428,3.118,10.985s9.37-4.416,9.9-9.468-1.7-9.954-4.13-14.414c-4.179,3.292-8.075,7.4-9.372,12.562s.841,11.472,5.8,13.407c6.058,2.363,13.28-3.952,12.413-10.4s-8.5-10.724-14.72-8.82-10.14,8.876-9.382,15.336"
          transform="translate(211.022)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1522"
          data-name="Path 1522"
          d="M1046.618,639.7a8.111,8.111,0,0,0-3.113,8.368c6.62-1.408,10.306-10.274,6.633-15.961-9.622,3.666-14.991,15.822-11.22,25.4a22.7,22.7,0,0,0,12.662-17.77,8.416,8.416,0,0,0-9.419,4.543"
          transform="translate(204.771 59.914)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1523"
          data-name="Path 1523"
          d="M1080.545,632.25a57.067,57.067,0,0,0-21.976,14.013c-3.663,3.764-6.09,11.249-1.215,13.2a7.532,7.532,0,0,0,4.248.1,28.284,28.284,0,0,0,21.472-20.521c-7.359-3.871-15.025,4.595-18.716,12.046-1.307,2.639-2.207,6.545.394,7.931,1.6.858,3.558.068,5.188-.743,4.521-2.246,9.226-4.9,11.565-9.373s.968-11.2-3.849-12.708-9.692,6.166-5.46,8.915"
          transform="translate(229.199 60.117)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1524"
          data-name="Path 1524"
          d="M1077.5,607.466c-9.206,1.943-13.662,12.2-16.682,21.106-.621,1.833-1.234,3.883-.433,5.646,1.723,3.79,7.515,2.429,10.81-.115,6.1-4.7,10.494-14,5.516-19.871-5.174-6.107-17.449.039-15.661,7.84s15.516,7.982,17.511.23l-1.075,10.721"
          transform="translate(236.965 24.331)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1525"
          data-name="Path 1525"
          d="M1060.9,664.848a30.393,30.393,0,0,0-13.642,16.486c11.667,2.852,24.918-3.991,29.349-15.155-3.812-.882-8.234.166-10.606,3.275s-1.865,8.29,1.5,10.279c4.477,2.647,10.707-2.652,9.609-7.735s-7.593-7.742-12.417-5.8-7.657,7.312-7.774,12.513"
          transform="translate(218.565 107.186)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1526"
          data-name="Path 1526"
          d="M1054.276,648.82c-3.942,3.292-8.043,6.806-9.839,11.616s-.283,11.266,4.5,13.146a9.447,9.447,0,0,0,5.569.256c9.553-2.224,12.388-17.357,4.287-22.887s-21.164,2.622-19.754,12.33a21.792,21.792,0,0,0,23.281-13.994,17.046,17.046,0,0,0-12.361,12.945"
          transform="translate(206.543 84.043)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1527"
          data-name="Path 1527"
          d="M1041.417,681.4c-5.017-.467-11.408-.156-13.3,4.514-2.4,5.919,6.594,11.122,12.266,8.194s7.974-9.7,9.763-15.832c-5.5-3.615-13.185-2.241-18.432,1.728s-8.495,10.01-11.066,16.066c-.726,1.711-1.408,3.7-.577,5.36,1.239,2.48,4.788,2.437,7.452,1.676a32.079,32.079,0,0,0,13.6-7.9c4.763-4.663,6.494-14.805,0-16.3-5.6-1.288-9.067,5.822-10.33,11.423"
          transform="translate(178.763 123.549)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1528"
          data-name="Path 1528"
          d="M1057.632,685.165A38.618,38.618,0,0,0,1043,694.813c5.689,7.777,19.326,7.447,24.632-.6a7.226,7.226,0,0,0,1.417-5.049c-.574-3.375-4.763-5.081-8.089-4.269s-5.917,3.338-8.334,5.763c-4,4.015-8.341,9.482-6.227,14.744,1.85,4.6,8.05,6.083,12.706,4.377s8.065-5.658,11.178-9.517c1.219-1.51,2.473-3.138,2.71-5.066.579-4.709-5.565-7.9-10.074-6.418s-7.447,5.714-10.057,9.678"
          transform="translate(212.416 135.845)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1529"
          data-name="Path 1529"
          d="M1073.693,712.275c-4.348,3.038-8.859,6.257-11.308,10.961s-2.055,11.35,2.3,14.375c4.472,3.1,11.024.936,14.514-3.246s4.688-9.756,5.775-15.091c.453-2.209.828-4.756-.594-6.508-2.689-3.314-7.933-.34-10.966,2.664-4.328,4.292-8.849,8.915-10.223,14.857s1.955,13.388,8.014,14.057c2.818.31,5.577-.855,8.109-2.134,4.238-2.141,8.483-4.883,10.607-9.128s1.234-10.3-2.977-12.5-10.467,2.415-8.541,6.752"
          transform="translate(238.239 174.274)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1530"
          data-name="Path 1530"
          d="M1077.462,686.052c-4.912,5-10.008,10.313-11.833,17.078s.985,15.326,7.686,17.369c3.793,1.156,7.928.022,11.474-1.752a33.871,33.871,0,0,0,18.283-30.033,6.537,6.537,0,0,0-1.173-4.267c-1.52-1.755-4.233-1.633-6.532-1.325-5.4.719-11.12,1.816-15.033,5.6s-4.758,11.149-.315,14.294,11.971-3.162,8.505-7.359l-11.2,13.2"
          transform="translate(244.306 133.307)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1531"
          data-name="Path 1531"
          d="M1076.39,667.456a62.966,62.966,0,0,0-6.618,15.477c-.829,2.991-.978,7.036,1.85,8.317,2.747,1.246,5.626-1.325,7.547-3.651,3.954-4.79,8.033-9.974,8.761-16.144s-3.495-13.332-9.707-13.327c-7.958.007-11.269,10.042-12.3,17.931-.34,2.583-.455,5.719,1.669,7.227a6.143,6.143,0,0,0,4.375.7,19.747,19.747,0,0,0,16.624-18.735,27.014,27.014,0,0,0-9.82,13.894"
          transform="translate(245.237 97.483)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1532"
          data-name="Path 1532"
          d="M1084.865,636.111a62.9,62.9,0,0,0-11.03,19.341c-.929,2.627-1.667,5.594-.516,8.133,1.831,4.03,7.681,4.709,11.518,2.5s6.12-6.3,8.216-10.2c2.671-4.973,3.955-13.268-1.581-14.363-2.107-.418-4.214.692-5.919,1.994a24.181,24.181,0,0,0-9.272,20.771"
          transform="translate(255.35 65.692)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1533"
          data-name="Path 1533"
          d="M1128.12,610.612a97.314,97.314,0,0,0-21.355,25.4c-1.212,2.082-2.385,4.48-1.776,6.811,1.313,5.044,8.61,4.678,13.488,2.842,8.226-3.1,16.638-7.645,20.5-15.541s.555-19.585-8.043-21.411c-3.194-.677-6.523.064-9.6,1.146a44.893,44.893,0,0,0-27.658,28.977,10.27,10.27,0,0,0-.4,6.088c.821,2.5,3.206,4.115,5.548,5.308,17.946,9.145,42.377,2.282,52.938-14.869,1.486-2.415,2.752-5.242,2.123-8.006-.862-3.8-4.907-5.939-8.676-6.943-18.068-4.812-38.883,7.324-43.595,25.419"
          transform="translate(284.562 25.758)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1534"
          data-name="Path 1534"
          d="M1114.33,652.821c-5.929,1.176-12.256,2.559-16.43,6.931s-4.552,12.784.77,15.646a12,12,0,0,0,7.082.9c7.337-.846,14.654-4.758,17.965-11.362s1.4-15.854-5.1-19.368c-9.3-5.034-20.365,3.517-25.544,12.738-1.427,2.542-2.708,5.494-1.9,8.3,1.161,4.02,5.9,5.677,10.028,6.357a70.1,70.1,0,0,0,38.76-4.724c2.784-1.193,5.623-2.688,7.244-5.247,4.14-6.545-2.987-15.485-10.707-16.1s-14.776,4-21.14,8.414"
          transform="translate(281.615 77.192)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1535"
          data-name="Path 1535"
          d="M1127.979,644.876c-2.251,3.522-4.558,7.2-5.074,11.347s1.327,8.906,5.24,10.372c2.661,1,5.636.31,8.344-.547,6.748-2.143,13.444-5.54,17.513-11.333s4.719-14.412-.032-19.661c-5.763-6.369-16.218-5.179-23.941-1.418-5.465,2.659-11,7.444-10.567,13.508.352,4.991,5.076,8.984,10.057,9.446s9.905-2.07,13.3-5.746c3.272-3.541,5.274-9.206,2.449-13.114s-11.034-1.369-9.587,3.231"
          transform="translate(322.789 57.658)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1536"
          data-name="Path 1536"
          d="M1125.388,671.4c-4.59-1.627-9.8,1.186-12.51,5.233s-3.495,9.04-4.218,13.857c-.391,2.61-.772,5.333.044,7.842,2.121,6.525,11.521,7.95,17.32,4.282s8.74-10.428,11.162-16.848c.955-2.532,1.9-5.23,1.407-7.891-1.229-6.7-10.985-8.466-16.548-4.538s-7.84,10.951-9.776,17.479c-1.144,3.859-2.18,8.4.245,11.611,1.554,2.063,4.177,3,6.7,3.558a36.8,36.8,0,0,0,29.742-6.5c2.989-2.266,5.741-5.208,6.506-8.879,1.73-8.331-8.163-15.294-16.589-14.119s-15.108,7.452-21.176,13.417c-4.345,4.27-8.067,12.8-2.427,15.106"
          transform="translate(306.591 115.962)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1537"
          data-name="Path 1537"
          d="M1090.529,682.619c-2.967,3.881-6.029,7.977-6.806,12.8s1.6,10.531,6.332,11.745c4.426,1.136,8.781-1.9,12.1-5.044,5.321-5.039,10.15-11.367,10.521-18.686S1107.091,668.225,1099.8,669c-6.665.709-10.99,8.874-8.615,15.14s10.01,9.629,16.533,8.089,11.591-7.222,13.473-13.654c-11.863-5.914-28.313,2.3-30.713,15.336"
          transform="translate(271.009 113.101)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1538"
          data-name="Path 1538"
          d="M1119.473,708.246c-3.646-.684-7.515-1.349-10.975-.015s-6.11,5.491-4.468,8.818c1.2,2.429,4.076,3.47,6.721,4.054a48.181,48.181,0,0,0,44.179-13.3c1.8-1.86,3.543-4.551,2.366-6.855-.9-1.774-3.123-2.373-5.1-2.591a31.291,31.291,0,0,0-31.018,17.618c12.417,7.371,29.454,5.914,40.442-3.453a5.922,5.922,0,0,0,2.417-3.6c.3-2.928-3.184-4.663-6.075-5.206a42.786,42.786,0,0,0-33.841,8.256"
          transform="translate(299.82 155.325)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1539"
          data-name="Path 1539"
          d="M1095.949,701.82c-4.88,5.682-10.049,13.427-6.518,20.033,3.023,5.66,11.222,6.652,16.966,3.793s9.592-8.4,13.187-13.713c1.256-1.852,2.568-4.015,2.006-6.183-.489-1.884-2.26-3.131-4-4.008-13.789-6.97-32.934,1.276-37.348,16.081-.489,1.647-.787,3.539.127,4.99,1.009,1.606,3.109,2.082,5,2.253a33.352,33.352,0,0,0,28.6-12.276c1.266-1.6,2.419-3.778,1.457-5.574-.816-1.523-2.771-1.982-4.5-2.048-9.578-.364-18.74,7.561-19.756,17.093"
          transform="translate(265.523 156.833)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1540"
          data-name="Path 1540"
          d="M1109.911,731.309a61.768,61.768,0,0,0-31.2,22.315c-1.618,2.212-3.045,5.443-1.195,7.464,1.527,1.669,4.223,1.246,6.383.582a66.321,66.321,0,0,0,19.067-9.451c4.617-3.307,9.136-7.906,9.182-13.586.078-9.788-13.806-14.368-22.3-9.494s-12.44,14.805-15.793,24c-.691,1.9-1.374,4.067-.454,5.865.97,1.9,3.312,2.586,5.42,2.906,15.685,2.385,32.446-5.777,40.237-19.6-10.316-6.472-25.715-2.478-31.587,8.192a5.7,5.7,0,0,0,5.878,4.064"
          transform="translate(255.965 197.18)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
        <motion.path
          variants={draw}
          id="Path_1541"
          data-name="Path 1541"
          d="M1135.773,726.356a29.212,29.212,0,0,0-19.488,16.78,48.1,48.1,0,0,0,39.349-23.43,66.833,66.833,0,0,0-58.224,28.672,67.93,67.93,0,0,0,58.5-14.529c1.838-1.584,3.741-4.054,2.583-6.185-.863-1.591-2.969-1.955-4.78-1.953a27.607,27.607,0,0,0-25.485,18.412c11.711,5.589,27.052,2.1,35.195-8a29.5,29.5,0,0,0-29.838,11.015"
          transform="translate(290.983 186.235)"
          fill="none"
          stroke={clr}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="10"
        />
      </g>
    </motion.svg>
  );
};

export default SvgSwirls;
