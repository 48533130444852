import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFoundBase = ({ data }) => {
  return (
    <div className="page-not-found container">
      <h1>404</h1>
      <h2>Oops! Page not found</h2>
      <button className="page-not-found__back-button" aria-label="click to navigate to the homepage">
        <Link to={'/'}>Back to Homepage</Link>
      </button>
    </div>
  );
};

export default PageNotFoundBase;
