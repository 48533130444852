import React, { useRef, useState } from 'react';
import { postSendEmail } from 'services/api';

const ContactForm = () => {
  // local state managemnet
  const [errors, updateErrors] = useState({
    firstname: false,
    lastname: false,
    email: false,
    message: false,
  });
  const [successMessage, updateSuccessMessage] = useState(false);
  const [failMessage, updateFailMessage] = useState(false);
  const [pendingMessage, updatePendingMessage] = useState(false);

  // refs
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);

  // adds simple honeypot
  // uses 'tel' field for this form
  const honeypotRef = useRef(null);

  // Functions
  // reset form
  const resetForm = () => {
    updateErrors({
      firstname: false,
      lastname: false,
      email: false,
      message: false,
    });

    updateSuccessMessage(false);
    updateFailMessage(false);
  };

  //  fomr error checking
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  const errorChecking = () => {
    let data = { ...errors };

    // check firstname
    if (firstNameRef.current.value == '') {
      data.firstname = true;
      updateErrors(data);
      return false;
    } else {
      data.firstname = false;
      updateErrors(data);
    }

    // check lastname
    // Not required but leaving in
    // if (lastNameRef.current.value == '') {
    //   data.lastname = true;
    //   updateErrors(data);
    //   return false;
    // } else {
    //   data.lastname = false;
    //   updateErrors(data);
    // }

    // check email
    if (!validateEmail(emailRef.current.value)) {
      data.email = true;
      updateErrors(data);
      return false;
    } else {
      data.email = false;
      updateErrors(data);
    }

    // check message
    if (messageRef.current.value == '') {
      data.message = true;
      updateErrors(data);
      return false;
    } else {
      data.message = false;
      updateErrors(data);
    }

    return true;
  };

  // On submit handle
  const handleSubmit = (event) => {
    event.preventDefault();

    // check the simple honeypot
    if (honeypotRef.current && honeypotRef.current.value !== '') {
      // if the honeypot field has a value
      // breakout as likeihood is a bot.
      updateSuccessMessage(true);
      updateFailMessage(false);

      return;
    }

    // error checking
    if (errorChecking()) {
      updatePendingMessage(true);

      postSendEmail({
        firstname: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        email: emailRef.current.value,
        message: messageRef.current.value,
      }).then((response) => {
        if (response.result === 'SUCCESS') {
          updatePendingMessage(false);
          updateSuccessMessage(true);
          updateFailMessage(false);
        } else {
          updatePendingMessage(false);
          updateSuccessMessage(false);
          updateFailMessage(true);
        }
      });
    }
  };

  return (
    <div className="container-form">
      <div className="contact-form">
        <h2>Contact us</h2>
        {successMessage && !failMessage && <div className="contact-form__success">Thank you for your email!</div>}
        {!successMessage && failMessage && (
          <div className="contact-form__fail">
            Something went wrong with sending the form. Please try again!
            <div className="submit-button">
              <button onClick={resetForm}>Restart</button>
            </div>
          </div>
        )}
        {!successMessage && !failMessage && (
          <form onSubmit={handleSubmit} className="contact-form__form">
            <div className="firstname">
              <label htmlFor="firstName" id="nameLabel">
                First Name
              </label>
              <input type="text" id="firstName" name="firstName" placeholder="Enter your first name" ref={firstNameRef} tabIndex="1" required />
              {errors.firstname && <span className="form-error">Please enter a firstname</span>}
            </div>
            <div className="lastname">
              <label htmlFor="lastName">Last name</label>
              <input type="text" id="lastName" placeholder="Enter your last name" ref={lastNameRef} tabIndex="2" />
              {errors.lastname && <span className="form-error">Please enter a lastname</span>}
            </div>
            <div className="email">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="email" placeholder="Enter your email" ref={emailRef} tabIndex="3" required />
              {errors.email && <span className="form-error">Please enter a valid email</span>}
            </div>
            <div className="tel">
              <label htmlFor="tel"></label>
              <input type="tel" name="tel" id="tel" placeholder="" ref={honeypotRef} autoComplete="off" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />
            </div>
            <div className="message">
              <label htmlFor="message">Message</label>
              <textarea name="message" placeholder="Start writing your message. We'd love to hear from you!" ref={messageRef} tabIndex="4" required></textarea>
              {errors.message && <span className="form-error">Please enter a message</span>}
            </div>
            <div className="submit-button">
              {!pendingMessage ? (
                <button type="submit" className="send">
                  Submit
                </button>
              ) : (
                <span className="sending">Sending...</span>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
