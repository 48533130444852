import React, { Component } from 'react';
import { connect } from 'react-redux';

// import scss
import './Sections.nav.scss';

// import actions
import { activeMobileNav } from 'store/app/App.actions';

// import selectors
import { getSiteNavigation } from 'store/app/App.selectors';
import { getActiveMobileNavState } from 'store/app/App.selectors';

// import components
import NavigationBase from 'components/navigation/Navigation.base';

class Nav extends Component {
  constructor(props) {
    super(props);

    this.triggerMobileNav = this.triggerMobileNav.bind(this);
  }

  triggerMobileNav() {
    if (this.props.viewType !== 'footer') {
      if (window.innerWidth < 768) {
        const { activeNav } = this.props;
        this.props.activeNav();
      }
    }
  }

  render() {
    const { navigation, viewType = 'header', isMobileNavActive = false } = this.props;
    return (
      <nav className={`navigation navigation--${viewType} navigation--${isMobileNavActive ? 'open' : 'close'}`}>
        <NavigationBase navigation={navigation} activeNav={this.triggerMobileNav} />
      </nav>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    activeNav: () => {
      dispatch(activeMobileNav());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    navigation: ownProps.viewType === 'footer' ? footerNavigation : getSiteNavigation(state),
    isMobileNavActive: getActiveMobileNavState(state),
  };
};

const footerNavigation = {
  home: {
    classFriendlyName: 'home',
    id: `nav_1`,
    name: 'Home',
    type: 'internal_page',
    url: '',
  },
  contact: {
    classFriendlyName: 'contact',
    id: `nav_2`,
    name: 'Contact',
    type: 'internal_page',
    url: 'contact',
  },
  privacy: {
    classFriendlyName: 'privacy_policy',
    id: `nav_3`,
    name: 'Privacy Policy',
    type: 'internal_page',
    url: 'privacy-policy',
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
