import React from 'react';
import { connect } from 'react-redux';

// import selectors
import { getHomepageShowcaseContent } from 'store/pages/Pages.selectors';

// import components
import CreativeServicesHeader from 'components/creative-services/CreativeServices.header';
import CreativeServicesBody from 'components/creative-services/CreativeServices.body';
import BlogRelatedGrid from 'components/blog/Blog.related_grid';

const CreativeServicesBase = ({ data, related_data }) => {
  return (
    <>
      <article className="creative-services__base">
        <CreativeServicesHeader />
        <CreativeServicesBody data={data} />
      </article>
      <div className="related_blog_container container">
        <BlogRelatedGrid related_data={related_data} />
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    related_data: getHomepageShowcaseContent(state),
  };
};

export default connect(mapStateToProps, null)(CreativeServicesBase);
