import React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';

import { getEmployeeByID } from 'store/employees/Employees.selectors';

import SvgClose from 'components/svg/generic/Svg.close';

// framer const
const employee_image = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      delay: 0.4,
    },
  },
};
const employee_details = {
  hidden: { y: 60, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      duration: 1.2,
      delay: 0.6,
    },
  },
};

// import components
const ModalLayoutEmployee = (props) => {
  const { details = {}, content = {} } = props.data;
  const { job_title = '', name = '' } = details;
  const { cta_image = '', description = '', image = '' } = content;

  return (
    <div className="employee">
      <button
        className="employee__close"
        aria-label="close overlay"
        onClick={() => {
          props.callback('');
        }}
      >
        <SvgClose />
      </button>
      <motion.figure className="employee__image" key="image" initial={employee_image.hidden} animate={{ ...employee_image.visible, opacity: props.id === '' ? 0 : 1, scale: props.id === '' ? 0 : 1 }}>
        <img src={image} alt={name} title={name} />
      </motion.figure>
      <motion.div key="body" initial={employee_details.hidden} animate={{ ...employee_image.visible, y: props.id === '' ? 60 : 0, opacity: props.id === '' ? 0 : 1 }}>
        <div className="employee__name">{name}</div>
        <div className="employee__job-title">{job_title}</div>
        <div className="employee__description wysiwyg" dangerouslySetInnerHTML={{ __html: description }} />
      </motion.div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { id = '' } = ownProps;
  return {
    data: id === '' ? {} : getEmployeeByID(state, id),
  };
};

export default connect(mapStateToProps, null)(ModalLayoutEmployee);
