import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// import actions
import { displayMoreBlogs, displayResetBlogs } from 'store/blogs/Blogs.actions';

// import selectors
import { getBlogs, shouldDisplayMoreButton, getBlogById } from 'store/blogs/Blogs.selectors';
import { getProjectDataByID } from 'store/projects/Projects.selectors';

// import components
import BlogsBlock from 'components/blogs/Blogs.block';
import SvgArrowDown from 'components/svg/generic/Svg.arrow_down';

const BlogsGrid = ({ limit = 'all', ...props }) => {
  useEffect(() => {
    return () => {
      props.displayResetBlogs();
    };
  }, []);

  const renderBlocks = () => {
    const sliceValue = limit !== 'all' ? limit : props.data.length;

    return props.data.slice(0, sliceValue).map((block, index) => {
      return block && <BlogsBlock block={block} key={index} index={index} />;
    });
  };
  return (
    <ul className="blogs__grid">
      {renderBlocks()}
      {props.showMore && limit == 'all' && (
        <button
          aria-label="Show more blogs"
          className="blogs__grid__show-more"
          onClick={() => {
            props.displayMoreBlogs();
          }}
        >
          Load More <SvgArrowDown />
        </button>
      )}
    </ul>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { ids = [] } = ownProps;

  if (ids.length > 0) {
    return {
      data: ids.map((id, index) => {
        return id.includes('project') ? getProjectDataByID(state, id.replace('project_', '')) : getBlogById(state, id.replace('blog_', ''));
      }),
      showMore: false,
    };
  }

  return {
    data: getBlogs(state),
    showMore: shouldDisplayMoreButton(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    displayMoreBlogs: () => {
      dispatch(displayMoreBlogs());
    },
    displayResetBlogs: () => {
      dispatch(displayResetBlogs());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogsGrid);
