import React from 'react';

// import scss
import './Categories.header.scss';

// import components
import SvgRedLines from 'components/svg/patterns/Svg.red_lines';
import SvgSwirls from 'components/svg/patterns/Svg.swirls';
import { SvgHeart } from 'components/svg/generic/Svg.inline_text';

const CategoriesHeader = ({ data = {} }) => {
  const { title = '', description = '' } = data;
  const renderTitle = title.replace('{heart}', SvgHeart);
  const opts = { clazz: 'svg-icon--swirls', clr: '#ffd208' };
  return (
    <section className="categories__header">
      <div className="container">
        <SvgRedLines />
        <SvgSwirls {...opts} />
        <h1 className="categories__title wysiwyg" dangerouslySetInnerHTML={{ __html: renderTitle }} />
        <h2 className="categories__description wysiwyg" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </section>
  );
};

export default CategoriesHeader;
