import React from 'react';

// import components
import CreativeServicesCtaBlock from 'components/creative-services/CreativeServices.cta-block';

// import SVG patterns
import SvgOrangeSquares from 'components/svg/patterns/Svg.orange_squares';
import SvgBlueLinesTriangle from 'components/svg/patterns/Svg.blue_lines_triangle';
import SvgYellowLineLines from 'components/svg/patterns/Svg.yellow_line_lines';
import SvgYellowLinesCircle from 'components/svg/patterns/Svg.yellow_lines_circle';
import SvgRedLines from 'components/svg/patterns/Svg.red_lines';

export const CTA_ITEMS = [
  {
    id: 'Character Development',
    target: 'character_development',
    class_name: 'character_development',
  },
  {
    id: 'UI/UX',
    target: 'ui_ux',
    class_name: 'ui_ux',
  },
  {
    id: 'Branding',
    target: 'branding',
    class_name: 'branding',
  },
  {
    id: 'Short Form Video',
    target: 'short_form_video',
    class_name: 'short_form_video',
  },
  {
    id: 'Banners',
    target: 'banners',
    class_name: 'banners',
  },
];

const CreativeServicesCtaGrid = ({ data, client }) => {
  return (
    <ul className="creative-services__cta-grid">
      <SvgOrangeSquares />
      <SvgBlueLinesTriangle />
      <SvgYellowLineLines />
      <SvgYellowLinesCircle />
      <SvgRedLines />
      {CTA_ITEMS.map((block, index) => {
        return <CreativeServicesCtaBlock data={block} key={index} />;
      })}
    </ul>
  );
};

export default CreativeServicesCtaGrid;
