import React from 'react';

// import const
import { USE_WEBP } from 'constants/app/AppConstants';

// import utils
import { convertFileExtensionToWebp } from 'utils/general';

const PictureMappings = ({ src = '', srcSet = '', alt = '', title = '', caption = '', class_name = '', width = 0, height = 0 }) => {
  const createImageSrcSet = () => {
    let regExCheck = new RegExp('.*.(svg|gif)', 'i');
    let fileExt = regExCheck.test(src);

    // Use modrenizr to check browser for WEBP
    let webpFormat;
    let otherFormat;

    if (USE_WEBP != false && !fileExt) {
      webpFormat = `${convertFileExtensionToWebp(src)} 100w`;
    }

    otherFormat = `${src} 100w`;

    return (
      <React.Fragment>
        {USE_WEBP != false && !fileExt && <source type="image/webp" srcSet={webpFormat} sizes={'100w'} />}
        <source srcSet={otherFormat} sizes={'100w'} />
      </React.Fragment>
    );
  };

  return (
    <figure>
      <picture>
        {createImageSrcSet()}
        <img src={src} alt={alt} title={title} className={class_name} width={width} height={height} />
      </picture>
      {caption !== '' && <figcaption className="wysiwyg" dangerouslySetInnerHTML={{ __html: caption }} />}
    </figure>
  );
};

export default PictureMappings;
