import React from 'react';

// import scss
import './Svg.testimonialBoxes.scss';

const SvgTestimonialSmall = () => {
  // const className = `svg-content svg-content-small`;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387 338.43" preserveAspectRatio="xMinYMin meet">
      <g transform="translate(-335.277 -1751.889)"></g>
      <path
        className="stroke-small"
        d="M302.25,327.62c-49.24-6.36-53.56-45.61-53.56-45.61l-180.6-6.31
	c-31.99-1.11-57.02-27.94-55.92-59.92l0,0l5.09-145.76c1.13-31.99,27.97-57.01,59.96-55.88l0,0l239.9,8.38
	c31.99,1.11,57.02,27.94,55.92,59.92l-5.09,145.76c-1.13,31.99-27.97,57.01-59.96,55.88l0,0l-15.12-0.53
	c-7.71,27.08,35.31,45.35,15.84,44.67C307.05,328.16,304.91,327.97,302.25,327.62z"
      />
    </svg>
  );
};

const SvgTestimonialMedium = () => {
  // const className = `svg-content svg-content-medium ${column}`;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 434.14 369.86" preserveAspectRatio="xMinYMin meet">
      <g transform="translate(-335.277 -1751.889)"></g>
      <path
        className="stroke-medium"
        d="M354.67,354.61c-54.76-2.26-63.3-45.04-63.3-45.04L92.16,320.01
	c-35.3,1.85-65.41-25.27-67.26-60.56L16.47,98.56c-1.85-35.3,25.27-65.41,60.56-67.26l264.64-13.87
	c35.3-1.85,65.41,25.26,67.26,60.56l8.43,160.89c1.85,35.3-25.27,65.41-60.56,67.26l-16.67,0.87c-5.87,30.54,43.2,46.51,21.71,47.63
	C360,354.74,357.63,354.73,354.67,354.61z"
      />
    </svg>
  );
};

const SvgTestimonialLarge = () => {
  // const className = `svg-content svg-content-large ${column}`;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 547.71 482" preserveAspectRatio="xMinYMin meet">
      <g transform="translate(-335.277 -1751.889)"></g>
      <path
        className="stroke-large"
        d="M423.22,467.67c-69.41-10.21-74.54-65.86-74.54-65.86L93.7,388.44
	c-45.2-2.39-79.9-40.97-77.51-86.17c0,0,0,0,0,0l10.8-206.13c2.34-45.2,40.89-79.95,86.09-77.6c0,0,0,0,0,0l338.71,17.75
	c45.2,2.39,79.9,40.97,77.51,86.17c0,0,0,0,0,0L518.5,328.6c-2.34,45.2-40.89,79.95-86.09,77.6c0,0,0,0,0,0l-21.34-1.12
	c-11.56,38.13,48.76,65.04,21.26,63.6C429.97,468.55,426.96,468.22,423.22,467.67z"
      />
    </svg>
  );
};

const getTestimonialBox = (size) => {
  switch (size) {
    case 'small':
      return <SvgTestimonialSmall />;
    case 'medium':
      return <SvgTestimonialMedium />;
    case 'large':
      return <SvgTestimonialLarge />;
    default:
      console.warn(`getTestimonialBox : size not recognised : ${size}`);
      return <SvgTestimonialMedium />;
  }
};

export default getTestimonialBox;
