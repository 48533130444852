import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { LazyImage } from 'react-lazy-images';

import { Link } from 'react-router-dom';

import PictureMappings from 'components/picture-mappings/PictureMappings';

// import scss
import './Clients.block.scss';

const ClientsBlock = ({ block, modalCallback }) => {
  const controls = useAnimation();

  const { id, content, details, projects, project_count } = block;
  const { background_image, logo } = content;
  const { name, friendly_url } = details;

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  // rise in
  const motion_styles = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  const renderBlockContent = () => {
    return (
      <>
        <div className="clients__block__background-image">
          <LazyImage
            src={background_image}
            alt={name}
            placeholder={({ imageProps, ref }) => <div className="lazy-load__placeholder" ref={ref} />}
            actual={({ imageProps }) => <PictureMappings {...imageProps} width={480} height={367} />}
          />
        </div>
        <div className="clients__block__inner">
          <PictureMappings src={logo} alt={name} title={name} class_name={'clients__block__logo'} />
        </div>
      </>
    );
  };

  return (
    <motion.li key={name} className="clients__block motion_styles" variants={motion_styles} initial="hidden" animate={controls} ref={ref}>
      {project_count === 1 ? <Link to={projects[0].friendly_url}>{renderBlockContent()}</Link> : <Link to={friendly_url}>{renderBlockContent()}</Link>}
    </motion.li>
  );
};

export default ClientsBlock;
