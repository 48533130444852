//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import consts
import { PAGES } from 'store/pages/Pages.reducer';

// import selectors
import { getSiteMetaData } from 'store/app/App.selectors';
import { getPageDataByName } from 'store/pages/Pages.selectors';

// import components
import SiteMetaData from 'components/generic/SiteMetaData';

import { trackPageview } from 'utils/tracking';

class PagePrivacyPolicy extends Component {
  componentDidMount() {
    trackPageview({ title: 'Privacy Policy', path: this.props.location.pathname });
  }
  render() {
    const { data = false } = this.props;
    const { description = '' } = data;

    return (
      <div className="privacy-policy">
        <SiteMetaData {...this.props.metadata} />
        <div className="container">
          <h1>Privacy Policy</h1>
          <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: getPageDataByName(state, PAGES.PRIVACY_POLICY),
    metadata: getSiteMetaData(state, 'privacy-policy'),
  };
};

export default connect(mapStateToProps, null)(PagePrivacyPolicy);
