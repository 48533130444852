import React from 'react';

// import scss
import './Svg.processIcons.scss';

const SvgProcess1 = () => {
  return (
    <svg width="60" height="60" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73">
      <path
        className="clr-process1"
        d="M37.16,73.54A36.5,36.5,0,1,1,73.66,37,36.54,36.54,0,0,1,37.16,73.54Zm0-67A30.5,30.5,0,1,0,67.66,37,30.54,30.54,0,0,0,37.16,6.54Z"
        transform="translate(-0.66 -0.54)"
      />
      <g className="isolate">
        <path
          className="clr-process1"
          d="M28.87,43.28h5.4V34.75c0-.54,0-1.08,0-1.48v-.68h-.08a1.69,1.69,0,0,1-.25.46,4.21,4.21,0,0,1-.68.83l-1.15,1-4.32-4.64,7.34-6.8h6.52V43.28h5.47v6H28.87Z"
          transform="translate(-0.66 -0.54)"
        />
      </g>
    </svg>
  );
};

const SvgProcess2 = () => {
  return (
    <svg width="60" height="60" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.94 81.37">
      <g className="isolate">
        <path
          className="clr-process2"
          d="M53.44,75.67a14.79,14.79,0,0,1-.36-3.16c0-9.72,11-11.09,11-14.55,0-1.83-1.22-1.9-1.9-1.9a3.85,3.85,0,0,0-2.56,1.36,7,7,0,0,0-1,1.41l-5.26-3.6a9.23,9.23,0,0,1,2.09-2.95A10.44,10.44,0,0,1,63,49.36c4,0,9.21,2.2,9.21,8.14,0,7.23-10.4,9.14-10.69,12.16h5V66.75h6.08v8.92Z"
          transform="translate(-16.44 -20.2)"
        />
      </g>
      <path
        className="clr-process2"
        d="M76,26.41a2.93,2.93,0,0,0-3,.49L23.54,67.62a3,3,0,0,0,.84,5.12L83.31,95.36a3,3,0,0,0,4-3.24L77.86,28.78A3,3,0,0,0,76,26.41m2.15-5.6a8.92,8.92,0,0,1,5.68,7.07l9.49,63.34A9,9,0,0,1,81.15,101L22.22,78.33A9,9,0,0,1,19.72,63L69.16,22.26A8.92,8.92,0,0,1,78.11,20.81Z"
        transform="translate(-16.44 -20.2)"
      />
    </svg>
  );
};

const SvgProcess3 = () => {
  return (
    <svg width="60" height="60" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 80.7">
      <g className="isolate">
        <path
          className="clr-process3"
          d="M56.15,46.17H73.82v5.36l-4.14,5.29a6.61,6.61,0,0,1,5.15,6.59c0,4.42-3,9.07-10.19,9.07a14,14,0,0,1-6.77-1.69,9.25,9.25,0,0,1-2.26-1.66L59,63.73a8.46,8.46,0,0,0,1.51,1,7.39,7.39,0,0,0,3.53,1c2.12,0,3-1,3-2s-.9-2.09-3.35-2.09c-.25,0-.57,0-1.15.07L60.93,58.4l3.71-4.89a11.43,11.43,0,0,1,.9-1l.43-.4v-.07a2.73,2.73,0,0,0-.57,0c-.33,0-.76.07-1.19.07h-2v2.08H56.15Z"
          transform="translate(-22.49 -19.32)"
        />
      </g>
      <path
        className="clr-process3"
        d="M51.15,25.53a3,3,0,0,0-1.45,1.18L29,59.08a3,3,0,0,0,.19,3.5L53.5,92.9a3,3,0,0,0,3.41.92L92.54,80.14a3,3,0,0,0,1.92-3L92.27,38.34a3,3,0,0,0-2.2-2.72L53,25.43a2.9,2.9,0,0,0-1.85.1M49,19.92a9,9,0,0,1,5.61-.28L91.68,29.83A9,9,0,0,1,98.28,38l2.19,38.83a9,9,0,0,1-5.76,8.91L59.08,99.42a9,9,0,0,1-10.24-2.77L24.47,66.33a9,9,0,0,1-.56-10.49L44.64,23.47A9,9,0,0,1,49,19.92Z"
        transform="translate(-22.49 -19.32)"
      />
    </svg>
  );
};

const SvgProcess4 = () => {
  return (
    <svg width="60" height="60" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.35 77.34">
      <g className="isolate">
        <path
          className="clr-process4"
          d="M46.69,59.17,55.76,43.8h9.4V57.91H68v5.8H65.16v6H57.67v-6h-11Zm7-1.33v.07h4V52.84a11.56,11.56,0,0,1,.14-2l.15-1h-.15l-.36.86c-.25.54-.47,1.15-.86,1.84Z"
          transform="translate(-19.59 -17.07)"
        />
      </g>
      <path
        className="clr-process4"
        d="M58.26,94.41a20.94,20.94,0,0,1-14.85-6.14L25.73,70.59a21,21,0,0,1,0-29.71L43.41,23.2a21.06,21.06,0,0,1,29.7,0h0L90.79,40.88a21,21,0,0,1,0,29.71L73.11,88.27A20.94,20.94,0,0,1,58.26,94.41Zm0-71.34a15,15,0,0,0-10.61,4.38L30,45.13a15,15,0,0,0,0,21.21L47.65,84a15,15,0,0,0,21.22,0L86.55,66.34a15,15,0,0,0,0-21.21L68.87,27.45A15,15,0,0,0,58.26,23.07Z"
        transform="translate(-19.59 -17.07)"
      />
    </svg>
  );
};

const SvgProcess5 = () => {
  return (
    <svg width="60" height="60" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.2 73.56">
      <g className="isolate">
        <path
          className="clr-process5"
          d="M49.55,64.13a10.77,10.77,0,0,0,1.66,1.08A8.29,8.29,0,0,0,55,66.29c1.69,0,3.13-.72,3.13-2.3,0-1.26-.9-2.27-3.53-2.27a8.4,8.4,0,0,0-2.52.36,2.67,2.67,0,0,0-.83.4l-4-1.48.94-14.32h16v8.09H58.12V52.69H54.45l-.18,2.23a4.48,4.48,0,0,1-.11.72l-.07.36h.07a2.71,2.71,0,0,1,.54-.15,12.69,12.69,0,0,1,1.94-.1c6,0,9.29,3.63,9.29,8.24,0,5.11-4.07,9-10.4,9a14.36,14.36,0,0,1-6.73-1.66,10.43,10.43,0,0,1-2.45-1.62Z"
          transform="translate(-14.11 -23.22)"
        />
      </g>
      <path
        className="clr-process5"
        d="M20.73,29.26,20.28,71.7l-.17.68L93.69,90.73l.45-42.44.17-.68L20.73,29.26M22,23.4,95.76,41.79a6,6,0,0,1,4.37,7.27l-.46,43.17A6,6,0,0,1,92.4,96.6L18.66,78.21a6,6,0,0,1-4.37-7.27l.45-43.17A6,6,0,0,1,22,23.4Z"
        transform="translate(-14.11 -23.22)"
      />
    </svg>
  );
};

const SvgProcess6 = () => {
  return (
    <svg width="60" height="60" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.06 91.05">
      <g className="isolate">
        <path
          className="clr-process6"
          d="M50.2,58.58c0-7.92,4.57-14.62,13-14.62a15.17,15.17,0,0,1,4.21.58,12.58,12.58,0,0,1,1.59.57l-1.87,6.27A6.51,6.51,0,0,0,66.08,51a10.63,10.63,0,0,0-2.67-.36c-2.12,0-3.78.68-4.68,2.7h.07l.87-.22a11.43,11.43,0,0,1,2.23-.22,8.8,8.8,0,0,1,8.78,8.79c0,4.35-3.52,9-9.68,9C55.64,70.71,50.2,67.07,50.2,58.58ZM60.93,64a2,2,0,0,0,2.16-2.12c0-1-.79-2.92-3.6-2.92-1.26,0-1.69.33-1.69,1.37C57.8,62.25,59.34,64,60.93,64Z"
          transform="translate(-15.47 -10.75)"
        />
      </g>
      <path
        className="clr-process6"
        d="M48.49,101.8l-.53,0a8.93,8.93,0,0,1-6.23-3,9.08,9.08,0,0,1-2.27-5.35L38,74.29a3.09,3.09,0,0,0-1.51-2.41L20,62.22a9,9,0,0,1,1.12-16.17l17.76-7.27a3,3,0,0,0,1.82-2.16l4.08-18.75A9,9,0,0,1,55.49,11a9.1,9.1,0,0,1,5,3h0l12.4,14.64a3,3,0,0,0,2.62,1.05l19.09-1.92a9.05,9.05,0,0,1,8.59,13.76L93.09,57.79a3.05,3.05,0,0,0-.2,2.83l7.72,17.57A9,9,0,0,1,96,90.11a9.13,9.13,0,0,1-5.76.51L71.55,86.05a3.05,3.05,0,0,0-2.74.69L54.49,99.51A9,9,0,0,1,48.49,101.8Zm5.07-85a3,3,0,0,0-1.63.48,3,3,0,0,0-1.33,1.92L46.52,37.9a9,9,0,0,1-5.41,6.44L23.35,51.6A3,3,0,0,0,23,57l16.57,9.67A9.08,9.08,0,0,1,44,73.84L45.44,93a3.1,3.1,0,0,0,.77,1.81A3.06,3.06,0,0,0,50.5,95L64.82,82.26a9.05,9.05,0,0,1,8.16-2l18.64,4.56a3.06,3.06,0,0,0,1.95-.17,3,3,0,0,0,1.6-1.68,3,3,0,0,0,0-2.32L87.4,63a9,9,0,0,1,.59-8.4L98.08,38.31a3.09,3.09,0,0,0,.43-1.91,3,3,0,0,0-3.33-2.72L76.1,35.6a9,9,0,0,1-7.79-3.13L55.9,17.82a3,3,0,0,0-1.68-1A3,3,0,0,0,53.56,16.75Z"
        transform="translate(-15.47 -10.75)"
      />
    </svg>
  );
};

const getProcessIcon = (index) => {
  switch (index) {
    case 0:
      return <SvgProcess1 />;
    case 1:
      return <SvgProcess2 />;
    case 2:
      return <SvgProcess3 />;
    case 3:
      return <SvgProcess4 />;
    case 4:
      return <SvgProcess5 />;
    case 5:
      return <SvgProcess6 />;
  }
};

export default getProcessIcon;
