import React from 'react';

// import utils
import { isEmptyObj } from 'utils/general';

const AboutTimeline = ({ data = {} }) => {
  const { now = '', then = '', content = [] } = data;

  const renderDates = () => {
    return content.map((item, index) => {
      const { date, description } = item;

      return (
        <div className={'timeline__date' + (index % 2 == 0 ? ' left' : ' right')} key={index}>
          <div className="timeline__date__title">{date}</div>
          <div className="timeline__date__description">
            <p className="content">{description}</p>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="timeline-section">
      <div className="timeline">
        <div className="timeline__now-section">
          <div className="timeline__now__title">Now</div>
          {now !== '' && <p className="timeline__now__description">{now}</p>}
        </div>
        {content.length > 0 && <div className="timeline__dates-section">{renderDates()}</div>}
        <div className="timeline__then-section">
          {then !== '' && <p className="timeline__then__description">{then}</p>}
          <div className="timeline__then__title">Then</div>
        </div>
      </div>
    </div>
  );
};

export default AboutTimeline;
