export const getEmployeeByID = (state, id) => {
  if (state.employees.byID) {
    return state.employees.byID[id] || {};
  }
  return {};
};

export const getAllEmployeesCtas = (state) => {
  const ids = state.employees.allIDs;

  return ids.map((employee_id) => {
    const employee = state.employees.byID[employee_id];
    const { id, content, details } = employee;
    const { name } = details;

    const { cta_image = '' } = content;
    return {
      id,
      name,
      cta_image,
    };
  });
};
