import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const random = Math.random() * (1 - 0) + 0;
    const delay = 0.5 + random * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.1 },
      },
    };
  },
};

const SvgYellowLines = () => {
  const controls = useAnimation();

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <motion.svg
      className="svg-icon--yellow-lines"
      xmlns="http://www.w3.org/2000/svg"
      width="544.531"
      height="276.286"
      viewBox="0 0 544.531 276.286"
      ref={ref}
      variants={draw}
      initial="hidden"
      animate={controls}
    >
      <g data-name="Group 900">
        <g data-name="Group 899" fill="none" stroke="#fed209" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="10">
          <motion.path variants={draw} data-name="Path 1858" d="M115.569 196.9837a322.348 322.348 0 0 1-40.2253-47.9021" />
          <motion.path variants={draw} data-name="Path 1859" d="m161.5935 199.061-33.3002-45.5736" />
          <motion.path variants={draw} data-name="Path 1860" d="m197.6404 191.3347-36.403-65.7888" />
          <motion.path variants={draw} data-name="Path 1861" d="m269.1241 193.099-20.8784-38.4352" />
          <motion.path variants={draw} data-name="Path 1862" d="m323.1545 191.0958-27.118-30.4527" />
          <motion.path variants={draw} data-name="Path 1863" d="m364.6583 150.5-23.0488-23.1578" />
          <motion.path variants={draw} data-name="Path 1864" d="M429.4227 118.3118 408.3323 94.625" />
          <motion.path variants={draw} data-name="Path 1865" d="m486.9705 113.189-12.4926-15.5622" />
          <motion.path variants={draw} data-name="Path 1866" d="m533.7828 100.6128-15.5943-18.8745" />
          <motion.path variants={draw} data-name="Path 1867" d="m219.6353 242.0668-10.6214-16.0198" />
          <motion.path variants={draw} data-name="Path 1868" d="m272.2124 242.5645-42.4963-64.079" />
          <motion.path variants={draw} data-name="Path 1869" d="m314.0055 227.1503-12.0502-13.5318" />
          <motion.path variants={draw} data-name="Path 1870" d="m362.4712 197.338-69.8282-79.7808" />
          <motion.path variants={draw} data-name="Path 1871" d="M416.2044 147.0699a690.988 690.988 0 0 1-50.1662-53.6127" />
          <motion.path variants={draw} data-name="Path 1872" d="m336.17 99.4274-36.6872-42.566" />
          <motion.path variants={draw} data-name="Path 1873" d="m478.3166 182.7608-31.5573-31.353" />
          <motion.path variants={draw} data-name="Path 1874" d="m477.9446 143.058-44.1285-46.8536" />
          <motion.path variants={draw} data-name="Path 1875" d="M526.8566 121.3294Q469.9386 67.329 418.4372 7.9724" />
          <motion.path variants={draw} data-name="Path 1876" d="m413.5165 82.732-32.2449-41.6631" />
          <motion.path variants={draw} data-name="Path 1877" d="m445.8682 78.188-23.5747-25.1135" />
          <motion.path variants={draw} data-name="Path 1878" d="m444.6937 198.1381-20.0349-19.7733" />
          <motion.path variants={draw} data-name="Path 1879" d="m262.153 136.2157-13.6361-19.4063" />
          <motion.path variants={draw} data-name="Path 1880" d="m229.957 149.1088-28.8623-44.6734" />
          <motion.path variants={draw} data-name="Path 1881" d="M138.3045 268.3314Q80.7283 202.3652 28.134 132.2654" />
          <motion.path variants={draw} data-name="Path 1882" d="M71.3995 238.1204a389.381 389.381 0 0 1-32.2433-41.6618" />
          <motion.path variants={draw} data-name="Path 1883" d="m32.4914 233.9399-7.0838-10.6801" />
        </g>
      </g>
    </motion.svg>
  );
};

export default SvgYellowLines;
