//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import const
import { PAGES } from 'store/pages/Pages.reducer';

// import selectors
import { getSiteMetaData } from 'store/app/App.selectors';
import { getPageDataByName } from 'store/pages/Pages.selectors';

// import components
import CreativeServicesBase from 'components/pages/creative-services/CreativeServices.base';
import SiteMetaData from 'components/generic/SiteMetaData';
import PageNotFoundBase from 'components/page-not-found/PageNotFound.base';
import { trackPageview } from 'utils/tracking';

class PageCreativeServices extends Component {
  componentDidMount() {
    trackPageview({ title: 'Creative Services', path: this.props.location.pathname });
  }
  render() {
    const { data = false, metadata } = this.props;
    if (!data) {
      return <PageNotFoundBase />;
    }

    const { content = {}, details = {} } = data;

    const { name = 'Creative Services' } = details;
    const { cta_image = '' } = content;

    const { description: meta_description = '', og_description = '', og_title = '', title: meta_title = '' } = metadata;

    const base_meta_title = meta_title.replace('{title}', name);
    const base_meta_description = meta_description.replace('{title}', name);
    const base_og_title = og_title.replace('{title}', name);
    const base_og_description = og_description.replace('{title}', name);

    return (
      <div className="project">
        <SiteMetaData title={base_meta_title} description={base_meta_description} og_description={base_og_description} og_title={base_og_title} og_image={cta_image} />
        <div className="container">
          <CreativeServicesBase data={data} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const data = getPageDataByName(state, PAGES.CREATIVE_SERVICES);

  return {
    data,
    metadata: getSiteMetaData(state, 'project'),
  };
};

export default withRouter(connect(mapStateToProps, null)(PageCreativeServices));
