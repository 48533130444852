// import const
import { CLIENTS_DISPATCH_DATA } from 'store/clients/Clients.actions';

// import parsers
import { parseClients } from 'store/clients/Clients.parsers';

const initState = {
  allIDs: [],
  byID: {},
};

const Clients = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case CLIENTS_DISPATCH_DATA: {
      const { allIDs, byID } = parseClients(action.payload);
      nextState.allIDs = allIDs;
      nextState.byID = byID;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default Clients;
