import React from 'react';
import { connect } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import { getClientByID } from 'store/clients/Clients.selectors';
import { getCategoryByID } from 'store/categories/Categories.selectors.js';

// import const
import { URL_PAGE_WHO_WE_WORK_WITH, URL_PAGE_WHAT_WE_DO } from 'constants/app/AppUrls';

// import scss
import './Project.header.scss';

// import components
import SvgArrowLeft from 'components/svg/generic/Svg.arrow_left';

const ProjectHeader = ({ data, goBackCallback, returnUrl }) => {
  const history = useHistory();

  const { details, content, id } = data;

  const { header_image } = content;
  const { name, description } = details;

  return (
    <div className="project__header" style={{ backgroundImage: `url(${header_image})` }}>
      <div className="project__header__container">
        <div className="back-button">
          <button
            className="go-back"
            onClick={() => {
              if (returnUrl !== '') {
                history.push(returnUrl);
              } else {
                history.goBack();
              }
            }}
          >
            <SvgArrowLeft /> Back
          </button>
        </div>
        <div className="title">
          <h1>{name}</h1>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const searchParamCategory = getParameterByName('category', false);
  const searchParamSocial = getParameterByName('social', false);

  let returnUrl = getClientByID(state, ownProps?.data?.details?.client || '')?.details?.friendly_url || '';

  if (searchParamCategory) {
    returnUrl = getCategoryByID(state, searchParamCategory)?.friendly_url || '';
  } else if (searchParamSocial) {
    returnUrl = `${URL_PAGE_WHAT_WE_DO}/social`;
  }

  return {
    returnUrl,
  };
};

export default connect(mapStateToProps, null)(ProjectHeader);

// helper functions
// ****
const getParameterByName = (name, defaultValue, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results || !results[2]) return defaultValue;

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
