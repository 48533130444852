//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

// import selectors
import { getBlogByUrl } from 'store/blogs/Blogs.selectors';
import { getSiteMetaData } from 'store/app/App.selectors';

// import components
import BlogBase from 'components/pages/blog/Blog.base';
import SiteMetaData from 'components/generic/SiteMetaData';
import PageNotFoundBase from 'components/page-not-found/PageNotFound.base';

import { trackPageview } from 'utils/tracking';

class PageBlog extends Component {
  componentDidMount() {
    trackPageview({ title: this.props?.data?.details?.title || 'Unknown blog title', path: this.props.location.pathname });
  }
  render() {
    const { data = false, metadata, isExact } = this.props;

    if (!data || !isExact) {
      return <PageNotFoundBase />;
    }

    const { title } = data;
    const { meta_description = '', og_description = '', og_title = '', meta_title = '', keywords = '' } = metadata;
    const { meta = {} } = data;
    const {
      meta_description: override_desc = '',
      meta_keywords: override_keywords = '',
      meta_title: override_title = '',
      og_description: override_og_desc = '',
      og_title: override_og_title = '',
    } = meta;

    const base_meta_title = override_title !== '' ? override_title : meta_title.replace('{title}', title);
    const base_meta_description = override_desc !== '' ? override_desc : meta_description.replace('{title}', title);
    const base_og_title = override_og_title !== '' ? override_og_title : og_title.replace('{title}', title);
    const base_og_description = override_og_desc !== '' ? override_og_desc : og_description.replace('{title}', title);
    const base_keywords = override_keywords !== '' ? override_keywords : keywords;
    return (
      <div className="blog">
        <SiteMetaData
          title={base_meta_title}
          description={base_meta_description}
          og_description={base_og_description}
          og_title={base_og_title}
          og_image={data.content.slider_image}
          keywords={base_keywords}
        />
        <BlogBase data={data} />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const urlFragment = ownProps.match.url || '';

  return {
    data: getBlogByUrl(state, urlFragment),
    metadata: getSiteMetaData(state, 'individual_blog'),
    isExact: ownProps.match.isExact,
  };
};

export default withRouter(connect(mapStateToProps, null)(PageBlog));
