import React from 'react';
import { motion } from 'framer-motion';

// framer const
const employee_image = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      delay: 0.3,
    },
  },
};
const employee_details = {
  hidden: { y: 60, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
      duration: 1.2,
      delay: 0.5,
    },
  },
};

// import components
const ModalLayoutEmployee = ({ data, closeCallback, scrollbarCallback }) => {
  const { details, content } = data;
  const { job_title, name } = details;
  const { cta_image, description, image } = content;

  const onAnimationComplete = (e) => {
    scrollbarCallback();
  };

  return (
    <div id="modal-content" className="modal-layout__employee">
      <motion.figure className="modal-layout__employee__image" key="image" initial={employee_image.hidden} animate={employee_image.visible}>
        <img src={image} alt={name} title={name} />
      </motion.figure>
      <motion.div key="body" initial={employee_details.hidden} animate={employee_details.visible} onAnimationComplete={onAnimationComplete}>
        <div className="modal-layout__employee__name">{name}</div>
        <div className="modal-layout__employee__job-title">{job_title}</div>
        <div className="modal-layout__employee__description wysiwyg" dangerouslySetInnerHTML={{ __html: description }} />

        <button className="modal-layout__employee__close" aria-label="close overlay" onClick={closeCallback}>
          close
        </button>
      </motion.div>
    </div>
  );
};

export default ModalLayoutEmployee;
