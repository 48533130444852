import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const random = Math.random() * (1 - 0) + 0;
    const delay = 0.5 + random * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.1 },
      },
    };
  },
};

const SvgRedLinesRectangle = () => {
  const controls = useAnimation();

  const { ref, inView, entry } = useInView({
    threshold: 0.15,
    triggerOnce: true,
  });

  useEffect(() => {
    inView ? controls.start('visible') : controls.start('hidden');
  }, [controls, inView]);

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      className="svg-icon--red-lines-rectangle"
      width="503.037"
      height="486.575"
      viewBox="0 0 503.037 486.575"
      ref={ref}
      variants={draw}
      initial="hidden"
      animate={controls}
    >
      <g data-name="Group 844" fill="none" stroke="rgba(236,104,91,0.3)" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="10">
        <motion.path variants={draw} data-name="Path 1662" d="M102.6623 94.8289a101.532 101.532 0 0 0 47.7032-1.4119" />
        <motion.path variants={draw} data-name="Path 1663" d="M218.533 149.0292a101.528 101.528 0 0 0-37.9388-28.9521" />
        <motion.path variants={draw} data-name="Path 1664" d="m127.7167 68.564-16.022-28.1383" />
        <motion.path variants={draw} data-name="Path 1665" d="m208.3586 302.2643 29.0342-14.3347" />
        <motion.path variants={draw} data-name="Path 1666" d="M15.454 116.6693 29.9594 87.721" />
        <motion.path variants={draw} data-name="Path 1667" d="M141.7497 71.8366a109.7 109.7 0 0 1 15.6887-21.1394" />
        <motion.path variants={draw} data-name="Path 1668" d="M165.2667 71.628a44.2 44.2 0 0 0 29.607 9.8488" />
        <motion.path variants={draw} data-name="Path 1669" d="M202.2576 193.1408a44.2 44.2 0 0 0 29.607 9.8488" />
        <motion.path variants={draw} data-name="Path 1670" d="M42.0278 62.3307a44.213 44.213 0 0 0 29.607 9.8487" />
        <motion.path variants={draw} data-name="Path 1671" d="M194.3999 109.6477a180.707 180.707 0 0 1 33.4146-6.2873" />
        <motion.path variants={draw} data-name="Path 1672" d="m145.3272 153.6969 17.6093-26.6417" />
        <motion.path variants={draw} data-name="Path 1673" d="m94.6447 160.8051 17.6086-26.6424" />
        <motion.path variants={draw} data-name="Path 1674" d="M170.0874 150.9997a81.327 81.327 0 0 0 29.949 11.1946" />
        <motion.path variants={draw} data-name="Path 1675" d="M282.661 221.7563a81.288 81.288 0 0 0 29.949 11.1945" />
        <motion.path variants={draw} data-name="Path 1676" d="M329.3485 422.6833a162.023 162.023 0 0 0 17.0521-36.1747" />
        <motion.path variants={draw} data-name="Path 1677" d="M380.0191 290.6901a162.045 162.045 0 0 0-33.6476-21.6183" />
        <motion.path variants={draw} data-name="Path 1678" d="M327.218 382.1953a172.8 172.8 0 0 1 18.298-36.983" />
        <motion.path variants={draw} data-name="Path 1679" d="M295.4938 183.445a172.749 172.749 0 0 1 28.5775 29.765" />
        <motion.path variants={draw} data-name="Path 1680" d="M304.53 351.1555a156.568 156.568 0 0 0-21.3004-28.4881" />
        <motion.path variants={draw} data-name="Path 1681" d="M297.4239 382.1044a156.569 156.569 0 0 0-21.3005-28.4881" />
        <motion.path variants={draw} data-name="Path 1682" d="M252.561 329.5246a149.367 149.367 0 0 1 8.5547-32.3205" />
        <motion.path variants={draw} data-name="Path 1683" d="M287.046 305.1626a56.666 56.666 0 0 0 22.1373 2.8298" />
        <motion.path variants={draw} data-name="Path 1684" d="m328.6306 326.0425 24.582-20.9462" />
        <motion.path variants={draw} data-name="Path 1685" d="M364.502 330.38a81.361 81.361 0 0 1 17.406 26.7817" />
        <motion.path variants={draw} data-name="Path 1686" d="m365.365 370.9562 26.2737 24.718" />
        <motion.path variants={draw} data-name="Path 1687" d="M353.4445 433.04a72.241 72.241 0 0 0 45.386-7.7305" />
        <motion.path variants={draw} data-name="Path 1688" d="M461.2106 346.1687a72.245 72.245 0 0 0-35.732-29.0313" />
        <motion.path variants={draw} data-name="Path 1689" d="M416.3533 292.473a72.245 72.245 0 0 0-35.732-29.0314" />
        <motion.path variants={draw} data-name="Path 1690" d="M270.097 192.0843a77.749 77.749 0 0 0 2.5381-24.936c-.1037-1.7142-1.9733-3.9718-2.915-2.5337" stroke-linejoin="round" />
        <motion.path variants={draw} data-name="Path 1691" d="M243.8689 130.1546a151.522 151.522 0 0 0-4.1787 44.6571" />
        <motion.path variants={draw} data-name="Path 1692" d="M116.075 155.8137q15.0038 11.1348 30.6332 21.4023" />
        <motion.path variants={draw} data-name="Path 1693" d="m161.6346 211.4646 26.2908-32.1127" />
        <motion.path variants={draw} data-name="Path 1694" d="M80.9615 131.6442a246.18 246.18 0 0 1 2.7354-42.5464" />
        <motion.path variants={draw} data-name="Path 1695" d="m111.4202 192.2234 31.7119 8.6325" />
        <motion.path variants={draw} data-name="Path 1696" d="m243.1293 248.7383 31.714 8.6318" />
        <motion.path variants={draw} data-name="Path 1697" d="M86.507 174.3075a261.569 261.569 0 0 0-36.737-31.5723" />
        <motion.path variants={draw} data-name="Path 1698" d="M45.8078 123.5075a227.161 227.161 0 0 0 16.1109-30.161" />
        <motion.path variants={draw} data-name="Path 1699" d="M289.5333 282.9426a227.347 227.347 0 0 0 16.1095-30.1596" />
        <motion.path variants={draw} data-name="Path 1700" d="M98.0395 39.157A227.33 227.33 0 0 0 114.152 8.9975" />
        <motion.path variants={draw} data-name="Path 1701" d="M142.0595 236.1104a58.838 58.838 0 0 0 39.333-3.235" />
        <motion.path variants={draw} data-name="Path 1702" d="M205.5852 239.7809a76.214 76.214 0 0 0-18.9424-30.357" />
        <motion.path variants={draw} data-name="Path 1703" d="M67.1152 41.1653a76.205 76.205 0 0 0 23.4362-27.0384" />
        <motion.path variants={draw} data-name="Path 1704" d="m226.2171 235.8757 30.2063-26.967" />
        <motion.path variants={draw} data-name="Path 1705" d="m396.9192 476.6027.9713-31.5328" />
        <motion.path variants={draw} data-name="Path 1706" d="M431.2427 432.7a116.1 116.1 0 0 1-2.9413-46.9076" />
        <motion.path variants={draw} data-name="Path 1707" d="m407.3938 363.449 34.184-1.6607" />
        <motion.path variants={draw} data-name="Path 1708" d="m317.7868 287.1592 18.3531 5.8656" />
        <motion.path variants={draw} data-name="Path 1709" d="m341.9488 246.404 18.3532 5.8655" />
        <motion.path variants={draw} data-name="Path 1710" d="M387.1704 318.3719a87.166 87.166 0 0 0 18.037 12.8806" />
        <motion.path variants={draw} data-name="Path 1711" d="M447.317 409.3495a116.784 116.784 0 0 0 13.5363-21.2608" />
        <motion.path variants={draw} data-name="Path 1712" d="M477.9685 378.6015a116.8 116.8 0 0 0 13.5364-21.2609" />
        <motion.path variants={draw} data-name="Path 1713" d="m178.115 259.0908 20.8651 9.213" />
        <motion.path variants={draw} data-name="Path 1714" d="m120.5417 119.1983 22.1364 1.247" />
      </g>
    </motion.svg>
  );
};

export default SvgRedLinesRectangle;
