//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import selectors
import { getSiteMetaData } from 'store/app/App.selectors';

// import components
import ClientsGrid from 'components/clients/Clients.grid';
import SiteMetaData from 'components/generic/SiteMetaData';
import { getPageDataByName } from 'store/pages/Pages.selectors';
import { PAGES } from 'store/pages/Pages.reducer';
import ClientsHeader from 'components/clients/Clients.header';
import ClientsTestimonials from 'components/clients/Clients.testimonials';

import { trackPageview } from 'utils/tracking';

class PageClients extends Component {
  componentDidMount() {
    trackPageview({ title: 'Who We Work With', path: this.props.location.pathname });
  }
  render() {
    const { header, testimonials } = this.props;
    return (
      <div className="clients">
        <SiteMetaData {...this.props.metadata} />
        <ClientsHeader data={header} />
        <ClientsGrid />
        <ClientsTestimonials data={testimonials} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const data = getPageDataByName(state, PAGES.WHO_WE_WORK_WITH);
  return {
    metadata: getSiteMetaData(state, 'who-we-work-with'),
    ...data,
  };
};

export default connect(mapStateToProps, null)(PageClients);
