import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ModalCookiePolicy = ({ updateCookiePolicy }) => {
  const [displayPopup, updateDisplayPopup] = useState(false);

  const toggleDisplayPopup = () => {
    localStorage.setItem('jw_cookie_policy', 'true');
    updateDisplayPopup(false);
  };

  useEffect(() => {
    localStorage.getItem('jw_cookie_policy') == null && updateDisplayPopup(true);
  }, []);

  return (
    <div className={'modal--cookie-policy' + (!displayPopup ? ' modal--cookie-policy--hidden' : '')}>
      <div className="modal--cookie-policy__container">
        <p className="title">This website uses cookies.</p>
        <p>By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience and to help our website run effectively.</p>
        <div className="modal--cookie-policy__button">
          <button onClick={toggleDisplayPopup}>Close</button>
          <Link to={'/privacy-policy'}>Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
};

export default ModalCookiePolicy;
