import { getUrlDynamic, getUrlByContentType } from 'constants/app/AppUrls';
import { CONTENT_TYPE_PROJECT } from 'constants/app/AppConstants';

import { processImages } from 'utils/imageProcessing';

const EXTERNAL_CTA_TEXT = {
  external_url_cta_1: 'View it',
  external_url_cta_2: 'Play it',
};

const findEarliestDate = (data) => {
  const earliest = Math.max(
    ...data.map(function (o) {
      return o.created;
    })
  );
  return data.find(function (o) {
    return o.created == earliest;
  });
};

const parseData = (project) => {
  const { data, id, name } = project;
  const { friendly_url = '', content = {} } = data;
  const {
    client = '',
    cta_image = '',
    description = '',
    external_url = '',
    header_image = '',
    types = [],
    related_blog = false,
    external_url_cta = 'external_url_cta_2',
    meta_description = '',
    meta_keywords = '',
  } = content;

  const parsedProject = {
    id,
    friendly_url: getUrlByContentType(CONTENT_TYPE_PROJECT, friendly_url.trim()),
    details: {
      name,
      description,
      types,
      client,
      external_url,
      external_url_cta: EXTERNAL_CTA_TEXT[external_url_cta],
      related_blog: related_blog === 'none' ? false : related_blog,
    },
    content: {
      cta_image,
      header_image,
      header_srcset: false,
    },
    meta: {
      meta_description,
      meta_keywords,
    },
  };

  if (cta_image !== '') {
    parsedProject.content.cta_image = getUrlDynamic(cta_image);
  }

  // sort multisized images
  if (Array.isArray(header_image)) {
    const { 0: img_lg = '', 1: img_md = '', 2: img_sm = '' } = header_image;

    const img_lg_file = getUrlDynamic(img_lg.file_name);
    const img_md_file = getUrlDynamic(img_md.file_name);
    const img_sm_file = getUrlDynamic(img_sm.file_name);

    parsedProject.content.header_srcset = processImages('', [img_lg_file, img_md_file, img_sm_file], [`${img_lg.width}w`, `${img_md.width}w`, `${img_sm.width}w`]);
    parsedProject.content.header_image = img_lg_file;
  } else if (header_image !== '') {
    parsedProject.content.header_image = getUrlDynamic(header_image);
  }

  return parsedProject;
};

export const parseProjects = (data) => {
  const allIDs = [];
  const byID = {};
  const byClientID = {};
  const byCategoryID = {};
  const latestProjectByID = [];

  const latestProject = findEarliestDate(data);

  if (latestProject) {
    latestProjectByID.push(latestProject.id);
  }

  for (const project of data) {
    const { id, name, data } = project;
    const { content } = data;
    const { types, client } = content;

    const parsed = parseData(project);
    allIDs.push(id);
    byID[id] = { ...parsed };

    // Assign ID by client
    if (!byClientID[client]) {
      byClientID[client] = [];
    }
    byClientID[client].push(id);

    // Assign ID by category type
    types.map((type) => {
      if (!byCategoryID[type]) {
        byCategoryID[type] = [];
      }
      byCategoryID[type].push(id);
    });
  }
  return { allIDs, byID, byClientID, byCategoryID, latestProjectByID };
};
