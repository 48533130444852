export const PROJECTS_DISPATCH_DATA = 'PROJECTS_DISPATCH_DATA';
export const PROJECTS_DISPLAY_MORE = 'PROJECTS_DISPLAY_MORE';
export const PROJECTS_DISPLAY_RESET = 'PROJECTS_DISPLAY_RESET';

export const displayMoreProjects = () => ({
  type: PROJECTS_DISPLAY_MORE,
});

export const displayResetProjects = () => ({
  type: PROJECTS_DISPLAY_RESET,
});
