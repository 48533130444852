import React from 'react';

// import components
import SvgBlueDots from 'components/svg/patterns/Svg.blue_dots';
import ContactBecomingJollywise from 'components/pages/contact/Contact.becoming_jollywise';

const ContactJoinUs = ({ data, jollywise }) => {
  const { background_image = '', description = '', title = '' } = data;

  const inlineStyle = background_image !== '' ? { backgroundImage: `url(${background_image})` } : {};

  return (
    <section className="contact-join-us" style={inlineStyle}>
      <SvgBlueDots />

      <div className="container contact-join-us__container">
        <h1 className="contact-join-us__title">{title}</h1>
        <div className="contact-join-us__description">{description}</div>
      </div>

      <div className="container contact-join-us__contact-becoming-jollywise">
        <ContactBecomingJollywise data={jollywise} />
      </div>
    </section>
  );
};

export default ContactJoinUs;
