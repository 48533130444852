import React from 'react';
import { connect } from 'react-redux';

// import scss
import './Navigation.mobile_button.scss';

// import actions
import { activeMobileNav } from 'store/app/App.actions';

// import selectors
import { getActiveMobileNavState } from 'store/app/App.selectors';

const NaviagtionMobileButton = (props) => {
  const { isMobileNavActive = false } = props;
  return (
    <div
      className={'navigation__mobile-button ' + (isMobileNavActive ? 'navigation__mobile-button--open' : 'navigation__mobile-button--close')}
      onClick={() => {
        props.activeNav();
      }}
    >
      <span className="navigation__mobile-button__line-1"></span>
      <span className="navigation__mobile-button__line-2"></span>
      <span className="navigation__mobile-button__line-3"></span>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    activeNav: () => {
      dispatch(activeMobileNav());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    isMobileNavActive: getActiveMobileNavState(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NaviagtionMobileButton);
