import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

const SocialBlock = ({ block }) => {
  // state
  const [hasBeenSeen, updateHasBeenSeen] = useState(false);

  const controls = useAnimation();

  const { content, details, friendly_url, type = '' } = block;
  const { cta_image } = content;
  const { name, override_title = '' } = details;

  const { ref, inView, entry } = useInView({
    threshold: window.innerWidth < 768 ? 0.1 : 0.4,
    triggerOnce: window.innerWidth > 768,
  });

  // rise in
  const motion_styles = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  useEffect(() => {
    !inView && !hasBeenSeen && controls.start('hidden');
    if (inView) {
      if (!hasBeenSeen) {
        controls.start('visible');
        updateHasBeenSeen(!hasBeenSeen);
      }
    }
  }, [inView]);

  const renderBlockContent = () => {
    return (
      <div className={'social__block__inner'}>
        <div className="social__block__background-image" style={{ backgroundImage: `url(${cta_image})` }} />
        <div className="social__block__title">{override_title !== '' ? override_title : name}</div>
      </div>
    );
  };
  return (
    <motion.li key={name} className="social__block motion_styles" variants={motion_styles} initial="hidden" animate={controls} ref={ref}>
      <Link to={type !== 'social_page' ? `${friendly_url}?social=true` : friendly_url}>{renderBlockContent()}</Link>
    </motion.li>
  );
};

export default SocialBlock;
