import { getUrlDynamic } from 'constants/app/AppUrls';

export const parseContact = (data = []) => {
  const { header = {}, join_us = {} } = data;
  const { background_image: header_image = '' } = header;
  const { background_image: join_us_image = '' } = join_us;

  if (header_image !== '') {
    header.background_image = getUrlDynamic(header_image);
  }

  if (join_us_image !== '') {
    join_us.background_image = getUrlDynamic(join_us_image);
  }

  return data;
};
