import React from 'react';

// import scss
import './Blogs.header.scss';

// import components
import SvgRedLines from 'components/svg/patterns/Svg.red_lines';
import SvgBlueSwirl from 'components/svg/patterns/Svg.blue_swirl';

const BlogsHeader = ({ data }) => {
  const { title, description } = data;
  return (
    <section className="blogs__header ">
      <div className="container">
        <SvgRedLines />
        <SvgBlueSwirl />
        <h1 className="blogs__title wysiwyg" dangerouslySetInnerHTML={{ __html: title }} />
        <h2 className="blogs__description wysiwyg" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </section>
  );
};

export default BlogsHeader;
