import React from 'react';
import { connect } from 'react-redux';

// import scss
import './Clients.grid.scss';

// import consts
import { MODAL_OVERLAY_TYPE_CLIENT } from 'constants/app/AppConstants';

// import selectors
import { getClients } from 'store/clients/Clients.selectors';

// import actions
import { activeOverlayModal } from 'store/app/App.actions';

// import components
import ClientsBlock from 'components/clients/Clients.block';

const ClientsGrid = (props) => {
  const renderBlocks = () => {
    return props.clients.map((block, index) => {
      return <ClientsBlock block={block} key={index} />;
    });
  };

  return <ul className="clients__grid">{renderBlocks()}</ul>;
};

const mapStateToProps = (state) => {
  return {
    clients: getClients(state),
  };
};

export default connect(mapStateToProps, {})(ClientsGrid);
